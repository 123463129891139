import { Button, Group } from "@mantine/core";
import {
  IconBrandYoutube,
  IconExternalLink,
  IconPlus,
  IconVideo,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";
import ResellerHowTo from "./howto/ResellerHowTo";
import { useSessionStore } from "../../../stores/UserStore";

const CreateResellerButton = () => {
  const lang = useLanguage((s) => s.language);
  const route = `/resellers/white-labels/create`;

  return (
    <Group>
      {useSessionStore.getState().reseller?.name === "Magileads" && (
        <Button
          leftSection={<IconBrandYoutube size={18} />}
          component="a"
          href="https://youtu.be/MwHNOiSgWnw"
          target="_blank"
          variant="light"
        >
          {lang.resellers.onboarding.watch}
        </Button>
      )}

      <ResellerHowTo />

      <Button
        leftSection={<IconPlus size={18} />}
        radius={"sm"}
        color="primary"
        component={Link}
        to={route}
      >
        {lang.resellers.create.button_tooltip}
      </Button>
    </Group>
  );
};

export default CreateResellerButton;
