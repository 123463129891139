import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { formatAnswer } from "../../utils";
import { parseDate } from "../../../../../functions/dates";
import {
  IconCalendarClock,
  IconShare3,
  IconSpeakerphone,
  IconX,
} from "@tabler/icons-react";
import AnswerDownloader from "./AnswerDownloader";
import { useMemo, useState } from "react";
import AnswerSender from "./AnswerSender";
import { useLanguage } from "../../../../../stores/LanguageStore";
import PositiveChanger from "../actions/PositiveChanger";
import { Link } from "react-router-dom";
import AnswerDirectLink from "./AnswerDirectLink";

const MoreActions = ({ item, current, setCurrent, container, progInfo }) => {
  return (
    <Group align="center" gap={"xs"} wrap="nowrap" miw={64}>
      {progInfo && (
        <Tooltip label="Voir la campagne dans Statistiques">
          <ActionIcon
            component={Link}
            to={`/statistics/campaigns?highlight=${progInfo?.programmation_id}`}
            target="_blank"
            variant="subtle"
            opacity={0.75}
            ml={"auto"}
          >
            <IconSpeakerphone size={18} />
          </ActionIcon>
        </Tooltip>
      )}

      {item?.reply && (
        <PositiveChanger
          isAnswer
          containerId={current?.custom_status || current?.status}
          is_positive={item?.is_positive_reply}
          itemId={item?.id}
          prospectId={current?.id}
          reply_type={item?.type}
          actualProspect={current}
          setActualProspect={setCurrent}
          container={container}
        />
      )}
    </Group>
  );
};

const AnswerItem = ({ item, type, about, current, setCurrent, container }) => {
  const [replying, setReplying] = useState(false);
  const lang = useLanguage((s) => s.language);

  const date = new Date(item.date).toLocaleString(
    lang.id === "FRA" ? "fr-FR" : "en-EN",
    {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );

  const progInfo = useMemo(() => {
    let p = current?.programmations?.find(
      (x) => x.programmation_id === item.workflow_programmation_id
    );
    return p || null;
  }, [current?.programmations, item.workflow_programmation_id]);

  return type === "email" ? (
    <Paper
      component={Stack}
      withBorder
      radius={"md"}
      px={"xs"}
      pos={"relative"}
      gap={0}
      h={"100%"}
    >
      <Group
        gap={"xs"}
        style={{ borderBottom: "1px solid #00000016" }}
        pt={4}
        pb={4}
      >
        <ThemeIcon color={"gray"} variant="subtle">
          <IconCalendarClock size={18} />
        </ThemeIcon>
        <Text c={"gray"} size="sm">
          {date.charAt(0).toUpperCase() + date.slice(1)}
        </Text>

        {progInfo && (
          <>
            <ThemeIcon variant="subtle" ml={"auto"}>
              <IconSpeakerphone size={18} />
            </ThemeIcon>
            <Anchor
              target="_blank"
              href={`/statistics/campaigns?highlight=${progInfo.programmation_id}`}
              truncate
              w={200}
              size="sm"
              mr={"auto"}
            >
              {progInfo?.workflow_name} #{progInfo?.programmation_id}{" "}
              {item?.workflow_step_id && (
                <span style={{ fontSize: 12, paddingLeft: 8 }}>
                  ( {lang.prm.resume.step} #{item?.workflow_step_id} )
                </span>
              )}
            </Anchor>
          </>
        )}

        <AnswerDownloader item={item} />

        {item?.direct_link && (
          <AnswerDirectLink link={item.direct_link} isLinkedin={false} />
        )}

        <PositiveChanger
          isAnswer
          containerId={current?.custom_status || current?.status}
          is_positive={item?.is_positive_reply}
          itemId={item?.id}
          prospectId={current?.id}
          reply_type={item?.type}
          actualProspect={current}
          setActualProspect={setCurrent}
          container={container}
        />

        <Button
          variant="light"
          size="compact-sm"
          color={replying ? "red" : "tertiary.6"}
          leftSection={
            replying ? <IconX size={18} /> : <IconShare3 size={18} />
          }
          ml={8}
          onClick={() => setReplying(!replying)}
        >
          {lang.prm.answer}
        </Button>
      </Group>
      {replying && (
        <AnswerSender
          about={about}
          item={item}
          current={current}
          setAnswering={setReplying}
        />
      )}

      <iframe
        title={item.date}
        id="iFrame1"
        srcDoc={formatAnswer(item?.reply || item?.message)}
        style={{
          width: replying ? "calc(100% - 50px)" : "100%",
          border: "none",
          borderLeft: replying ? "1px solid #00000016" : "none",
          marginLeft: replying ? 42 : 0,
          paddingLeft: replying ? 8 : 0,
          height: "100%",
          minHeight: 320,
        }}
        // sandbox=""
      />
    </Paper>
  ) : (
    <Stack gap={6} className="hover-source">
      <Group
        wrap="nowrap"
        ml={item?.reply ? 0 : "auto"}
        mr={item?.reply ? "auto" : 0}
      >
        {item?.message && <MoreActions type={type} />}
        <Paper
          withBorder
          bg={
            item?.message
              ? "var(--mantine-primary-color-1)"
              : "var(--mantine-color-gray-1)"
          }
          radius={"md"}
          p={"sm"}
          py={"xs"}
          w={"fit-content"}
        >
          {item?.reply || item?.message}
        </Paper>
        {item?.reply && (
          <MoreActions
            item={item}
            container={container}
            current={current}
            setCurrent={setCurrent}
            progInfo={progInfo}
          />
        )}
      </Group>
      <Text
        ta={item?.reply ? "left" : "right"}
        size="sm"
        c={"dimmed"}
        w={"100%"}
      >
        {parseDate(item.date)}
      </Text>
    </Stack>
  );
};

export default AnswerItem;
