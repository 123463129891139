import { Collapse } from "@mantine/core";
import {
  IconBoxModel2,
  IconBrandLinkedin,
  IconList,
  IconMailForward,
  IconSend,
  IconSendOff,
  IconSignature,
  IconUserX,
  IconAddressBookOff,
  IconMail,
  IconMessageCircle,
  IconMessage,
  IconTopologyFullHierarchy,
  IconHierarchy2,
  IconApps,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { useAccountsStore } from "../../stores/ConfigStore";
import NavItemMenu from "./NavItemMenu";
import { LinksGroup } from "./NavbarLinksGroup/NavbarLinksGroup";

const iconSize = 18;

const Ressources = ({ navbarClosed, opened, setOpened }) => {
  const lang = useLanguage((s) => s.language);
  const emails = useAccountsStore((s) => s.email_accounts);
  const linkedins = useAccountsStore((s) => s.linkedin_accounts);

  const accountErrors = useMemo(() => {
    return {
      emails: emails.filter((x) => !x.is_valid).length,
      linkedins: linkedins.filter((x) => !x.is_valid).length,
    };
  }, [emails, linkedins]);

  const ressources = [
    {
      id: "contact-lists",
      path: "/contact-lists",
      icon: <IconList size={iconSize} />,
      name: lang.header.navigations.lists.label,
    },
    {
      id: "models",
      path: "/models",
      icon: <IconBoxModel2 size={iconSize} />,
      name: lang.header.navigations.models.label,
      children: [
        {
          path: "/email",
          name: lang.header.navigations.models.emails,
          icon: <IconMail size={iconSize} />,
        },
        {
          icon: <IconSignature size={iconSize} />,
          name: lang.header.menus.link_groups.email_signatures,
          path: "/email-signatures",
        },
        {
          path: "/linkedin-invitation",
          name: lang.header.navigations.models.linkedin_invitations,
          icon: <IconBrandLinkedin size={iconSize} />,
        },
        {
          path: "/linkedin-message",
          name: lang.header.navigations.models.linkedin_messages,
          icon: <IconMessage size={iconSize} />,
        },
        {
          path: "/sms",
          name: lang.header.navigations.models.sms,
          icon: <IconMessageCircle size={iconSize} />,
        },
        // {
        //   path: "/workflow",
        //   name: lang.header.navigations.workflows.label,
        //   icon: <IconHierarchy2 size={iconSize} />,
        // },
      ],
    },
    {
      id: "workflows",
      path: "workflows",
      name: lang.header.navigations.workflows.label,
      icon: <IconHierarchy2 size={iconSize} />,
    },
    {
      id: "senders",
      name: lang.header.navigations.senders,
      icon: <IconSend size={iconSize} />,
      path: "senders",
      children: [
        {
          icon: <IconMailForward size={iconSize} />,
          name: lang.header.menus.link_groups.emails_accounts,
          path: "/email",
          indicatorContent: accountErrors.emails,
        },
        {
          icon: <IconBrandLinkedin size={iconSize} />,
          name: lang.header.menus.link_groups.linkedin_accounts,
          path: "/linkedin",
          indicatorContent: accountErrors.linkedins,
        },
      ],
    },
    {
      id: "exclusions",
      name: "Exclusions",
      path: "exclusions",
      icon: <IconAddressBookOff size={iconSize} />,
      children: [
        {
          icon: <IconSendOff size={iconSize} />,
          name: lang.header.menus.link_groups.blacklists,
          path: "/blacklists",
        },
        {
          icon: <IconUserX size={iconSize} />,
          name: lang.header.menus.link_groups.unsubscribers,
          path: "/unsubscribers",
        },
      ],
    },
  ];

  return (
    <>
      <LinksGroup
        closed={navbarClosed}
        name={lang.header.menus.link_groups.title_ressources}
        onClick={() => setOpened(!opened)}
        icon={IconApps}
        navbarClosed={navbarClosed}
        hasLinks={true}
        opened={opened}
        noNav
      />

      <Collapse in={opened}>
        {ressources.map((link) => (
          <NavItemMenu
            key={link.path}
            item={link}
            navbarClosed={navbarClosed}
          />
        ))}
      </Collapse>
    </>
  );
};

export default Ressources;
