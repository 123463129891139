import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Flex,
  PasswordInput,
  Stack,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../api/AuthAPI";
import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useSessionStore } from "../../../stores/UserStore";
import { IconKey, IconLock, IconMail } from "@tabler/icons-react";

const LoginForm = () => {
  const lang = useLanguage((s) => s.language);
  const url = new URLSearchParams(window.location.search);
  const destination = url.get("destination");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setSession = useSessionStore((store) => store.setSession);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    form.onSubmit((values) => {
      login({ email: values.email, password: values.password })
        .then((response) => {
          const { access_token, refresh_token } = response.data;
          setSession({ access_token, refresh_token });
          navigate(destination || "/");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          let data = error.response.data;

          if (data.state_message === "incorrect_email_or_password") {
            form.setFieldError(
              "email",
              lang.errors.main.incorrect_email_or_password +
                ` ${
                  data?.remaining_attempts
                    ? `(${data?.remaining_attempts})`
                    : ""
                } `
            );
          }
          if (data.state_message === "too_many_attempts") {
            form.setFieldError("email", lang.errors.main.too_many_attempts);
          }
          if (data.state_message === "incorrect_email") {
            form.setFieldError("email", lang.errors.main.incorrect_email);
          }
          if (data.state_message === "email_not_validated") {
            form.setFieldError("email", lang.errors.main.email_not_validated);
          }
          if (data.state_message === "user_account_disabled") {
            form.setFieldError("email", lang.errors.main.user_account_disabled);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit} w={"60%"}>
      <TextInput
        required
        leftSection={<IconMail size={18} />}
        label={lang.login.mail}
        placeholder={lang.signup.mail}
        size="md"
        type="email"
        className={`animate__animated animate__fadeInUp`}
        style={{ animationDelay: ".1s" }}
        {...form.getInputProps("email")}
      />
      <PasswordInput
        leftSection={<IconKey size={18} />}
        label={lang.login.password}
        placeholder={lang.login.password}
        size="md"
        required
        className={`animate__animated animate__fadeInUp`}
        style={{ animationDelay: ".2s" }}
        {...form.getInputProps("password")}
      />

      <Flex justify={"space-between"} align={"center"} mt={"xs"}>
        <Checkbox
          label={lang.login.remember_me}
          className={`animate__animated animate__fadeInUp`}
          style={{ animationDelay: ".25s" }}
          {...form.getInputProps("remember", { type: "checkbox" })}
        />

        <Anchor
          component={Link}
          to={"/password-recovery"}
          size="sm"
          className={`animate__animated animate__fadeInUp`}
          style={{ animationDelay: ".25s" }}
        >
          {lang.login.password_forgot}
        </Anchor>
      </Flex>

      <Button
        type="submit"
        mt={"md"}
        loading={loading}
        leftSection={<IconLock size={18} />}
        size="md"
        className={`animate__animated animate__fadeInUp`}
        style={{ animationDelay: ".3s" }}
      >
        {lang.login.button_label}
      </Button>

      <Divider
        my={8}
        className={`animate__animated animate__fadeInLeft`}
        style={{ animationDelay: ".8s" }}
      />
    </Stack>
  );
};

export default LoginForm;
