import { useCallback, useEffect, useState } from "react";
import { levelsColor } from "./filterSettings";
import { useLanguage } from "../../stores/LanguageStore";
import FilterItem from "./FilterItem";
import FilterItemAdder from "./FilterItemAdder";
import FilterGroupAdder from "./FilterGroupAdder";
import { Button, Group, Paper, Stack } from "@mantine/core";
import { IconCheck, IconRotate } from "@tabler/icons-react";
import FilterModeSelector from "./FilterModeSelector";
import FilterBlockChild from "./FilterBlockChild";
import FilterSaver from "./FilterSaver";

export const conditionWidth = 360;

let newId = 0;

function countObjectsWithValue(arr) {
  let count = 0;
  arr.forEach((obj) => {
    if (Object.prototype.hasOwnProperty.call(obj, "value")) {
      count++;
    }
    if (Object.prototype.hasOwnProperty.call(obj, "values")) {
      count += countObjectsWithValue(obj.values);
    }
  });
  return count;
}

const FilterBlockRoot = ({
  filter,
  submitFilter,
  fields,
  id,
  maxItems,
  maxHeight = 300,
  disableButtons,
  getFieldOptionName,
  isClient,
  onSaved,
  pageName,
  buttonLoading,
  disableEdit,
}) => {
  const lang = useLanguage((s) => s.language);

  const [_mode, _setMode] = useState(filter?.mode || "and");

  const onModeChange = (v) => {
    _setMode(v);
  };

  const [items, setItems] = useState(
    filter?.values.map((x) => ({ ...x, id: newId++ })) || []
  );

  const addItem = () => {
    const newItem = {
      field_name: fields[0].options[0].id.toString(),
      type: fields[0].options[0]?.type === "number" ? "=" : "contains",
      value: "",
      id: newId++,
    };
    setItems((prev) => [...prev, newItem]);
  };

  const addGroup = () => {
    const newItem = {
      mode: "and",
      values: [
        {
          field_name: fields[0].options[0].id.toString(),
          type: fields[0].options[0]?.type === "number" ? "=" : "contains",
          value: "",
          id: newId++,
        },
      ],
    };
    setItems((prev) => [...prev, newItem]);
  };

  const deleteItem = (id) => {
    setItems((prev) => {
      let newItems = prev.filter((x) => x.id !== id);

      return newItems;
    });
  };

  const deleteGroup = (id) => {
    setItems((prev) => {
      let newItems = prev.filter((x) => x.id !== id);

      return newItems;
    });
  };

  const handleItemChange = useCallback(({ id, field_name, type, value }) => {
    setItems((prev) => {
      let newItems = [...prev];
      let index = newItems.findIndex((x) => x.id === id);

      newItems[index] = {
        field_name: field_name,
        type: type,
        value: value,
        id: id,
      };

      return newItems;
    });
  }, []);

  const handleGroupChange = useCallback((id, newValues) => {
    setItems((prev) => {
      let newItems = [...prev];
      let index = newItems.findIndex((x) => x.id === id);
      newItems[index] = { ...newValues, id: id };

      return newItems;
    });
  }, []);

  const handleReset = () => {
    _setMode("and");
    setItems([
      {
        field_name: fields[0].options[0].id.toString(),
        type: fields[0].options[0]?.type === "number" ? "=" : "contains",
        value: "",
        id: 1,
      },
    ]);
    newId = 1;
  };

  const handleFilter = (e) => {
    e.preventDefault();
    submitFilter({ mode: _mode, values: items });
    e.stopPropagation();
  };

  const [itemNumbers, setItemNumbers] = useState(countObjectsWithValue(items));

  useEffect(() => {
    setItemNumbers(countObjectsWithValue(items));
  }, [items]);

  useEffect(() => {
    setItems(filter?.values.map((x) => ({ ...x, id: newId++ })) || []);
    _setMode(filter?.mode || "and");
  }, [filter]);

  return (
    <Paper miw={500} h={"100%"} component={Stack}>
      <Stack
        pos="relative"
        mah={maxHeight}
        style={{ overflow: "auto" }}
        component="form"
        id={"main-filter-form" + id}
        onSubmit={handleFilter}
        flex={"1 1 auto"}
      >
        <FilterModeSelector
          onChange={onModeChange}
          value={_mode}
          borderColor={levelsColor[0]}
          disabled={disableEdit}
        />

        <Stack pos="relative" pt={0}>
          <div
            style={{
              position: "absolute",
              top: -16,
              left: 12,
              width: 0,
              height: "calc(100% + 4px)",
              borderRight: "2px solid",
              borderColor: levelsColor[0],
            }}
          />
          {/* Children goes here */}
          {items.map((x, i) =>
            x?.values ? (
              <FilterBlockChild
                key={x.id}
                fields={fields}
                getFieldOptionName={getFieldOptionName}
                handleChange={(newItems) => handleGroupChange(x.id, newItems)}
                handleDeleteGroup={() => deleteGroup(x.id)}
                values={x.values}
                mode={x.mode}
                isLast={i === items.length - 1}
                parentMode={_mode}
                itemNumbers={itemNumbers}
                maxItems={maxItems}
                disableEdit={disableEdit}
              />
            ) : (
              <FilterItem
                item={x}
                key={x.id}
                lineColor={levelsColor[0]}
                isLast={i === items.length - 1}
                parentMode={_mode}
                handleDelete={() => deleteItem(x.id)}
                handleChange={(newFilter) =>
                  handleItemChange({
                    ...newFilter,
                    id: x.id,
                  })
                }
                fields={fields}
                isClient={isClient}
                getFieldOptionName={getFieldOptionName}
              />
            )
          )}

          <Stack ml={"sm"} pl={25} pos="relative">
            <div
              style={{
                position: "absolute",
                top: "calc(50% - 1px)",
                left: 0,
                width: 23,
                border: 0,
                borderTop: "2px dashed",
                borderColor: levelsColor[0],
              }}
            />
            <Group>
              <FilterGroupAdder
                disabled={itemNumbers >= maxItems || disableEdit}
                onClick={addGroup}
                color={levelsColor[0]}
              />
              <FilterItemAdder
                disabled={itemNumbers >= maxItems || disableEdit}
                onClick={addItem}
                color={levelsColor[0]}
              />
            </Group>
          </Stack>
        </Stack>
      </Stack>

      {!disableButtons && (
        <Group mt={"auto"} wrap="nowrap" pt={"xs"}>
          <Button
            leftSection={<IconRotate size={16} />}
            size="compact-sm"
            onClick={handleReset}
            variant="subtle"
          >
            {lang.table.toolbar.filter.reset_button_label}
          </Button>

          <FilterSaver
            onSaved={onSaved}
            filter={{ mode: _mode, values: items }}
            pageName={pageName}
          />

          <Button
            leftSection={<IconCheck size={16} />}
            size="compact-sm"
            type="submit"
            form={"main-filter-form" + id}
            loading={buttonLoading}
          >
            {lang.table.toolbar.filter.submit_button_label}
          </Button>
        </Group>
      )}
    </Paper>
  );
};

export default FilterBlockRoot;
