import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { deleteUser } from "../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconTrashX, IconUserX } from "@tabler/icons-react";
import BackButton from "../../../components/View/BackButton";

const DeleteAccount = ({ profile, onSuccess, isOther }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const submitDelete = () => {
    setLoading(true);
    deleteUser(profile.id)
      .then((res) => {
        if (res.data.state) {
          notifications.show({
            message: lang.profile.delete.success_text,
            color: "green.8",
          });
          handleClose();
          if (onSuccess) onSuccess();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {isOther ? (
        <Tooltip label={lang.profile.delete.button_label}>
          <Button
            color="black"
            variant="light"
            onClick={handleOpen}
            loading={loading}
            disabled={!profile}
            px={10}
            w={"100%"}
          >
            <IconTrashX size={20} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          color="black"
          variant="light"
          onClick={handleOpen}
          leftSection={<IconUserX size={20} />}
        >
          {lang.profile.delete.button_label}
        </Button>
      )}

      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        title={
          <Text>
            {lang.profile.delete.dialog_title}{" "}
            {isOther ? (
              <b>
                {profile?.first_name} {profile?.last_name} #{profile?.id}
              </b>
            ) : (
              ""
            )}
          </Text>
        }
        styles={{
          content: {
            boxShadow: "red 0 0 6px",
            border: "1px solid #a00",
          },
          title: {
            maxWidth: "92%",
          },
        }}
        size={"md"}
      >
        <Stack>
          <Text>{lang.profile.delete.dialog_text}</Text>
          <TextInput
            label={lang.profile.delete.email}
            onChange={(e) => {
              if (e.target.value === profile.email) setCanDelete(true);
              else setCanDelete(false);
            }}
            placeholder={lang.profile.delete.email_placeholder}
            fullWidth
            autoFocus
          />

          <Group justify="end" mt={"md"}>
            <BackButton onClick={handleClose} />
            <Button
              onClick={submitDelete}
              loading={loading}
              disabled={!canDelete}
              color="black"
              leftSection={<IconTrashX size={18} />}
            >
              {lang.profile.delete.submit_button}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default DeleteAccount;
