import { Button, Divider, Group, Stack, Text, TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useSessionStore } from "../../../stores/UserStore";
import { signup } from "../../../api/AuthAPI";
import PasswordField from "../../../components/View/PasswordField";
import { IconMail, IconUserPlus } from "@tabler/icons-react";
import { IconKey } from "@tabler/icons-react";

const SignupForm = () => {
  const lang = useLanguage((s) => s.language);
  const reseller = useSessionStore((s) => s.reseller);

  //#region Signup
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    phone: false,
    fName: false,
    lName: false,
    unknown: false,
  });
  const [pwdError, setPwdError] = useState(true);

  const handlePhoneError = () => {
    setErrors({ phone: true });
    document.getElementById("phone").focus();
    setLoading(false);
  };
  const handlePwdError = () => {
    setErrors({ password: true });
    document.getElementById("pwd").focus();
    setLoading(false);
  };
  const handleEmailError = () => {
    setErrors({ email: true });
    document.getElementById("email").focus();
    setLoading(false);
  };
  const handleFNameError = () => {
    setErrors({ fName: true });
    document.getElementById("first_name").focus();
    setLoading(false);
  };
  const handleLNameError = () => {
    setErrors({ lName: true });
    document.getElementById("last_name").focus();
    setLoading(false);
  };
  const handleUnknownError = () => {
    setErrors({ unknown: true });
    setLoading(false);
  };

  const navigate = useNavigate();

  const handleSignup = (e) => {
    e.preventDefault();
    setErrors({});

    // Params
    const params = new URLSearchParams(location.search);
    let ref = params.get("ref");
    let team_code = params.get("team_code");
    let organization_code = params.get("organization_code");

    // Data
    let formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    data.domain_saas =
      reseller?.domain_saas === "application.magileads.io"
        ? null
        : reseller?.domain_saas || null;
    data.email_validation_url_success = `https://${
      reseller?.domain_saas2 || reseller?.domain_saas
    }/success/account?lang=${lang.id}`;
    data.email_validation_url_error = `https://${
      reseller?.domain_saas2 || reseller?.domain_saas
    }/email-not-validated?lang=${lang.id}`;
    data.language = lang.id;
    data.referred_user_id = parseInt(ref) || null;
    data.team_codes = team_code ? [team_code] : null;
    data.organization_code = organization_code ? [organization_code] : null;
    data.show_onboarding = true;

    if (pwdError) {
      return handlePwdError();
    } else {
      setLoading(true);
      signup(data)
        .then((response) => {
          modals.open({
            title: lang.signup.signup_success.title,
            centered: true,
            children: (
              <>
                <Text>{lang.signup.signup_success.text}</Text>

                <Group justify="center" mt={"lg"}>
                  <Button
                    onClick={() => {
                      navigate("/login");
                      modals.closeAll();
                    }}
                  >
                    {lang.signup.signup_success.button_label}
                  </Button>
                </Group>
              </>
            ),
          });
        })
        .catch((error) => {
          let data = error.response.data;
          if (data.state_message === "empty_last_name") {
            return handleLNameError();
          }
          if (data.state_message === "empty_first_name") {
            return handleFNameError();
          }
          if (data.state_message === "incorrect_email") {
            return handleEmailError();
          }
          if (data.state_message === "mail_error") {
            return handleEmailError();
          }
          if (data.state_message === "email_used") {
            return handleEmailError();
          }
          if (data.state_message === "incorrect_mobile_phone") {
            return handlePhoneError();
          }
          if (data.state_message === "mobile_phone_used") {
            return handlePhoneError();
          } else {
            handleUnknownError();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  //#endregion

  return (
    <Stack
      component={"form"}
      onSubmit={handleSignup}
      w={"60%"}
      mx={"auto"}
      pos={"relative"}
    >
      <TextInput
        name="email"
        leftSection={<IconMail size={18} />}
        id="email"
        label={lang.signup.mail}
        placeholder={lang.signup.mail}
        required
        error={errors.email}
        w={"100%"}
        type="email"
        size="md"
        className={`animate__animated animate__fadeInUp`}
        style={{ animationDelay: ".1s" }}
      />
      <PasswordField
        error={pwdError}
        leftSection={<IconKey size={18} />}
        setError={setPwdError}
        id={"pwd"}
        size="md"
        className={`animate__animated animate__fadeInUp`}
        style={{ animationDelay: ".2s" }}
      />

      <Button
        type="submit"
        loading={loading}
        mt={"md"}
        w={"100%"}
        mx={"auto"}
        leftSection={<IconUserPlus size={18} />}
        size="md"
        className={`animate__animated animate__fadeInUp`}
        style={{ animationDelay: ".3s" }}
      >
        {lang.signup.button_label}
      </Button>

      <Divider
        my={8}
        className={`animate__animated animate__fadeInLeft`}
        style={{ animationDelay: ".8s" }}
      />
    </Stack>
  );
};

export default SignupForm;
