import { Button, Group, Stack, TextInput, Title } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../components/View/BackButton";
import PageTitle from "../../../../components/View/PageTitle";
import { createAIKey } from "../../../../api/ConfigsApi";

const CreateAiKeyPage = () => {
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    setError(null);
    createAIKey({ name: data?.name, api_key: data?.api_key })
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message: lang.openai_keys.row_highlight.created,
            color: "tertiary.8",
          });
        }, 300);
        navigate(`../?highlight=${response.data.data_field_id}`);
      })
      .catch((err) => {
        let message = err.response?.data?.state_message;
        if (message === "invalid_api_key") {
          setError("api_key");
          return document.getElementById("api_key-field").focus();
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.openai_keys.browser_tab_title} />
      </Group>
      <Stack
        className="layout-block"
        w={"40%"}
        mx={"auto"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Title order={5}>{lang.openai_keys.create.dialog.title}</Title>
        <TextInput
          name="name"
          label={lang.openai_keys.create.dialog.name}
          placeholder={lang.openai_keys.create.dialog.name}
          autoFocus
          required
        />

        <TextInput
          id="api_key-field"
          name="api_key"
          label={lang.openai_keys.create.dialog.key}
          placeholder={"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"}
          required
          error={error === "api_key"}
        />

        <Group justify="flex-end" mt={"md"}>
          <BackButton />
          <Button type="submit" loading={loading}>
            {lang.openai_keys.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default CreateAiKeyPage;
