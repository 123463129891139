import {
  ActionIcon,
  Group,
  LoadingOverlay,
  Paper,
  TextInput,
  Tooltip,
} from "@mantine/core";
import EmailAccountSelector from "../../../../../components/Selectors/EmailAccountSelector";
import { useState } from "react";
import { IconMailbox } from "@tabler/icons-react";
import { sendEmail } from "../../../../../api/WorkflowAPI";
import { notifications } from "@mantine/notifications";
import EmailAnswerEditor from "./EmailAnswerEditor";
import EmailSignatureSelector from "../../../../../components/Selectors/EmailSignatureSelector";

const AnswerSender = ({ item, setAnswering, about, current }) => {
  const [sender, setSender] = useState(
    item?.reply_to?.integration_id
      ? { id: item?.reply_to?.integration_id }
      : null
  );
  const [recipient, setRecipient] = useState(about?.email || "");
  const [sign, setSign] = useState(
    item?.reply_to?.signature_id ? { id: item?.reply_to?.signature_id } : null
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = ({ html, text }) => {
    let oldHtml = item.reply;
    let parsedHtml = `
      ${html}
      <br />

      <div style="
          border: none;
          border-top: solid #e1e1e1 1pt;
          padding: 3pt 0in 0in 0in;
        ">
      </div>

      ${oldHtml || ""}
    `;

    setLoading(true);

    sendEmail({
      html: parsedHtml,
      text,
      subject: `Re : ${item?.reply_to?.subject || ""}`,
      email_account_id: sender?.id,
      recipient,
      contact_id_prm: current.id,
      email_signature_id: sign?.id,
    })
      .then((res) => {
        notifications.show({ message: "Email envoyé", color: "green.8" });
        setAnswering(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Paper pos={"relative"} pt={4}>
      <LoadingOverlay visible={loading} />
      <Group
        w={"100%"}
        wrap="nowrap"
        gap={"xl"}
        px={8}
        pl={4}
        pt={4}
        pos={"relative"}
        style={{
          zIndex: 4,
          background: "white",
        }}
      >
        <EmailAccountSelector
          label={"Expéditeur"}
          size={"sm"}
          required
          onChange={setSender}
          value={sender?.id}
        />
        <TextInput
          type="email"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          label="Destinataire"
          placeholder="Destinataire"
          w={"100%"}
          required
          rightSection={
            <Tooltip label="Prendre l'email du contact">
              <ActionIcon
                disabled={!about?.email}
                variant="light"
                onClick={() => setRecipient(about.email)}
              >
                <IconMailbox size={18} />
              </ActionIcon>
            </Tooltip>
          }
        />
        <EmailSignatureSelector
          value={sign?.id}
          onChange={setSign}
          label={"Signature"}
          size={"sm"}
          w="100%"
        />
      </Group>

      <EmailAnswerEditor
        key={item.id}
        id={item.id}
        handleSubmit={handleSubmit}
        handleClose={() => setAnswering(false)}
      />

      {/* <Divider mt={16} mb={8} /> */}
    </Paper>
  );
};

export default AnswerSender;
