import { Button, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import CreateModelEmail from "../../pages/protected/models/emails/CreateModelEmail";
import { useLanguage } from "../../stores/LanguageStore";

const AddModelEmail = ({ onModelCreated }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Tooltip label={lang.campaign.panel.model.tooltip}>
        <Button variant="subtle" size="compact-sm" onClick={open} fw={700}>
        {lang.campaign.panel.model.label}
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        size={1200}
        title={lang.model_emails.create.dialog.title}
        styles={{
          header: {
            paddingBottom: 8,
            minHeight: 32
          }
        }}
        removeScrollProps={{
          enabled: false
        }}
      >
        <CreateModelEmail onCreated={onModelCreated} handleClose={close} inModal />
      </Modal>
    </>
  );
};

export default AddModelEmail;
