import { useProfileStore } from "../../../stores/UserStore";
import AvatarEditor from "./components/AvatarEditor";
import { updateProfile, updateSelf } from "../../../api/UserAPI";
import { useEffect, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import {
  Button,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Radio,
  Select,
  Stack,
  TagsInput,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import PhoneNumberInput from "../../../components/Selectors/PhoneNumberInput";
import CountrySelector from "../../../components/Selectors/CountrySelector";
import { IconDeviceFloppy, IconRestore } from "@tabler/icons-react";
import timezones from "./components/timezones.json";

const Profile = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    data.mail_notification = data?.mail_notification?.split(",") || [];

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "")
    );

    setLoading(true);
    updateProfile(profile.id, filteredData)
      .then((res) => {
        setDirty(false);
        updateSelf();
        notifications.show({
          message: lang.profile.edited,
          color: "tertiary.8",
        });

        if (profile.language !== filteredData.language) {
          location.reload();
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    updateSelf();
  }, []);

  return (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      onChange={() => setDirty(true)}
      align="start"
      gap={"lg"}
      style={{ overflow: "auto", position: "relative" }}
      flex={"1 1 auto"}
      h={0}
      mih={"100%"}
      w={"65%"}
      mx={"auto"}
    >
      <LoadingOverlay
        visible={loading}
        overlayProps={{ blur: 2 }}
        w={600}
        h={"100%"}
      />
      <Stack style={{ overflow: "auto" }} flex={"1 1 auto"} h={0}>
        <AvatarEditor profile={profile} onSuccess={updateSelf} pl={32} />
        <Paper
          withBorder
          component={Stack}
          w={"fit-content"}
          p={"md"}
          px={"xl"}
          radius={"md"}
        >
          <Text fw={600} size="md" mb={-8}>
            {lang.profile.personal_infos}
          </Text>

          <Group gap={"xl"}>
            <TextInput
              name="first_name"
              label={lang.profile.first_name}
              defaultValue={profile?.first_name}
              w={250}
              required
            />
            <TextInput
              name="last_name"
              label={lang.profile.last_name}
              defaultValue={profile?.last_name}
              w={250}
              required
            />
          </Group>

          <Group gap={"xl"}>
            <Radio.Group
              name="sex"
              label={lang.signup.civility}
              w={250}
              required
              defaultValue={profile.sex}
            >
              <Group mt="xs">
                <Radio
                  value="male"
                  label={lang.profile.civility_male}
                  size="sm"
                />
                <Radio
                  value="female"
                  label={lang.profile.civility_female}
                  size="sm"
                />
              </Group>
            </Radio.Group>

            <Select
              label={lang.profile.language}
              placeholder={lang.profile.language}
              name="language"
              defaultValue={profile.language}
              allowDeselect={false}
              w={250}
              data={[
                { label: "English", value: "ENG" },
                { label: "Français", value: "FRA" },
              ]}
            />
          </Group>

          <Group gap={"xl"}>
            <PhoneNumberInput
              defaultCountry={profile?.mobile_phone_country || "FR"}
              defaultValue={profile?.mobile_phone}
              name={"mobile_phone"}
              required
              label={lang.profile.mobile_phone}
              w={250}
            />
            <TextInput
              defaultValue={profile?.phone}
              name={"phone"}
              label={lang.profile.phone}
              w={250}
              description={lang.profile.with_zero}
              withCountrySelector={false}
            />
          </Group>

          <Group gap={"xl"}>
            <TextInput
              type="email"
              name="email"
              label={lang.profile.mail}
              placeholder={lang.profile.mail}
              defaultValue={profile?.email}
              w={250}
              required
            />

            <PasswordInput
              name="password"
              label={lang.profile.password}
              placeholder={lang.profile.password}
              w={250}
              autoComplete="new-password"
            />
          </Group>
          <Text size="sm" mt={-8}>
            * {lang.profile.mail_helper}
          </Text>

          <Textarea
            label={lang.signup.activity_description}
            placeholder={lang.signup.activity_description_placeholder}
            defaultValue={profile?.activity_description}
            name="activity_description"
            w={"100%"}
            required
            maxLength={500}
            resize="vertical"
            rows={2}
          />
        </Paper>

        <Paper
          withBorder
          component={Stack}
          w={"fit-content"}
          p={"md"}
          px={"xl"}
          radius={"md"}
        >
          <Text fw={600} size="md" mb={-8}>
            {lang.profile.addresses}
          </Text>

          <Group gap={"xl"}>
            <TextInput
              name="city"
              label={lang.profile.city}
              placeholder={lang.profile.city}
              defaultValue={profile?.city}
              w={250}
            />
            <TextInput
              label={lang.profile.postal_code}
              placeholder={lang.profile.postal_code}
              name="zip_code"
              defaultValue={profile?.zip_code}
              w={250}
            />
          </Group>

          <Group gap={"xl"}>
            <TextInput
              name="address"
              label={lang.profile.address}
              placeholder={lang.profile.address}
              defaultValue={profile?.address}
              w={250}
            />
            <CountrySelector
              label={lang.profile.country}
              placeholder={lang.profile.country}
              name="country"
              defaultValue={profile.country}
              w={250}
            />
          </Group>
        </Paper>

        <Paper
          withBorder
          component={Stack}
          w={"fit-content"}
          p={"md"}
          px={"xl"}
          radius={"md"}
        >
          <Text fw={600} size="md" mb={-8}>
            {lang.profile.advanced}
          </Text>

          <Group gap={"xl"} align="start">
            <Select
              data={timezones}
              defaultValue={profile.timezone}
              w={250}
              label={lang.profile.timezone.label}
              placeholder={lang.profile.country}
              description={lang.profile.timezone_text}
              name="timezone"
              searchable
            />
            <TagsInput
              defaultValue={profile?.emails_notification || []}
              w={250}
              description={lang.profile.mail_notif_text}
              label={lang.profile.mail_notification}
              placeholder={lang.profile.mail_notification}
              name="mail_notification"
            />
          </Group>
        </Paper>

        <Paper
          withBorder
          component={Stack}
          w={"fit-content"}
          p={"md"}
          px={"xl"}
          radius={"md"}
        >
          <Text fw={600} size="md" mb={-8}>
            {lang.profile.defaults}
          </Text>
          <Group gap={"xl"} align="start">
            <Select
              label={lang.profile.list_language}
              placeholder={lang.profile.language}
              name="contact_list_language"
              defaultValue={profile.contact_list_language}
              description={lang.profile.list_language_text}
              w={250}
              data={[
                { label: "English", value: "ENG" },
                { label: "Français", value: "FRA" },
              ]}
            />

            <CountrySelector
              label={lang.profile.list_country}
              placeholder={lang.profile.country}
              name="contact_list_country"
              description={lang.profile.list_country_text}
              defaultValue={profile.contact_list_country}
              w={250}
            />
          </Group>
        </Paper>
      </Stack>

      <Group w={600} justify="space-between" pb={8}>
        <Button
          variant="outline"
          type="reset"
          leftSection={<IconRestore size={18} />}
        >
          {lang.profile.reset_button_label}
        </Button>
        <Button
          variant="filled"
          type="submit"
          color="tertiary.8"
          leftSection={<IconDeviceFloppy size={18} />}
        >
          {lang.profile.save_button_label}
        </Button>
      </Group>
    </Stack>
  );
};

export default Profile;
