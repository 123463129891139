import { useProfileStore } from "../stores/UserStore";

export async function executeSequentially(promises, onChange, onError) {
  for (const p of promises) {
    const result = await p().catch((err) => {
      onError?.(err);
    });
    onChange?.(result);
  }
}

export function transformFilterToServer(filter, disableQueryTransform) {
  let query = filter?.quickFilterValues?.join(" ");
  let result =
    query && !disableQueryTransform
      ? {
          mode: "and",
          values: [
            { field_name: "any_datafield", type: "contains", value: query },
          ],
        }
      : filter?.items?.length > 0
      ? {
          mode: filter?.logicOperator?.toLowerCase(),
          values:
            filter?.items.map((item) => ({
              field_name: item?.field,
              type: item?.operator.toLowerCase(),
              value: item?.value,
            })) || [],
        }
      : null;

  if (query && filter?.items?.length > 0) {
    result.values.push({
      mode: filter?.logicOperator?.toLowerCase(),
      values:
        filter?.items.map((item) => ({
          field_name: item?.field,
          type: item?.operator.toLowerCase(),
          value: item?.value,
        })) || [],
    });
  }
  return result;
}

export function transformSortToServer(sortModel) {
  const sort = sortModel?.[0];
  if (sort) {
    return {
      field_name: sort.field,
      sort_direction: sort.sort,
    };
  }
  return null;
}

export function filterDeaccentedSearch({ options, search }) {
  const splittedSearch = search
    .toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .split(" ");

  return options.filter((option) => {
    const words = option.label
      .toLowerCase()
      .trim()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "")
      .split(" ");

    return splittedSearch.every((searchWord) => {
      return words.some((word) => word.includes(searchWord));
    });
  });
}

export function getPermission(name) {
  const profile = useProfileStore.getState().profile;

  return profile?.permissions?.find((x) => x.name === name)?.value || false;
}

export function getLimit(permission, profile, type) {
  return profile?.permissions?.find((x) => x.name === permission)?.[type] || 0;
}

export function getObjectWithChangedProperties(obj1, obj2) {
  const changedProperties = {};

  for (const key in obj2) {
    if (Object.hasOwnProperty.call(obj2, key)) {
      if (
        !Object.hasOwnProperty.call(obj1, key) ||
        JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])
      ) {
        // Check if the property is not in obj1 or its value has changed
        changedProperties[key] = obj2[key];
      }
    }
  }

  return changedProperties;
}

export function sortDescId(rows) {
  if (rows?.length > 0) {
    return rows.sort((a, b) => b.id - a.id);
  }
  return [];
}

export function wrapDatafieldValue(v) {
  return `<span class="var">{${v.replaceAll("%", "")}}</span> `;
}
