import { useLoaderData, useNavigate } from "react-router-dom";
import CreateLinkedinSender from "./CreateLinkedinSender";
import { Group, Stack } from "@mantine/core";
import LinkedinAccount from "../../tools/linkedin/components/LinkedinAccount";
import PageTitle from "../../../../components/View/PageTitle";
import { useLanguage } from "../../../../stores/LanguageStore";

const LinkedinPage = () => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();
  const accountProfile = useLoaderData();

  const onCreated = (id) => {
    navigate("../?highlight=" + id);
  };

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle
          title={
            accountProfile?.name ||
            lang.linkedin_accounts.browser_tab_title_create
          }
        />
        {accountProfile && <CreateLinkedinSender />}
      </Group>

      <Stack className="layout-block" gap={"xs"} flex={1}>
        <LinkedinAccount
          onCreated={onCreated}
          accountProfile={accountProfile}
          isPage
        />
      </Stack>
    </>
  );
};

export default LinkedinPage;
