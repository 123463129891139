import {
  IconArrowMerge,
  IconArrowsSplit,
  IconBrandZapier,
  IconBriefcase,
  IconCopy,
  IconDatabaseShare,
  IconFileImport,
  IconLanguage,
  IconMessageCircle,
  IconMessagePlus,
  IconPlus,
  IconQuestionMark,
  IconSparkles,
  IconThumbUp,
  IconUserCheck,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconLinkedin } from "../../../../../components/Icons/IconLinkedin";
import { IconGmaps } from "../../../../../components/Icons/IconGmaps";
import { IconSalesNavigator } from "../../../../../components/Icons/IconSalesNavigator";

export const types = (type, isOrigin, isEmail) => {
  let lang = useLanguage.getState().language;
  let all = {
    Creation: {
      icon: (
        <IconPlus
          style={{ width: isOrigin ? 16 : 18, opacity: isOrigin ? 0.75 : 1 }}
        />
      ),
      label: lang.contact_lists.jobs.types.Creation,
    },
    Copy: {
      icon: <IconCopy style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.Copy,
    },
    CopyTo: {
      icon: <IconCopy style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.CopyTo,
    },
    Split: {
      icon: <IconArrowsSplit style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.Split,
    },
    Merge: {
      icon: <IconArrowMerge style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.Merge,
    },
    Import: {
      icon: <IconFileImport style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.Import,
    },
    Enrich: {
      icon: <IconUserCheck style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.Enrich + (isEmail ? " Email" : ""),
    },
    LinkedinExtractPeoplesSearch: {
      icon: <IconLinkedin style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.LinkedinExtractPeoplesSearch,
    },
    LinkedinExtractSalesNavigatorPeoplesSearch: {
      icon: <IconLinkedin style={{ width: isOrigin ? 16 : 18 }} />,
      label:
        lang.contact_lists.jobs.types
          .LinkedinExtractSalesNavigatorPeoplesSearch,
    },
    LinkedinExtractPostCommenters: {
      icon: <IconMessageCircle style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.LinkedinExtractPostCommenters,
    },
    LinkedinExtractPostLikers: {
      icon: <IconThumbUp style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.LinkedinExtractPostLikers,
    },
    LinkedinExtractPublishersJobOffers: {
      icon: <IconBriefcase style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.LinkedinExtractPublishersJobOffers,
    },
    ContactListCopyContactListToPrm: {
      icon: <IconCopy style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.ContactListCopyContactListToPrm,
    },
    LinkedinExtractConnections: {
      icon: <IconLinkedin style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.LinkedinExtractConnections,
    },
    ConvertLinkedinLinks: {
      icon: <IconLinkedin style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.ConvertLinkedinLinks,
    },
    LinkedinAccountDisconnected: {
      icon: <IconLinkedin style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.LinkedinAccountDisconnected,
    },
    GoogleExtractMapsSearch: {
      icon: <IconGmaps style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.GoogleExtractMapsSearch,
    },
    IceBreaker: {
      icon: <IconMessagePlus style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.IceBreaker,
    },
    ZapierSendDataPrm: {
      icon: <IconBrandZapier style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.ZapierSendDataPrm,
    },
    ZapierSendDataStatistics: {
      icon: <IconBrandZapier style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.ZapierSendDataStatistics,
    },
    Translate: {
      icon: <IconLanguage style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.Translate,
    },
    LinkedinExtractPeoplesSearchAlternative: {
      icon: <IconLinkedin style={{ width: isOrigin ? 16 : 18 }} />,
      label:
        lang.contact_lists.jobs.types
          .LinkedinExtractSalesNavigatorPeoplesSearch,
    },
    LinkedinExtractSalesNavigatorPeoplesSearchAlternative: {
      icon: <IconLinkedin style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.LinkedinExtractPeoplesSearch,
    },
    DatabaseExtractContacts: {
      icon: <IconDatabaseShare style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.DatabaseExtractContacts,
    },
    LinkedinExtractSalesNavigatorCompaniesSearch: {
      icon: <IconSalesNavigator style={{ width: isOrigin ? 16 : 18 }} />,
      label:
        lang.contact_lists.jobs.types
          .LinkedinExtractSalesNavigatorCompaniesSearch,
    },
    LinkedinExtractSalesNavigatorCompaniesSearchAlternative: {
      icon: <IconSalesNavigator style={{ width: isOrigin ? 16 : 18 }} />,
      label:
        lang.contact_lists.jobs.types
          .LinkedinExtractSalesNavigatorCompaniesSearchAlternative,
    },
    DatafieldAi: {
      icon: <IconSparkles style={{ width: isOrigin ? 16 : 18 }} />,
      label: lang.contact_lists.jobs.types.DatafieldAi,
    },
  };

  return (
    all?.[type] || {
      icon: <IconQuestionMark style={{ width: isOrigin ? 16 : 18 }} />,
      label: "Unknown",
    }
  );
};
