import { Button, Group, Stack, Tabs, Text, Tooltip, rem } from "@mantine/core";
import {
  IconAlertTriangle,
  IconArrowAutofitLeft,
  IconArrowAutofitRight,
  IconBrush,
  IconReportMoney,
  IconUser,
} from "@tabler/icons-react";
import { useMemo, useState } from "react";
import Profile from "./Profile";
import Interface from "./Interface";
import Dangerzone from "./Dangerzone";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";
import Pricing from "../newPricing/Pricing";
import PageTitle from "../../../components/View/PageTitle";
import PagePermission from "../../../components/hooks/usePagePermission";
import { getPermission } from "../../../functions/utilFunctions";

const iconStyle = { width: rem(22), height: rem(22) };

const AccountSettings = () => {
  const lang = useLanguage((s) => s.language);
  const { tab } = useParams();
  const [openedLeft, setOpenedLeft] = useState(true);

  const tabs = useMemo(() => {
    let canDisplayPricing = getPermission("displayPricing");

    let result = [
      {
        label: lang.profile.browser_tab_title,
        value: "profile",
        icon: <IconUser style={iconStyle} />,
        component: <Profile />,
      },
      {
        label: lang.profile.interface,
        value: "interface",
        icon: <IconBrush style={iconStyle} />,
        component: <Interface />,
      },
    ];

    if (canDisplayPricing) {
      result.push({
        label: lang.profile.subscription,
        value: "subscription",
        icon: <IconReportMoney style={iconStyle} />,
        component: <Pricing />,
      });
    }

    result.push({
      label: lang.profile.deletion,
      value: "deletion",
      icon: <IconAlertTriangle style={iconStyle} />,
      isRed: true,
      component: <Dangerzone />,
    });

    return result;
  }, [
    lang.profile.browser_tab_title,
    lang.profile.deletion,
    lang.profile.interface,
    lang.profile.subscription,
  ]);

  const navigate = useNavigate();
  const handleTabChange = (v) => {
    navigate("/account-settings/" + v);
  };

  return (
    <>
      {tab === "subscription" && (
        <PagePermission permission={"displayPricing"} />
      )}
      <Group className="layout-block top" w={"100%"}>
        <PageTitle title={lang.routes["account-settings"]} />
      </Group>
      <Stack className="layout-block" gap={0} flex={"1 1 auto"} h={0}>
        <Tabs
          defaultValue={tab || "profile"}
          onChange={handleTabChange}
          orientation="vertical"
          h={"100%"}
          flex={"1 1 auto"}
          w={"100%"}
          keepMounted={false}
        >
          <Tabs.List mr={32}>
            {tabs.map((x) => (
              <Tooltip key={x.value} label={x.label} position="right">
                <Tabs.Tab
                  value={x.value}
                  key={x.value}
                  size={2}
                  bg={
                    tab === x.value
                      ? x.isRed
                        ? "var(--mantine-color-red-1)"
                        : "var(--mantine-primary-color-light-hover)"
                      : undefined
                  }
                  color={x.isRed ? "red" : "primary"}
                  h={64}
                  w={openedLeft ? 175 : 55}
                  c={x.isRed ? "red" : undefined}
                >
                  <Group>
                    {x.icon}
                    {openedLeft && <Text>{x.label}</Text>}
                  </Group>
                </Tabs.Tab>
              </Tooltip>
            ))}

            <Button
              px={0}
              variant="subtle"
              onClick={() => setOpenedLeft(!openedLeft)}
              radius={0}
              w={54}
              ml={openedLeft ? "auto" : 0}
              mt={"auto"}
            >
              {openedLeft ? (
                <IconArrowAutofitLeft size={18} />
              ) : (
                <IconArrowAutofitRight size={18} />
              )}
            </Button>
          </Tabs.List>

          <Stack flex={"1 1 auto"} style={{ overflow: "auto" }}>
            {tabs.map((tab) => (
              <Tabs.Panel key={tab.value} value={tab.value} keepMounted={false}>
                {tab.component}
              </Tabs.Panel>
            ))}
          </Stack>
        </Tabs>
      </Stack>
    </>
  );
};

export default AccountSettings;
