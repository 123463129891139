import {
  Group,
  InputLabel,
  Paper,
  Slider,
  Stack,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconTemperature } from "@tabler/icons-react";

const TemperatureSlider = ({ sm, defaultValue }) => {
  const lang = useLanguage((s) => s.language);
  return sm ? (
    <Paper component={Group} w={150} withBorder p={4}>
      <Tooltip label={lang.model_emails.create.dialog.temperature_desc}>
        <ThemeIcon variant="subtle" color="gray" size={"sm"}>
          <IconTemperature />
        </ThemeIcon>
      </Tooltip>
      <Slider
        name="temperature"
        min={0}
        max={1}
        step={0.1}
        flex={1}
        defaultValue={defaultValue || 0.5}
      />
    </Paper>
  ) : (
    <Stack gap={0} w={"100%"}>
      <InputLabel size="sm" required>
        {lang.model_emails.create.dialog.temperature}
      </InputLabel>
      <Slider
        name="temperature"
        min={0}
        max={1}
        step={0.1}
        mt={4}
        mb={16}
        className="temperature-slider"
        defaultValue={defaultValue || 0.5}
        marks={[
          { value: 0, label: lang.model_emails.create.dialog.repetitive },
          { value: 1, label: lang.model_emails.create.dialog.random },
        ]}
      />
    </Stack>
  );
};

export default TemperatureSlider;
