import {
  Badge,
  Button,
  Divider,
  Paper,
  Stack,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { LinksGroup } from "./NavbarLinksGroup/NavbarLinksGroup.jsx";
import { useUIStore } from "../../stores/UIStore.js";
import CreateCampaignButton from "./CreateCampaignButton.jsx";
import {
  IconArrowBadgeUpFilled,
  IconBuildingStore,
  IconPlus,
} from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore.js";
import Ressources from "./Ressources.jsx";
import { IconChartInfographic } from "@tabler/icons-react";
import { IconAddressBook } from "@tabler/icons-react";
import { useLeadsStore } from "../../stores/LeadsStore.js";
import { getPermission } from "../../functions/utilFunctions.js";
import { Link } from "react-router-dom";
import { useProfileStore } from "../../stores/UserStore.js";
import { useEffect } from "react";

const Navbar = () => {
  const lang = useLanguage((s) => s.language);
  const campaignOpened = useUIStore((s) => s.campaignOpened);
  const setCampaignOpened = useUIStore((s) => s.setCampaignOpened);
  const prms = useLeadsStore((s) => s.prms);
  const profile = useProfileStore((s) => s.profile);

  useEffect(() => {
    if (!getPermission("displaySideMenu")) {
      useUIStore.getState().setAsideCollapsed(true);
    } else {
      useUIStore.getState().setAsideCollapsed(false);
    }
  }, [profile]);

  if (getPermission("displaySideMenu"))
    return (
      <Paper
        component={Stack}
        gap={0}
        className="navbar"
        w={closed ? 60 : 200}
        style={{ borderRight: "1px solid #00000018" }}
        radius={0}
        h={"100%"}
        pb={6}
      >
        {getPermission("accessWorkflows") && (
          <CreateCampaignButton
            inNavbar
            navbarClosed={closed}
            opened={campaignOpened}
          />
        )}

        {getPermission("accessContactLists") && (
          <Button
            component={Link}
            to={"/contact-lists/import"}
            leftSection={<IconPlus size={18} />}
            id={"create-list"}
            radius={"sm"}
            w={"calc(100% - 24px)"}
            mx={12}
            h={36}
            variant="light"
            // gradient={{ from: "primary.4", to: "primary.6", deg: 90 }}
            mt={12}
            mb={8}
            justify="start"
          >
            {lang.header.menus.link_groups.create_list}
          </Button>
        )}

        {getPermission("accessModels") && (
          <Ressources
            inNavbar
            navbarClosed={closed}
            opened={campaignOpened}
            setOpened={setCampaignOpened}
          />
        )}

        {getPermission("displayDetailedStatistics") && (
          <LinksGroup
            id={"statistics"}
            path={"/statistics"}
            icon={IconChartInfographic}
            name={lang.header.navigations.statistics.label}
            navbarClosed={closed}
          />
        )}

        {getPermission("accessPRM") && (
          <LinksGroup
            id={"leads-tracking"}
            path={"/leads-tracking"}
            icon={IconAddressBook}
            name={lang.header.navigations.prm.label}
            navbarClosed={closed}
            rightSection={
              prms?.[0]?.new_answerers_since_date_view > 0 && (
                <Tooltip
                  label={`${prms?.[0]?.new_answerers_since_date_view} ${lang.header.navigations.prm.new_answerers}`}
                >
                  <Badge
                    size="sm"
                    color="secondary.5"
                    pos={"absolute"}
                    right={10}
                  >
                    {prms?.[0]?.new_answerers_since_date_view}{" "}
                  </Badge>
                </Tooltip>
              )
            }
          />
        )}

        <Divider mt={"auto"} mx={"35%"} size={"sm"} mb={8} />

        {getPermission("displayResellers") && (
          <LinksGroup
            id={"/resellers/white-labels"}
            path={"/resellers/white-labels"}
            name={lang.resellers.browser_tab_title}
            customIcon={
              <ThemeIcon variant={"subtle"} size={28} color={"primary"}>
                <IconBuildingStore size={20} />
              </ThemeIcon>
            }
          />
        )}

        {getPermission("displayPricing") && (
          <LinksGroup
            id={"/account-settings/subscription"}
            path={"/account-settings/subscription"}
            name={lang.pricing.browser_tab_title}
            customIcon={
              <ThemeIcon variant={"subtle"} size={28} color={"secondary"}>
                <IconArrowBadgeUpFilled size={20} />
              </ThemeIcon>
            }
          />
        )}
      </Paper>
    );

  return "";
};

export default Navbar;
