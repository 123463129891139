import {
  IconAlertTriangle,
  IconBell,
  IconBrandLinkedin,
  IconCirclePlus,
  IconClick,
  IconDeviceMobile,
  IconMail,
  IconMessageReply,
  IconMicrophone,
  IconNote,
  IconSend,
  IconTimelineEvent,
  IconUser,
  IconUserOff,
  IconUserX,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import { IconMicrophoneOff } from "@tabler/icons-react";

export function getProps(history) {
  const lang = useLanguage.getState().language;
  if (history.history_type === "creation") {
    return {
      icon: IconCirclePlus,
      iconColor: "green",
      label: lang.prm.details.history.added_to_prm,
    };
  }
  if (history.history_type === "note") {
    return {
      icon: IconNote,
      iconColor: "orange",
      label: history?.isCreate
        ? lang.prm.details.history.props.new_note
        : lang.prm.details.history.props.note,
    };
  }
  if (history.history_type === "reply") {
    return {
      icon: IconMessageReply,
      label: lang.prm.details.history.props.reply,
    };
  }
  if (history.history_type === "sent") {
    return {
      icon: IconSend,
      iconColor: "pink",
      label: lang.prm.details.history.props.sent,
    };
  } else {
    if (history.event.includes("email")) {
      return getEmailProps(history.event);
    }

    if (history.event.includes("linkedin")) {
      return getLinkedInProps(history.event);
    }

    if (history.event.includes("sms")) {
      return getSmsProps(history.event);
    }

    if (history.event.includes("smv")) {
      return getSmvProps(history.event);
    }
    return getOtherEvents(history.event);
  }
}

function getEmailProps(event) {
  const lang = useLanguage.getState().language;
  if (event === "email_open") {
    return {
      icon: IconMail,
      label: lang.prm.details.history.props.email_open,
      score_field: "score_open_count",
    };
  }
  if (event === "email_open_no_click") {
    return {
      icon: IconMail,
      label: lang.prm.details.history.props.email_open_no_click,
      score_field: "score_open_count",
    };
  }
  if (event === "email_answered") {
    return {
      icon: IconMail,
      label: lang.prm.details.history.props.email_answered,
      score_field: "score_answer_count",
    };
  }
  if (event === "email_sent") {
    return {
      icon: IconMail,
      label: lang.prm.details.history.props.email_sent,
    };
  }
  if (event === "email_queued") {
    return {
      icon: IconMail,
      label: lang.prm.details.history.props.email_queued,
    };
  }
}

function getLinkedInProps(event) {
  const lang = useLanguage.getState().language;
  if (event === "linkedin_invitation_accepted") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_invitation_accepted,
      score_field: "score_invitation_accepted_count",
    };
  }
  if (event === "linkedin_invitation_sent") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_invitation_sent,
    };
  }
  if (event === "linkedin_invitation_queued") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_invitation_queued,
    };
  }
  if (event === "linkedin_message_queued") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_message_queued,
    };
  }
  if (event === "linkedin_message_sent") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_message_sent,
    };
  }
  if (event === "linkedin_message_answered") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_message_answered,
      score_field: "score_answer_count",
    };
  }
  if (event === "linkedin_visit_queued") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_visit_queued,
    };
  }
  if (event === "linkedin_visited") {
    return {
      icon: IconBrandLinkedin,
      label: lang.prm.details.history.props.linkedin_visited,
    };
  }
}

function getSmsProps(event) {
  const lang = useLanguage.getState().language;
  if (event === "sms_got") {
    return {
      icon: IconDeviceMobile,
      label: lang.prm.details.history.props.sms_got,
    };
  }
  if (event === "sms_not_got") {
    return {
      icon: IconDeviceMobile,
      label: lang.prm.details.history.props.sms_not_got,
    };
  }
  if (event === "sms_sent") {
    return {
      icon: IconDeviceMobile,
      label: lang.prm.details.history.props.sms_sent,
    };
  }
  if (event === "sms_replied") {
    return {
      icon: IconDeviceMobile,
      label: lang.prm.details.history.props.sms_replied,
      score_field: "score_answer_count",
    };
  }
  if (event === "sms_queued") {
    return {
      icon: IconDeviceMobile,
      label: lang.prm.details.history.props.sms_queued,
    };
  }
}

function getSmvProps(event) {
  const lang = useLanguage.getState().language;
  if (event === "smv_got") {
    return {
      icon: IconMicrophone,
      label: lang.prm.details.history.props.smv_got,
    };
  }
  if (event === "smv_not_got") {
    return {
      icon: IconMicrophoneOff,
      label: lang.prm.details.history.props.smv_not_got,
    };
  }
  if (event === "smv_sent") {
    return {
      icon: IconMicrophone,
      label: lang.prm.details.history.props.smv_sent,
    };
  }
  if (event === "smv_queued") {
    return {
      icon: IconMicrophone,
      label: lang.prm.details.history.props.smv_queued,
    };
  }
}

function getOtherEvents(event) {
  const lang = useLanguage.getState().language;
  if (event === "link_click") {
    return {
      icon: IconClick,
      label: lang.prm.details.history.props.link_click,
      score_field: "score_link_click_count",
    };
  }
  if (event === "not_active") {
    return {
      icon: IconUserX,
      label: lang.prm.details.history.props.not_active,
    };
  }
  if (event === "all_contacts") {
    return {
      icon: IconUser,
      label: lang.prm.details.history.props.all_contacts,
    };
  }
  if (event === "call_custom") {
    return {
      icon: IconBell,
      label: lang.prm.details.history.props.call_custom,
    };
  }
  if (event === "error_occured") {
    return {
      icon: IconAlertTriangle,
      label: lang.prm.details.history.props.error_occured,
    };
  }
  if (event === "unsubscribe") {
    return {
      icon: IconUserOff,
      label: lang.prm.details.history.props.unsubscribe,
    };
  }
  if (event === "unsubscribe_advertiser") {
    return {
      icon: IconUserOff,
      label: lang.prm.details.history.props.unsubscribe_advertiser,
    };
  }
  if (event === "excluded_programmation") {
    return {
      icon: IconUserOff,
      label: lang.prm.details.history.props.excluded_programmation,
    };
  }
  if (event === "blacklisted") {
    return {
      icon: IconUserOff,
      label: lang.prm.details.history.props.blacklisted,
    };
  }
  return {
    icon: IconTimelineEvent,
    label: lang.prm.details.history.props.default,
  };
}
