import { useMemo } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { MultiSelect } from "@mantine/core";
import { IconBuildingFactory } from "@tabler/icons-react";

const IndustriesSelector = ({ values, setValues }) => {
  const lang = useLanguage((s) => s.language);
  const options = useMemo(() => {
    let o = lang.targeting_linkedin_google.industries;
    let result = [];
    Object.keys(o).forEach((key) => {
      result.push({ label: o[key], value: key.toString() });
    });

    return result;
  }, [lang]);

  const onChange = (v) => {
    setValues(v);
  };
  return (
    <MultiSelect
      label={lang.targeting_linkedin_google.industries_name}
      placeholder={lang.tags.enter_to_add}
      data={options}
      value={values}
      onChange={onChange}
      w={"100%"}
      leftSection={<IconBuildingFactory size={18} />}
      clearable
      searchable
      size="sm"
      styles={{ label: { fontSize: 13 } }}
    />
  );
};

export default IndustriesSelector;
