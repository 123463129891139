import {
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Button, Indicator, Popover, Tooltip } from "@mantine/core";
import { IconLayoutColumns } from "@tabler/icons-react";
import TableColumnsContent from "./TableColumnsContent";
import { defaultButtonType } from "../../initialState";
import { updateProfile } from "../../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { useProfileStore } from "../../../../stores/UserStore";

const TableColumns = ({ pageOrderName }) => {
  const lang = useLanguage((s) => s.language);
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);

  const apiRef = useGridApiContext();
  const columnVisibilityModel = useGridSelector(
    apiRef,
    gridColumnVisibilityModelSelector
  );

  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);

  const hiddenColumns = useMemo(() => {
    let x = [];
    Object.keys(columnVisibilityModel).forEach((key) =>
      columnVisibilityModel[key] === false ? x.push(key) : null
    );

    return x;
  }, [columnVisibilityModel]);

  const handleSave = () => {
    if (!edited) {
      return;
    }
    const allColumns = [...columns];

    let newOrder = allColumns.map((column) => column.field);

    let visibleColumns = [];
    allColumns.forEach((column) => {
      if (Object.keys(columnVisibilityModel).includes(column.field)) {
        if (columnVisibilityModel?.[column.field])
          visibleColumns.push(column.field);
      } else visibleColumns.push(column.field);
    });

    let columnsSize = {};
    columns.forEach((column) => {
      columnsSize[column.field] = column.width;
    });

    const newVisibleColumns = visibleColumns;

    const profile = useProfileStore.getState().profile;
    const setProfile = useProfileStore.getState().setProfile;

    const newData = {
      saved_order: {
        ...(profile?.saved_order || {}),

        [pageOrderName]: {
          hidden_columns: columns
            .filter((x) => !newVisibleColumns.includes(x.field))
            .map((x) => x.field),
            order_columns: newOrder.filter((x) => x !== "actions"),
          size_columns: columnsSize,
        },
      },
    };
    console.log(newData);

    setLoading(true);
    updateProfile(null, newData)
      .then((response) => {
        notifications.show({
          message: lang.table.toolbar.columns.visibility_saved,
          color: "tertiary.8",
        });
        setProfile({ ...profile, ...newData });
      })
      .finally(() => setLoading(false));
  };

  const onChange = (column, newValue) => {
    apiRef.current.setColumnVisibility(column, newValue);
    setEdited(true);
  };

  const hideAll = () => {
    const newModel = {};

    columns.forEach((col) => {
      newModel[col.field] = false;
    });

    apiRef.current.setColumnVisibilityModel(newModel);
  };

  const showAll = () => {
    const newModel = {};

    columns.forEach((col) => {
      newModel[col.field] = true;
    });
    apiRef.current.setColumnVisibilityModel(newModel);
  };

  return (
    <>
      <Popover
        shadow="md"
        position="bottom-start"
        offset={1}
        onClose={handleSave}
      >
        <Popover.Target>
          <Tooltip label={lang.table.toolbar.columns.button_tooltip}>
            <Button
              radius={0}
              variant={defaultButtonType}
              style={{ borderLeft: 0 }}
              className="toolbar-action-button"
              loading={loading}
            >
              <Indicator
                label={hiddenColumns.length}
                color="red"
                offset={2}
                disabled={hiddenColumns.length === 0}
                // withBorder
                size={16}
              >
                <IconLayoutColumns size={18} />
              </Indicator>
              <span style={{ marginLeft: 6 }}>
                {lang.table.toolbar.columns.button_label}
              </span>
            </Button>
          </Tooltip>
        </Popover.Target>

        <Popover.Dropdown pt={2}>
          <TableColumnsContent
            columnVisibility={columnVisibilityModel}
            columns={columns}
            onColumnCheck={onChange}
            hideAll={hideAll}
            showAll={showAll}
            pageOrderName={pageOrderName}
          />
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default TableColumns;
