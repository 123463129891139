import { useLanguage } from "../../../../../stores/LanguageStore";
import { MultiSelect } from "@mantine/core";
import { IconClock } from "@tabler/icons-react";

const fr_options = [
  { label: "En formation", value: "in_training" },
  { label: "Débutant", value: "entry_level" },
  { label: "Senior", value: "senior" },
  { label: "Stratégique", value: "strategic" },
  { label: "Manager débutant", value: "entry_level_manager" },
  { label: "Manager expérimenté", value: "experienced_manager" },
  { label: "Directeur", value: "director" },
  { label: "Vice-président", value: "vice_president" },
  { label: "CXO", value: "cxo" },
  { label: "Propriétaire/Partenaire", value: "owner_partner" },
];
const en_options = [
  { label: "In Training", value: "in_training" },
  { label: "Entry Level", value: "entry_level" },
  { label: "Senior", value: "senior" },
  { label: "Strategic", value: "strategic" },
  { label: "Entry Level Manager", value: "entry_level_manager" },
  { label: "Experienced Manager", value: "experienced_manager" },
  { label: "Director", value: "director" },
  { label: "Vice President", value: "vice_president" },
  { label: "CXO", value: "cxo" },
  { label: "Owner/Partner", value: "owner_partner" },
];

const SenioritySelector = ({ values, setValues }) => {
  const lang = useLanguage((s) => s.language);
  const onChange = (v) => {
    setValues(v);
  };
  return (
    <MultiSelect
      label={lang.targeting_linkedin_google.seniority_levels}
      placeholder={lang.targeting_linkedin_google.choose}
      data={lang.id === "FRA" ? fr_options : en_options}
      value={values}
      onChange={onChange}
      w={"100%"}
      leftSection={<IconClock size={18} />}
      clearable
      size="sm"
      styles={{ label: { fontSize: 13 } }}
    />
  );
};

export default SenioritySelector;
