import { Button, Group, Paper, Text } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import DisableAccount from "../admin/DisableAccount";
import { useProfileStore } from "../../../stores/UserStore";
import DeleteAccount from "../admin/DeleteAccount";
import { useNavigate } from "react-router-dom";

const Dangerzone = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const navigate = useNavigate();
  const onDisableSuccess = () => {
    setTimeout(() => {
      navigate("/login", { replace: true });
      localStorage.clear();
    }, 1000);
  };

  return (
    <Paper withBorder w={"fit-content"} p={"md"} px={"xl"} radius={"md"}>
      <Text fw={600} size="md">
        {lang.profile.deletion_title}
      </Text>
      <Text size="sm" mt={"md"}>
        {lang.profile.deletion_text}
      </Text>

      <Group gap={"xl"} mt={"md"}>
        <DisableAccount profile={profile} onSuccess={onDisableSuccess} />

        <DeleteAccount profile={profile} onSuccess={onDisableSuccess} />
      </Group>
    </Paper>
  );
};

export default Dangerzone;
