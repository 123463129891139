import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
} from "@mui/x-data-grid-pro";
import { useProfileStore } from "../../stores/UserStore";
import { filterNumberTypes } from "../../components/Filter/filterSettings";

export const defaultPageSize = 25;

export const defaultButtonType = "outline";

function getOrder({ pageOrderName, desiredOrder }) {
  const profile = useProfileStore.getState().profile;
  const order =
    profile?.saved_order?.[pageOrderName]?.order_columns || desiredOrder;

  console.log({ order });

  if (order) {
    return order.map((x) => x.toString());
  }
  return [];
}
function getSizes({ pageOrderName }) {
  const profile = useProfileStore.getState().profile;
  let size = {};

  let saved_size = profile?.saved_order?.[pageOrderName]?.size_columns;

  if (saved_size) {
    Object.keys(saved_size).forEach((key) => {
      size[key] = {
        width: saved_size[key],
      };
    });
  }

  return size;
}
function getVisibility({ pageOrderName, columns }) {
  const profile = useProfileStore.getState().profile;
  const hidden_columns = profile?.saved_order?.[pageOrderName]?.hidden_columns;

  console.log(hidden_columns);

  let model = {};

  if (hidden_columns) {
    [{ field: "__check__" }, ...columns].forEach((column) => {
      if (hidden_columns.includes(column.field)) {
        model[column.field] = false;
      } else model[column.field] = true;
    });
  }

  return model;
}
function getInitialFilter({ pageName, columns, defaultSearch, defaultFilter }) {
  const profile = useProfileStore.getState().profile;
  let pinnedFilter = profile?.saved_filters?.[pageName]?.find((x) => x.pinned);

  // Contact ID filter
  let params = new URLSearchParams(location.search);
  let paramsFilter = params.get("filter");
  if (paramsFilter) {
    let parsed = JSON.parse(paramsFilter);
    const newFilter = {
      items:
        parsed?.values?.map((val, index) => ({
          field: val.field_name,
          value: val.value?.toString(),
          operator: val.type,
          id: index.toString(),
        })) || [],
      logicOperator: parsed?.mode || "and",
      quickFilterValues: defaultSearch?.split(" ") || [""],
    };

    console.log(newFilter);

    return newFilter;
  }

  // Pinned filter
  if (pinnedFilter || defaultFilter) {
    let f = defaultFilter || pinnedFilter?.filter;

    const savedFilter = {
      mode: f?.mode,
      values: f?.values?.map((item) => {
        let isNumber =
          columns.find((x) => x.field === item.field_name)?.type === "number";

        return {
          ...item,
          type: isNumber
            ? Object.keys(filterNumberTypes).find(
                (key) => filterNumberTypes[key] === item.type
              )
            : item?.type,
        };
      }),
    };

    const newFilter = {
      items:
        savedFilter?.values?.map((val, index) => ({
          field: val.field_name,
          value: val.value,
          operator: val.type,
          id: index.toString(),
        })) || [],
      logicOperator: savedFilter?.mode || "and",
      quickFilterValues: defaultSearch?.split(" ") || [""],
    };

    return newFilter;
  }

  return {
    items: [],
    logicOperator: "and",
    quickFilterValues: defaultSearch?.split(" ") || [""],
  };
}

export function getDefaultDensity() {
  const profile = useProfileStore.getState().profile;

  return profile?.table_density || "standard";
}

export const initialTableState = ({
  pinnedLeft,
  disablePerPage,
  pageName,
  pageOrderName,
  columns,
  desiredOrder,
  desiredVisibleColumns,
  defaultSearch,
  defaultFilter,
}) => {
  return {
    pagination: {
      paginationModel: {
        pageSize: disablePerPage ? 100 : defaultPageSize,
      },
    },
    pinnedColumns: {
      left: pinnedLeft || [
        GRID_CHECKBOX_SELECTION_FIELD,
        GRID_TREE_DATA_GROUPING_FIELD,
      ],
      right: ["actions"],
    },
    columns: {
      columnVisibilityModel: getVisibility({
        pageOrderName,
        columns,
        desiredVisibleColumns,
      }),
      orderedFields: getOrder({ pageOrderName, desiredOrder }),
      dimensions: getSizes({ pageOrderName }),
    },
    filter: {
      filterModel: getInitialFilter({
        pageName,
        columns,
        defaultSearch,
        defaultFilter,
      }),
    },
  };
};
