import {
  ActionIcon,
  Box,
  Button,
  Group,
  Modal,
  Text,
  Timeline,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useLanguage } from "../../../../stores/LanguageStore";
import { IconLinkedin } from "../../../../components/Icons/IconLinkedin";
import {
  IconCheck,
  IconColumns,
  IconDeviceFloppy,
  IconFileImport,
  IconHelp,
  IconMail,
  IconMailCheck,
  IconMailSearch,
  IconPhone,
  IconPhonePlus,
  IconUserScan,
  IconUserSearch,
} from "@tabler/icons-react";
import BackButton from "../../../../components/View/BackButton";
import { IconGmaps } from "../../../../components/Icons/IconGmaps";

const icon_props = { size: 18 };

function getLinkedinTexts() {
  const lang = useLanguage.getState().language;
  return [
    {
      label: lang.contact_lists.import.how.linkedin.step1,
      icon: <IconLinkedin {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.linkedin.step2,
      icon: <IconUserScan {...icon_props} />,
      noMarginTop: true,
    },
    {
      label: lang.contact_lists.import.how.linkedin.step3,
      icon: <IconUserSearch {...icon_props} />,
      noMarginTop: true,
    },
    {
      label: lang.contact_lists.import.how.linkedin.step4,
      icon: <IconPhonePlus {...icon_props} />,
      noMarginTop: true,
    },
    {
      label: lang.contact_lists.import.how.linkedin.step5,
      icon: <IconMailSearch {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.linkedin.step6,
      icon: <IconMailCheck {...icon_props} />,
    },
  ];
}

function getGoogleTexts() {
  const lang = useLanguage.getState().language;
  return [
    {
      label: lang.contact_lists.import.how.google.step1,
      icon: <IconGmaps {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.google.step2,
      icon: <IconUserSearch {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.google.step3,
      icon: <IconMailCheck {...icon_props} />,
    },
  ];
}

function getMagileadsTexts() {
  const lang = useLanguage.getState().language;
  return [
    {
      label: lang.contact_lists.import.how.database.step1,
      icon: <IconUserSearch {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.database.step2,
      icon: <IconDeviceFloppy {...icon_props} />,
    },
  ];
}

function getManualTexts() {
  const lang = useLanguage.getState().language;
  return [
    {
      label: lang.contact_lists.import.how.manual.step1,
      icon: <IconFileImport {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.manual.step2,
      icon: <IconColumns {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.manual.step3,
      icon: <IconCheck {...icon_props} />,
    },
  ];
}

function getTexts(type) {
  if (type === "linkedin") {
    return getLinkedinTexts();
  }
  if (type === "google") {
    return getGoogleTexts();
  }
  if (type === "magileads") {
    return getMagileadsTexts();
  }
  if (type === "file") {
    return getManualTexts();
  }
  return [];
}

const HowItWorks = ({ type, inside }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  return (
    <>
      {inside ? (
        <Tooltip label={lang.contact_lists.import.how.title}>
          <Button
            variant="light"
            onClick={open}
            h={36}
            w={36}
            px={0}
            radius={"sm"}
            color="secondary"
          >
            <IconHelp size={24} />
          </Button>
        </Tooltip>
      ) : (
        <>
          <Tooltip label={lang.contact_lists.import.how.title}>
            <Button
              variant="light"
              onClick={open}
              h={26}
              w={26}
              p={0}
              radius={"md"}
              pos={"absolute"}
              top={4}
              right={4}
              size="sm"
              color="secondary"
            >
              <IconHelp size={20} />
            </Button>
          </Tooltip>

          <ActionIcon.Group>
            {type !== "google" && (
              <Tooltip
                label={
                  lang.contact_lists.import.methods.will_import +
                  lang.contact_lists.import.methods.linkedin
                }
              >
                <ActionIcon variant="subtle">
                  <IconLinkedin size={14} />
                </ActionIcon>
              </Tooltip>
            )}

            {type !== "companies" && (
              <Tooltip
                label={
                  lang.contact_lists.import.methods.will_import +
                  lang.contact_lists.import.methods.email
                }
              >
                <ActionIcon variant="subtle" color="secondary">
                  <IconMail size={14} />
                </ActionIcon>
              </Tooltip>
            )}

            <Tooltip
              label={
                lang.contact_lists.import.methods.will_import +
                lang.contact_lists.import.methods.phone
              }
            >
              <ActionIcon variant="subtle">
                <IconPhone size={14} />
              </ActionIcon>
            </Tooltip>
          </ActionIcon.Group>
        </>
      )}

      <Modal
        opened={opened}
        onClose={close}
        title={lang.contact_lists.import.how.title}
        size={"40%"}
      >
        {type === "magileads" && (
          <Text fw={500} mb={"md"}>
            {lang.contact_lists.import.how.database.text}
          </Text>
        )}
        <Timeline
          bulletSize={32}
          lineWidth={2}
          color="primary"
          active={0}
          reverseActive
        >
          {getTexts(type)?.map((step, index) => (
            <Timeline.Item
              key={index}
              bullet={step.icon}
              title={step.label}
              styles={{
                itemTitle: {
                  fontWeight: 400,
                  marginTop: step?.noMarginTop ? 0 : 8,
                  lineHeight: 1.25
                },
                item: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                },
              }}
            >
              <Box h={8} />
            </Timeline.Item>
          ))}
        </Timeline>

        <Group justify="end" mt={"sm"}>
          <BackButton onClick={close} />
        </Group>
      </Modal>
    </>
  );
};

export default HowItWorks;
