import { useLanguage } from "../../../stores/LanguageStore";
import { Group, Paper, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconCalendarClock } from "@tabler/icons-react";
import { formatAnswer } from "../../protected/leadsTracking/utils";
import { parseDate } from "../../../functions/dates";

const ReplyItem = ({ item, type }) => {
  const lang = useLanguage((s) => s.language);

  const date = new Date(item.date).toLocaleString(
    lang.id === "FRA" ? "fr-FR" : "en-EN",
    {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );

  return type === "email" ? (
    <Paper
      component={Stack}
      withBorder
      radius={"md"}
      px={"xs"}
      pos={"relative"}
      gap={0}
      h={"100%"}
    >
      <Group
        gap={"xs"}
        style={{ borderBottom: "1px solid #00000016" }}
        pt={4}
        pb={4}
      >
        <ThemeIcon color={"gray"} variant="subtle">
          <IconCalendarClock size={18} />
        </ThemeIcon>
        <Text c={"gray"} size="sm">
          {date.charAt(0).toUpperCase() + date.slice(1)}
        </Text>
      </Group>

      <iframe
        title={item.date}
        id="iFrame1"
        srcDoc={formatAnswer(item?.reply || item?.message)}
        style={{
          width: "100%",
          border: "none",
          borderLeft: "none",
          height: "100%",
          minHeight: 320,
        }}
        // sandbox=""
      />
    </Paper>
  ) : (
    <Stack gap={6} className="hover-source">
      <Group
        wrap="nowrap"
        ml={item?.reply ? 0 : "30%"}
        mr={item?.reply ? "30%" : 0}
      >
        <Paper
          withBorder
          bg={
            item?.message
              ? "var(--mantine-primary-color-1)"
              : "var(--mantine-color-gray-1)"
          }
          radius={"md"}
          p={"sm"}
          py={"xs"}
          w={"fit-content"}
        >
          {item?.reply || item?.message}
        </Paper>
      </Group>
      <Text
        ta={item?.reply ? "left" : "right"}
        size="sm"
        c={"dimmed"}
        w={"100%"}
      >
        {parseDate(item.date)}
      </Text>
    </Stack>
  );
};

export default ReplyItem;
