import { NumberFormatter, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";

const Price = ({ defaultPrice, futurePrice, reccurence, active }) => {
  const lang = useLanguage((s) => s.language);

  if (futurePrice === defaultPrice || !active)
    return (
      <Stack gap={0}>
        {active && <Text size="sm">{lang.pricing.actual}</Text>}
        <Text size="lg">
          <b style={{ fontSize: 32, marginRight: 8 }}>
            <NumberFormatter value={futurePrice?.toFixed(0)} />€
          </b>{" "}
          {reccurence === "monthly"
            ? lang.pricing.monthly_payment
            : lang.pricing.annual_payment}
        </Text>
      </Stack>
    );
  return (
    <Stack gap={"sm"}>
      <Stack gap={0}>
        <Text size="sm">{lang.pricing.actual}</Text>
        <Text size="sm">
          <b style={{ fontSize: 18, marginRight: 8 }}>
            <NumberFormatter value={defaultPrice?.toFixed(0)} />€
          </b>{" "}
          {reccurence === "monthly"
            ? lang.pricing.monthly_payment
            : lang.pricing.annual_payment}
        </Text>
      </Stack>
      <Stack gap={0}>
        <Text size="sm"> {lang.pricing.additional_options} </Text>
        <Text size="lg" mt={-5}>
          <b style={{ fontSize: 32, marginRight: 8 }}>
            <NumberFormatter value={(futurePrice - defaultPrice)?.toFixed(0)} />
            €
          </b>{" "}
          {reccurence === "monthly"
            ? lang.pricing.monthly_payment
            : lang.pricing.annual_payment}
        </Text>
      </Stack>
    </Stack>
  );
};

export default Price;
