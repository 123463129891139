import { useMemo, useState } from "react";
import { Box, Group, Tooltip } from "@mantine/core";
import { useAccountsStore } from "../../stores/ConfigStore";
import { useLanguage } from "../../stores/LanguageStore";
import { getEmails, getPools } from "../../api/EmailAPI";
import { IconCheck, IconMailForward, IconX } from "@tabler/icons-react";
import Selector from "./Selector";
import { getTypeIcon } from "../../pages/protected/workflows/CreateCampaign/flow/flowSettings";

function getOptionName(option, isPool) {
  if (isPool)
    return `POOL: ${option.name} #${option.id} (${option.number_of_accounts})`;
  return `${option.name} #${option.id} (${option.email})`;
}

function getColor(item) {
  if (item?.account_type === "MassMailing") {
    return "#00aa00";
  }
  if (item?.account_type === "SMTP") {
    return "#000";
  }
  if (item?.type) {
    return "#0000aa";
  }

  // Pool
  return undefined;
}

const EmailAccountSelector = ({
  id,
  label,
  placeholder,
  size,
  required,
  value,
  onChange,
  description,
  disabled,
  smtpOnly,
  onClick,
  w = "100%",
}) => {
  const lang = useLanguage((s) => s.language);
  const accounts = useAccountsStore((s) => s.email_accounts);
  const email_pools = useAccountsStore((s) => s.email_pools);
  const [loading, setLoading] = useState(false);
  const handleLoad = (e) => {
    setLoading(true);
    onClick?.();
    getEmails().then(() => {
      getPools().finally(() => setLoading(false));
    });
  };

  const options = useMemo(() => {
    let result = [
      { label: "Gmail", options: [] },
      { label: "SMTP", options: [] },
      { label: "Outlook", options: [] },
      { label: "MassMailing", options: [] },
      { label: "Pools", options: [] },
    ];
    accounts?.forEach((account) => {
      let place = result.find((x) => x.label === account.account_type);
      if (place)
        place.options.push({
          ...account,
          option_name: getOptionName(account),
        });
    });
    email_pools?.forEach((pool) => {
      let place = result.find((x) => x.label === "Pools");
      place.options.push({
        ...pool,
        option_name: getOptionName(pool, true),
      });
    });

    if (smtpOnly) {
      return [
        {
          label: "SMTP",
          options: accounts
            .filter((x) => x.account_type === "SMTP")
            .map((x) => ({ ...x, option_name: getOptionName(x) })),
        },
      ];
    }

    return [...accounts, ...email_pools].length > 0 ? result : [];
  }, [accounts, email_pools, smtpOnly]);

  return (
    <Group wrap="nowrap" align="flex-start" w={w}>
      <Selector
        disabled={disabled}
        getError={
          accounts.find((x) => x.id === value)?.is_valid === false && !disabled
            ? lang.emails_account.columns.not_valid
            : required
            ? !value
            : null
        }
        id={id}
        label={label}
        leftSection={
          value ? (
            getTypeIcon(
              email_pools.find((x) => x.id === value)
                ? "Pool"
                : accounts.find((x) => x.id === value)?.account_type,
              false,
              14,
              {
                variant: "subtle",
                color: getColor(accounts.find((x) => x.id === value)),
              }
            )
          ) : (
            <Tooltip label={lang.campaign.selectors.senders.tooltip}>
              <IconMailForward size={16} strokeWidth={1} />
            </Tooltip>
          )
        }
        loading={loading}
        onChange={onChange}
        onDropdownOpen={handleLoad}
        options={options}
        placeholder={
          placeholder ||
          lang.campaign.selectors.senders.placeholder + (required ? " *" : "")
        }
        required={required}
        rightOptionSection={(item) => {
          let valid =
            item?.is_valid || !!item?.accounts_list?.find((x) => x.is_valid);
          return valid ? (
            <Box w={20} ml={"auto"}>
              <Tooltip
                label={lang.campaign.selectors.senders.verified}
                withArrow
              >
                <IconCheck size={16} color="tertiary.8" />
              </Tooltip>
            </Box>
          ) : (
            <Box w={20} ml={"auto"}>
              <Tooltip
                label={lang.campaign.selectors.senders.not_verified}
                withArrow
              >
                <IconX size={16} color="red" />
              </Tooltip>
            </Box>
          );
        }}
        getValidOption={(item) => {
          let valid =
            item?.is_valid || !!item?.accounts_list?.find((x) => x.is_valid);

          return valid;
        }}
        size={size}
        value={value}
        getOptionName={(v) => getOptionName(v, !!v?.accounts_list)}
        hasGroup
        description={description}
      />
    </Group>
  );
};

export default EmailAccountSelector;
