import {
  Button,
  Checkbox,
  Divider,
  Group,
  NumberInput,
  PasswordInput,
  Stack,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { createSMTP, getEmails } from "../../../../../api/EmailAPI";
import { defaultSMTPConfigs } from "./defaultConfigs";
import { notifications } from "@mantine/notifications";

const CreateSMTP = ({
  setIsAdvanced,
  configs,
  onCreated,
  handleClose,
  props,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [imapRequired, setImapRequired] = useState(true);
  const [smtpError, setSmtpError] = useState(false);
  const [imapError, setImapError] = useState(false);

  const handleErrors = (message) => {
    setLoading(false);
    if (message.includes("smtp")) {
      setSmtpError(true);
    }
    if (message.includes("imap")) {
      setImapError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    setSmtpError(false);
    setSmtpError(false);
    data.name = data?.sender;
    data.smtp_port = parseInt(data?.smtp_port);
    data.smtp_secure = data?.smtp_secure ? "ssl" : "tls";
    data.imap_host = imapRequired ? data?.imap_host : undefined;
    data.imap_password = imapRequired ? data?.imap_password : undefined;
    data.imap_port = imapRequired ? parseInt(data?.imap_port) : undefined;
    data.imap_username = imapRequired ? data?.imap_username : undefined;
    data.imap_secure = data?.imap_secure
      ? "ssl"
      : !imapRequired
      ? undefined
      : "tls";

    createSMTP({ ...defaultSMTPConfigs, ...data })
      .then((response) => {
        getEmails()
          .then((res) => {
            let newItemsList = res.data.email_accounts_list;
            let newItem = newItemsList.find(
              (x) => x.id === response.data.email_account_id
            );
            console.log(newItem);
            notifications.show({
              title: "Email expéditeur ajouté",
              message: "Votre email a bien été ajouté",
              color: "tertiary.8",
            });
            onCreated(newItem);
            handleClose();
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => handleErrors(err.response.data.state_message));
  };

  return (
    <Stack w={480} gap={0} {...props}>
      <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
        <Group wrap="nowrap">
          <TextInput
            defaultValue={configs?.sender}
            placeholder={"John Doe"}
            name="sender"
            label={lang.emails_account.smtp.step_base.sender}
            type="text"
            w={"100%"}
            required
          />
          <TextInput
            defaultValue={configs?.email}
            placeholder={"email@example.com"}
            name="email"
            label={lang.emails_account.smtp.step_base.email}
            type="email"
            w={"100%"}
            required
          />
        </Group>

        <Divider my={12} />

        <Text size="md">{lang.emails_account.smtp.step_base.smtp_title}</Text>
        {smtpError && (
          <Text c={"red"} size="sm" mt={-6} fw={500}>
            {lang.emails_account.smtp.step_base.error_auth}
          </Text>
        )}
        <Group wrap="nowrap">
          <TextInput
            defaultValue={configs?.smtp_username}
            placeholder={lang.emails_account.smtp.step_base.smtp_username}
            name="smtp_username"
            label={lang.emails_account.smtp.step_base.smtp_username}
            required
            type="text"
            w={"100%"}
          />
          <TextInput
            defaultValue={configs?.smtp_host}
            placeholder={lang.emails_account.smtp.step_base.smtp_host}
            name="smtp_host"
            label={lang.emails_account.smtp.step_base.smtp_host}
            type="text"
            w={"100%"}
            required
          />
        </Group>
        <Group wrap="nowrap">
          <NumberInput
            defaultValue={configs?.smtp_port}
            placeholder={"465"}
            name="smtp_port"
            label={lang.emails_account.smtp.step_base.smtp_port}
            required
            w={"100%"}
          />
          <PasswordInput
            defaultValue={configs?.smtp_password}
            placeholder={lang.emails_account.smtp.step_base.smtp_password}
            name="smtp_password"
            label={lang.emails_account.smtp.step_base.smtp_password}
            w={"100%"}
            required
            autoComplete="new-password"
          />
        </Group>
        <Checkbox
          label={lang.emails_account.smtp.step_base.smtp_ssl}
          defaultChecked={configs?.smtp_secure}
          name="smtp_secure"
          mt={4}
        />

        <Divider my={12} />

        <Group justify="space-between">
          <Text size="md">{lang.emails_account.smtp.step_base.imap_title}</Text>
          <Switch
            label={lang.emails_account.smtp.step_base.ignore}
            checked={!imapRequired}
            onChange={(e) => setImapRequired(!e.target.checked)}
          />
        </Group>
        {imapError && (
          <Text c={"red"} size="sm" mt={-6} fw={500}>
            {lang.emails_account.smtp.step_base.error_auth_imap}
          </Text>
        )}
        <Group wrap="nowrap">
          <TextInput
            defaultValue={configs?.imap_username}
            placeholder={lang.emails_account.smtp.step_base.imap_username}
            name="imap_username"
            label={lang.emails_account.smtp.step_base.imap_username}
            required={imapRequired}
            type="text"
            w={"100%"}
          />
          <TextInput
            defaultValue={configs?.imap_host}
            placeholder={lang.emails_account.smtp.step_base.imap_host}
            name="imap_host"
            label={lang.emails_account.smtp.step_base.imap_host}
            type="email"
            w={"100%"}
            required={imapRequired}
          />
        </Group>
        <Group wrap="nowrap">
          <NumberInput
            defaultValue={configs?.imap_port}
            placeholder={"993"}
            name="imap_port"
            label={lang.emails_account.smtp.step_base.imap_port}
            required={imapRequired}
            w={"100%"}
          />
          <PasswordInput
            defaultValue={configs?.imap_password}
            placeholder={lang.emails_account.smtp.step_base.imap_password}
            name="imap_password"
            label={lang.emails_account.smtp.step_base.imap_password}
            w={"100%"}
            required={imapRequired}
            autoComplete="new-password"
          />
        </Group>
        <Checkbox
          label={lang.emails_account.smtp.step_base.imap_ssl}
          defaultChecked={configs?.imap_secure}
          name="imap_secure"
          mt={4}
        />

        <Group mt={"lg"} justify="space-between">
          <Button
            variant="subtle"
            leftSection={<IconArrowLeft />}
            onClick={() => setIsAdvanced(false)}
          >
            {lang.home.create_campaign.step2.simple}
          </Button>
          <Button size="md" type="submit" loading={loading}>
            {lang.home.create_campaign.step2.connect}
          </Button>
        </Group>
      </Stack>
    </Stack>
  );
};

export default CreateSMTP;
