import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Stack,
  Stepper,
  Text,
} from "@mantine/core";
import {
  IconChevronLeft,
  IconChevronRight,
  IconDeviceFloppy,
  IconRefreshDot,
  IconSend,
} from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Flow from "./flow/Flow";
import Contacts from "./contacts/Contacts";
import FlowPreview from "./flow/FlowPreview";
import { useNavigate, useParams } from "react-router-dom";
import { modals } from "@mantine/modals";
import { useFlowStore } from "../../../../stores/WorkflowStore";
import CampaignExclusion from "./exclusion/CampaignExclusion";
import {
  createWorkflow,
  editWorkflow,
  programWorkflow,
} from "../../../../api/WorkflowAPI";
import { defaultNodeConfigs, defaultProgramData } from "./flow/flowSettings";
import { incrementHour } from "../../../../functions/dates";
import { notifications } from "@mantine/notifications";
import { getLists } from "../../../../api/ContactAPI";
import { useListStore } from "../../../../stores/ListStore";
import NodeErrors from "./flow/components/NodeErrors";
import { editCampaign, findCampaign } from "../../../../api/StatsAPI";
import { useLanguage } from "../../../../stores/LanguageStore";
import Review from "./review/Review";
import { getNodesError } from "./flow/layouter";
import { useProfileStore } from "../../../../stores/UserStore";
import { getObjectWithChangedProperties } from "../../../../functions/utilFunctions";
import dayjs from "dayjs";
import { handleWorkflowGet } from "./flow/flowUtils";
import BackButton from "../../../../components/View/BackButton";
import {
  createSharing,
  editSharing,
  getSharings,
} from "../../../../api/PRMAPI";
import { useLeadsStore } from "../../../../stores/LeadsStore";
import RequiredActions from "../../account/RequiredActions/RequiredActions";
import LinkingNotification from "./flow/components/LinkingNotification";
import { getSignatures } from "../../../../api/ConfigsApi";

const CreateCampaign = () => {
  const lang = useLanguage((s) => s.language);
  const { campaignId } = useParams();
  const param = new URLSearchParams(window.location.search);
  const list = param.get("list");
  const [active, setActive] = useState(0);
  const [highestStepVisited, setHighestStepVisited] = useState(active);
  const loadingPage = useFlowStore((s) => s.loadingPage);
  const setLoadingPage = useFlowStore((s) => s.setLoadingPage);
  const [shouldWarn, setShouldWarn] = useState(true);

  const navigate = useNavigate();

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [campaignData, setCampaignData] = useState(null);
  const [programData, setProgramData] = useState({
    ...defaultProgramData,
    contactlist_ids: list ? [parseInt(list)] : [],
  });

  const [isReprog, setIsReprog] = useState(false);
  const isPast = useMemo(() => {
    if (!campaignData) {
      return false;
    }
    return isReprog ? false : dayjs(campaignData?.date_start).diff(dayjs());
  }, [campaignData, isReprog]);

  const [toStepAfterWorkflow, setToStepAfterWorkflow] = useState(1);
  const handleWorkflow = useCallback(
    async (e, isSave) => {
      e?.preventDefault?.();
      const nodes = useFlowStore
        .getState()
        .nodes.filter((x) => x.type === "action");

      const fd = new FormData(e.target);
      const data = Object.fromEntries(fd);
      const wProfile = useFlowStore.getState().profile;

      const nodesErrors = getNodesError({
        nodes,
        exclude_responders: !!data?.auto_remove_responders,
      })?.filter((x) => x.errors.length > 0);

      async function main() {
        setLoadingPage(true);
        let timezoneOffset = new Date().getTimezoneOffset() / 60;

        const steps = nodes.map((step, index) =>
          step?.data?.actionType !== "event"
            ? {
                step_id: {
                  type: step?.data?.exist ? "existing" : "new",
                  id: parseInt(step.id),
                },
                step_type: "action",
                position_x: step.x,
                position_y: step.y,
                parent_ids:
                  step?.data?.source === "0"
                    ? []
                    : Array.isArray(step?.data?.source)
                    ? step?.data?.source.map((s) => ({
                        type: nodes.find((x) => x.id === s)?.data?.exist
                          ? "existing"
                          : "new",
                        id: parseInt(s),
                      }))
                    : [
                        {
                          type: nodes.find((x) => x.id === step.data.source)
                            ?.data?.exist
                            ? "existing"
                            : "new",
                          id: parseInt(step.data.source),
                        },
                      ],
                action_type: step.data.actionType,
                model_id: step.data?.model_id,
                integration_id: step.data?.integration_id,
                pool_id: step.data?.pool_id,
                is_initial: step.data?.source === "0",
                signature_id: step.data?.signature_id,
                limit_send_per_day:
                  step.data?.limit_send_per_day ||
                  defaultNodeConfigs?.limit_send_per_day,
                sending_weekends_allowed:
                  step.data?.sending_weekends_allowed ||
                  defaultNodeConfigs?.sending_weekends_allowed,
                time_start_sending: incrementHour(
                  step.data?.time_start_sending ||
                    defaultNodeConfigs?.time_start_sending,
                  timezoneOffset
                ),
                time_stop_sending: incrementHour(
                  step.data?.time_stop_sending ||
                    defaultNodeConfigs?.time_stop_sending,
                  timezoneOffset
                ),
                blacklist_ids:
                  step.data?.blacklist_ids || defaultNodeConfigs?.blacklist_ids,
                email_linkedin_exclude:
                  step.data?.email_linkedin_exclude ||
                  defaultNodeConfigs?.email_linkedin_exclude,
                show_reply_as_conversation:
                  step.data?.show_reply_as_conversation !== null
                    ? step.data?.show_reply_as_conversation
                    : defaultNodeConfigs?.show_reply_as_conversation,
                allow_massmailing:
                  step.data?.show_reply_as_conversation ||
                  defaultNodeConfigs?.allow_massmailing,
                use_smtp_fallback_server:
                  step.data?.show_reply_as_conversation ||
                  defaultNodeConfigs?.use_smtp_fallback_server,
              }
            : {
                step_id: {
                  type: step?.data?.exist ? "existing" : "new",
                  id: parseInt(step.id),
                },
                step_type: "event",
                event_type: step.data?.event_type,
                position_x: step.x,
                position_y: step.y,
                filter: step.data?.filter,
                which_links_clicked: step.data?.which_links_clicked,
                when_minutes:
                  step.data?.when_minutes || defaultNodeConfigs?.when_minutes,
                name: step.data?.name,
                parent_ids: [
                  {
                    type: nodes.find((x) => x.id === step.data.source)?.data
                      ?.exist
                      ? "existing"
                      : "new",
                    id: parseInt(step.data.source),
                  },
                ],
              }
        );
        setShouldWarn(false);

        if (!wProfile?.id) {
          return createWorkflow({
            steps: steps,
            auto_remove_responders: !!data?.auto_remove_responders,
            name: data?.name,
          })
            .then((res) => {
              const setProfile = useFlowStore.getState().setProfile;
              setProfile({
                id: res.data.workflow_id,
                auto_remove_responders: !!data?.auto_remove_responders,
                name: data?.name,
              });

              setLoadingPage(true);

              notifications.show({
                message: lang.campaign.saved,
                color: "var(--mantine-color-green-8)",
              });

              if (!isSave) {
                getLists()
                  .then(() => {
                    setActive(toStepAfterWorkflow);
                    setHighestStepVisited((hSC) =>
                      Math.max(hSC, toStepAfterWorkflow)
                    );
                  })
                  .finally(() => setLoadingPage(false));
              } else {
                setLoadingPage(false);
              }

              document.getElementById("workflow-name").value = data?.name;
            })
            .catch(() => setLoadingPage(false));
        } else {
          return editWorkflow({
            id: wProfile?.id,
            steps: steps,
            auto_remove_responders: !!data?.auto_remove_responders,
            name: data?.name,
          })
            .then((res) => {
              setLoadingPage(true);

              notifications.show({
                message: lang.campaign.saved,
                color: "tertiary.7",
              });

              const setProfile = useFlowStore.getState().setProfile;
              setProfile({
                id: wProfile?.id,
                auto_remove_responders: !!data?.auto_remove_responders,
                name: data?.name,
              });

              if (!isSave) {
                getLists()
                  .then(() => {
                    setActive(toStepAfterWorkflow);
                    setHighestStepVisited((hSC) =>
                      Math.max(hSC, toStepAfterWorkflow)
                    );
                  })
                  .finally(() => setLoadingPage(false));
              } else {
                setLoadingPage(false);
              }
            })
            .catch(() => setLoadingPage(false));
        }
      }

      if (nodesErrors?.length > 0) {
        return modals.open({
          title: lang.campaign.nodes.errors.dialog_title,
          children: (
            <NodeErrors
              errors={nodesErrors}
              handleContinue={main}
              reactFlowInstance={reactFlowInstance}
            />
          ),
          size: "45%",
          radius: "lg",
          styles: {
            content: {
              boxShadow: "red 0 0 12px",
              border: "1px solid red",
            },
          },
          centered: true,
        });
      }

      return main();
    },
    [
      setLoadingPage,
      toStepAfterWorkflow,
      lang.campaign.nodes.errors.dialog_title,
      reactFlowInstance,
      lang.campaign.saved,
    ]
  );

  const handleProgramConfigs = useCallback(
    (e) => {
      e.preventDefault();
      let fd = new FormData(document.getElementById("program-form"));
      const data = Object.fromEntries(fd);
      data.allowed_monday = data.allowed_monday ? true : false;
      data.allowed_tuesday = data.allowed_tuesday ? true : false;
      data.allowed_wednesday = data.allowed_wednesday ? true : false;
      data.allowed_thursday = data.allowed_thursday ? true : false;
      data.allowed_friday = data.allowed_friday ? true : false;
      data.allowed_saturday = data.allowed_saturday ? true : false;
      data.allowed_sunday = data.allowed_sunday ? true : false;
      data.priority_email = data.priority_email ? true : false;
      data.stop_steps_at_end_date = data.stop_steps_at_end_date ? true : false;
      data.daily_send_limit = parseInt(data?.daily_send_limit || "0");
      data.limit_total_sending = parseInt(data.limit_total_sending);
      data.ab_proportion = parseInt(data?.ab_proportion) || undefined;
      data.date_stop = data?.date_stop ? data.date_stop : null;

      setProgramData({ ...programData, ...data });

      setActive(4);
      setHighestStepVisited((hSC) => Math.max(hSC, 4));
    },
    [programData]
  );

  const handleProgram = useCallback(() => {
    const workflowProfile = useFlowStore.getState().profile;
    const sharing = useFlowStore.getState().sharing;
    const statsShare = useFlowStore.getState().shareStats;

    let prog_sharing =
      sharing[0]?.ids_list.length > 0 && statsShare.read_permission
        ? [
            {
              ...sharing[0],
              name: workflowProfile.name,
              read_permission: true,
              write_permission: false,
              delete_permission: false,
            },
          ]
        : [];

    let timezoneOffset = new Date().getTimezoneOffset() / 60;
    let finalData = { ...programData };
    finalData.time_start_sending = incrementHour(
      finalData.time_start_sending,
      timezoneOffset
    );
    finalData.time_stop_sending = incrementHour(
      finalData.time_stop_sending,
      timezoneOffset
    );
    finalData.stats_sharings = prog_sharing;

    let oldData = { ...campaignData };

    if (campaignData) {
      oldData.time_start_sending = incrementHour(
        oldData.time_start_sending,
        timezoneOffset
      );
      oldData.time_stop_sending = incrementHour(
        oldData.time_stop_sending,
        timezoneOffset
      );
    }

    const diff = getObjectWithChangedProperties(oldData, finalData);

    if (isPast) {
      delete diff.date_start;
      delete diff.isPast;
    }

    function program() {
      setLoadingPage(true);
      const main = campaignId && !isReprog ? editCampaign : programWorkflow;
      main({
        workflow_id: workflowProfile?.id,
        data: campaignId && !isReprog ? diff : finalData,
        programmation_id: parseInt(campaignId),
      })
        .then((response) => {
          let newProgramId =
            response?.data?.workflow_programmation_id || parseInt(campaignId);

          notifications.show({
            message:
              campaignId && !isReprog
                ? lang.campaign.edited
                : lang.campaign.programmed,
            color: "var(--mantine-color-green-8)",
          });

          const setWorkflowProfile = useFlowStore.getState().setProfile;
          const setNodes = useFlowStore.getState().setNodes;
          const setEdges = useFlowStore.getState().setEdges;
          setWorkflowProfile({
            id: null,
            name: "",
            tags: [],
            folder: null,
            sharing: [],
            auto_remove_responders: true,
          });
          setNodes([]);
          setEdges([]);
          setProgramData(defaultProgramData);

          // Handle Sharing PRM
          const prmShare = useFlowStore.getState().sharePrm;
          if (prmShare.read_permission && sharing[0]?.ids_list.length > 0) {
            let found = useLeadsStore
              .getState()
              .sharings.find((x) => x.name === workflowProfile?.name);

            const mainSharer = found ? editSharing : createSharing;
            mainSharer({
              id: found?.id,
              ...sharing[0],
              name: workflowProfile?.name,
              read_permission: true,
              delete_permission: prmShare.delete_permission,
              write_permission: prmShare.write_permission,
              filter: {
                mode: "and",
                values: [
                  {
                    field_name: "programmation_id",
                    type: "equals",
                    value: newProgramId.toString(),
                  },
                ],
              },
            })
              .then((res) => {
                notifications.show({ message: lang.prm.sharing.shared });
                navigate("/statistics/campaigns?highlight=" + newProgramId);
              })
              .finally(() => {
                setLoadingPage(false);
              });
          } else {
            navigate("/statistics/campaigns?highlight=" + newProgramId);
          }
        })
        .catch(() => setLoadingPage(false));
    }

    const lists = useListStore.getState().lists;
    let listHasJob = programData.contactlist_ids.some((id) => {
      let list = lists.find((x) => x.id === id);

      if (list?.state === "InProgress" || list?.state === "InQueue") {
        return true;
      }
      return false;
    });

    if (listHasJob) {
      return modals.openConfirmModal({
        title: lang.campaign.alert_finish.title,
        children: <Text size="sm">{lang.campaign.alert_finish.text}</Text>,
        centered: true,
        labels: { confirm: "Programmer", cancel: lang.global.cancel },
        onConfirm: () => {
          program();
        },
      });
    }

    console.log({ diff });

    if (Object.keys(diff).length === 0) {
      return modals.open({
        title: lang.campaign.no_edition.title,
        children: (
          <Stack>
            <Text size="sm">{lang.campaign.no_edition.text}</Text>
            <Text size="sm">{lang.campaign.no_edition.text2}</Text>
            <Group justify="end" mt={"md"}>
              <BackButton onClick={() => modals.closeAll()} />

              <Button
                onClick={() => {
                  navigate("/statistics/campaigns?highlight=" + campaignId);
                  modals.closeAll();
                }}
              >
                {lang.campaign.no_edition.to_campaign}
              </Button>
            </Group>
          </Stack>
        ),
        centered: true,
      });
    }

    return program();
  }, [
    campaignData,
    campaignId,
    isPast,
    isReprog,
    lang,
    navigate,
    programData,
    setLoadingPage,
  ]);

  useEffect(() => {
    getSignatures();
    return () => {
      const setWorkflowProfile = useFlowStore.getState().setProfile;
      const setNodes = useFlowStore.getState().setNodes;
      const setEdges = useFlowStore.getState().setEdges;
      const setSharing = useFlowStore.getState().setSharing;
      const setShareStats = useFlowStore.getState().setShareStats;
      const setSharePrm = useFlowStore.getState().setSharePrm;
      const setShareWorkflow = useFlowStore.getState().setShareWorkflow;
      setWorkflowProfile({
        id: null,
        name: "",
        tags: [],
        folder: null,
        sharing: [],
        auto_remove_responders: true,
      });
      setNodes([]);
      setEdges([]);
      setSharing([
        {
          sharing_type_to: "User",
          ids_list: [],
          sharing_targets: [],
        },
      ]);
      setShareStats({
        id: "stats",
        read_permission: true,
      });
      setSharePrm({
        id: "prm",
        read_permission: true,
        write_permission: false,
        delete_permission: false,
      });
      setShareWorkflow({
        id: "workflow",
        read_permission: true,
        write_permission: false,
        delete_permission: false,
      });
    };
  }, []);

  const handleStepChange = (nextStep) => {
    if (shouldWarn && nextStep !== 1 && active === 0) {
      return modals.openConfirmModal({
        title: lang.campaign.warning,
        children: <Text size="sm">{lang.campaign.save_warning}</Text>,
        labels: { confirm: lang.campaign.save, cancel: lang.global.ignore },
        onCancel: () => {
          setActive(nextStep);
          setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
        },
        onConfirm: () => {
          setToStepAfterWorkflow(nextStep);
          setTimeout(() => {
            document.getElementById("workflow-config-submitter").click();
          }, 50);
        },
        centered: true,
      });
    }

    // Next on step Workflow
    if (active === 0 && nextStep === 1) {
      setToStepAfterWorkflow(1);
      return document.getElementById("workflow-config-submitter").click();
    }
    if (active === 3 && nextStep === 4) {
      return document.getElementById("programmation-config-submitter").click();
    }
    // Previous to step Workflow
    if (nextStep === 0) {
      setShouldWarn(false);
      if (shouldWarn) {
        return handleWorkflowGet({
          id: useFlowStore.getState().profile.id,
          onFinish: () => {
            setActive(0);
            setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
          },
          isEdit: true,
        });
      }
    }
    setActive(nextStep);
    setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
  };

  // Allow the user to freely go back and forth between visited steps.
  const shouldAllowSelectStep = (step) =>
    highestStepVisited >= step && active !== step;

  const handleActive = useCallback(() => {
    if (active === 0) {
      return (
        <Flow
          handleSubmit={handleWorkflow}
          reactFlowInstance={reactFlowInstance}
          setReactFlowInstance={setReactFlowInstance}
          setShouldWarn={setShouldWarn}
        />
      );
    }
    if (active === 1) {
      return (
        <Contacts
          programData={programData}
          setProgramData={setProgramData}
          isPast={isPast}
        />
      );
    }
    if (active === 2) {
      return (
        <Review programData={programData} setProgramData={setProgramData} />
      );
    }
    if (active === 3) {
      return (
        <FlowPreview
          programData={programData}
          handleProgramConfigs={handleProgramConfigs}
          isPast={isPast}
          isReprog={isReprog}
        />
      );
    }
    if (active === 4) {
      return (
        <CampaignExclusion
          programData={programData}
          setProgramData={setProgramData}
        />
      );
    }
  }, [
    active,
    handleProgramConfigs,
    handleWorkflow,
    isPast,
    isReprog,
    programData,
    reactFlowInstance,
  ]);

  const getNextDisabled = () => {
    if (active === 1 && programData?.contactlist_ids?.length === 0) {
      return true;
    }
    return false;
  };

  const getDoneDisabled = () => {
    return false;
  };

  useEffect(() => {
    const param = new URLSearchParams(window.location.search);
    const workflow_id = param.get("workflow");
    const reprog = param.get("reprog");
    const defaultList = param.get("list");
    const libraryId = param.get("libraryId");
    const isQuick = param.get("quick");

    if (libraryId) {
      handleWorkflowGet({
        libraryId: libraryId,
        isEdit: false,
        onFinish: ({ newNodes }) => {
          setTimeout(() => {
            document.getElementById("node_head_" + newNodes[0].id)?.click();
          }, 500);
        },
      });
    }
    if (isQuick) {
      setLoadingPage(true);
      setActive(2);
      setHighestStepVisited(2);
      setProgramData({
        ...defaultProgramData,
        contactlist_ids: defaultList ? [parseInt(defaultList)] : [],
      });

      return handleWorkflowGet({
        id: parseInt(workflow_id),
        isEdit: true,
        onFinish: ({ newNodes }) => {
          setLoadingPage(false);
        },
      });
    }
    if (workflow_id) {
      setTimeout(() => {
        handleWorkflowGet({
          id: parseInt(workflow_id),
          isEdit: true,
          onFinish: ({ newNodes }) => {
            document.getElementById("node_head_" + newNodes[0].id)?.click();
            setLoadingPage(false);

            if (campaignId) {
              setActive(3);
            }
          },
        });
      }, 50);
    }
    if (reprog) {
      setIsReprog(true);
      setActive(1);
      setHighestStepVisited(1);
    }
    if (campaignId) {
      setHighestStepVisited(4);
      getSharings();
      findCampaign(workflow_id, campaignId).then((response) => {
        let config = response.data.workflow_programmation_profile;

        setCampaignData(config);
        useFlowStore.getState().setSharing(config?.stats_sharings || []);
        setProgramData(config);
      });
    } else {
      let p = useProfileStore.getState().profile;
      const setWorkflowProfile = useFlowStore.getState().setProfile;
      setWorkflowProfile({
        id: null,
        name: "",
        tags: [],
        folder: null,
        sharing: [],
        auto_remove_responders: true,
      });
      setActive(0);
      setHighestStepVisited(0);
      setProgramData({
        ...defaultProgramData,
        contactlist_ids: defaultList ? [parseInt(defaultList)] : [],
      });
      useFlowStore.getState().setNodes([]);
      setLoadingPage(false);
      if (
        !workflow_id &&
        !p.dont_show_values?.library_auto_show &&
        !libraryId
      ) {
        setTimeout(() => {
          document.getElementById("workflow-library")?.click();
        }, 500);
      }
    }
  }, [campaignId, setLoadingPage]);

  return (
    <>
      <LoadingOverlay overlayProps={{ blur: 1 }} visible={loadingPage} />
      <Stack className="layout-block top" w={"100%"} gap={0}>
        <Group gap={0} wrap="nowrap">
          <Flex w={"100%"} mr="auto">
            <Stepper
              my={4}
              active={active}
              onStepClick={handleStepChange}
              iconSize={28}
              w={"100%"}
            >
              <Stepper.Step
                label={lang.campaign.steps.workflow}
                allowStepSelect={shouldAllowSelectStep(0)}
              />
              <Stepper.Step
                label={lang.campaign.steps.contacts}
                allowStepSelect={shouldAllowSelectStep(1)}
              />
              <Stepper.Step
                label={lang.campaign.steps.review}
                allowStepSelect={shouldAllowSelectStep(2)}
              />
              <Stepper.Step
                label={lang.campaign.steps.programmation}
                allowStepSelect={shouldAllowSelectStep(3)}
              />
              <Stepper.Step
                label={lang.campaign.steps.exclusions}
                allowStepSelect={shouldAllowSelectStep(4)}
              />
            </Stepper>
          </Flex>

          <Group wrap="nowrap" ml={64}>
            <Button
              variant="subtle"
              leftSection={<IconChevronLeft />}
              onClick={() => handleStepChange(active - 1)}
              disabled={active === 0}
              radius={"sm"}
            >
              {lang.global.back}
            </Button>
            {active === 4 ? (
              <Button
                variant="gradient"
                rightSection={
                  isReprog ? (
                    <IconRefreshDot />
                  ) : campaignId ? (
                    <IconDeviceFloppy />
                  ) : (
                    <IconSend />
                  )
                }
                onClick={handleProgram}
                disabled={getDoneDisabled()}
                radius={"sm"}
                gradient={{ from: "primary.4", to: "primary.6", deg: 90 }}
              >
                {isReprog
                  ? lang.campaign.steps.reprog
                  : campaignId
                  ? lang.campaign.steps.edit
                  : lang.campaign.steps.send}
              </Button>
            ) : (
              <Button
                variant="gradient"
                leftSection={active === 0 && <IconDeviceFloppy size={20} />}
                rightSection={<IconChevronRight />}
                onClick={() => handleStepChange(active + 1)}
                disabled={getNextDisabled()}
                w={(lang.id === "FRA" ? 130 : 120) - (active > 0 ? 15 : 0)}
                justify="flex-end"
                radius={"sm"}
                id="next-button"
                gradient={{ from: "primary.4", to: "primary.6", deg: 90 }}
              >
                {lang.global.next}
              </Button>
            )}
          </Group>
        </Group>
      </Stack>

      <Stack
        className="layout-block"
        flex={"1 1 auto"}
        h={0}
        pl={20}
        style={{ overflow: "hidden" }}
      >
        {handleActive()}
      </Stack>

      <RequiredActions />

      <LinkingNotification />
    </>
  );
};

export default CreateCampaign;
