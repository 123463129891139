import { useMemo, useState } from "react";
import {
  createField,
  generateDatafieldContent,
} from "../../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import { useDisclosure } from "@mantine/hooks";
import {
  Button,
  Group,
  Menu,
  Modal,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import AddTokens from "../../../../../components/Adder/AddTokens";
import GlobalLanguageSelector from "../../../../../components/Selectors/GlobalLanguageSelector";
import TemperatureSlider from "../../../../../components/View/TemperatureSlider";
import { useParams } from "react-router-dom";
import { IconSparkles } from "@tabler/icons-react";

const CreateFieldAI = () => {
  const { contactListId } = useParams();
  const lang = useLanguage((s) => s.language);
  const fields = useConfigStore((s) => s.datafields);

  const datafieldTokens = useMemo(() => {
    let df = fields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    return df;
  }, [fields]);

  const [opened, { open, close }] = useDisclosure();
  const [creatingDatafield, setCreatingDatafield] = useState(false);
  const [error, setError] = useState(false);
  const createDatafield = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    if (fields.some((x) => x.name.toLowerCase() === data?.name.toLowerCase())) {
      return setError("name");
    }
    setCreatingDatafield(true);
    createField({ name: data?.name }).then((res) => {
      notifications.show({
        message: lang.datafields.row_highlight.created,
        color: "tertiary.8",
      });

      generateDatafieldContent({
        contact_list_id: parseInt(contactListId),
        datafield_id: res.data.data_field_id,
        prompt: data.prompt,
        language: data.language,
        temperature: parseFloat(data.temperature),
        openai_api_key_id: parseInt(data?.openai_api_key_id),
      })
        .then((res) => {
          notifications.show({
            message: lang.contact_lists.datafield_content.success,
            color: "tertiary.8",
          });
          close();
        })
        .catch((err) => {
          close();
          let message = err.response.data.state_message;
          if (
            message === "You must provide at least one datafield in the prompt"
          ) {
            document.getElementById("ai_prompt").focus();
            setError("prompt");
          }
        })
        .finally(() => setCreatingDatafield(false));
    });
  };

  return (
    <>
      <Menu.Item onClick={open}>
        <Group gap={4}>
          <ThemeIcon variant="subtle" p={0}>
            <IconSparkles size={18} />
          </ThemeIcon>
          {lang.contact_lists.create_column.label_ai}
        </Group>
      </Menu.Item>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={lang.contact_lists.create_column.title}
          centered
        >
          <Stack component={"form"} onSubmit={createDatafield} gap={"xs"}>
            <Text size="sm">{lang.contact_lists.create_column.text2}</Text>
            <TextInput
              name="name"
              required
              label={lang.contact_lists.create_column.label}
              placeholder={lang.contact_lists.create_column.placeholder}
              data-autofocus
              onChange={(e) => {
                setError(false);
              }}
              error={
                error === "name" ? lang.contact_lists.create_column.error : null
              }
            />

            <Group pos={"relative"} wrap="nowrap" gap={0}>
              <Textarea
                label={lang.contact_lists.datafield_content.dialog.prompt}
                placeholder={
                  lang.contact_lists.datafield_content.dialog.prompt_placeholder
                }
                description={
                  lang.contact_lists.datafield_content.dialog.prompt_desc
                }
                name="prompt"
                id="ai_prompt"
                rows={4}
                w={"100%"}
                maxLength={500}
                required
                autoFocus
                data-autoFocus
                error={
                  error === "prompt" ? lang.errors.main.need_datafield : null
                }
              />
              <AddTokens
                items={datafieldTokens}
                handleAdd={(item) => {
                  let input = document.getElementById("ai_prompt");
                  input.value += `{${item?.value.replaceAll("%", "")}}`;
                  input.focus();
                }}
                long
                buttonProps={{
                  size: "xs",
                  variant: "subtle",
                  style: {
                    position: "absolute",
                    top: 4,
                    right: 0,
                  },
                }}
              />
            </Group>

            <GlobalLanguageSelector
              name={"language"}
              w="100%"
              size="sm"
              mb={4}
            />

            <TemperatureSlider />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={close} />
              <Button type="submit" loading={creatingDatafield}>
                {lang.datafields.create.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default CreateFieldAI;
