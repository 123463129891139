import { Center, Loader, Stack, Text } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import ActivityGroup from "../sheet/Right/activity/ActivityGroup";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { findProspect } from "../../../../../api/PRMAPI";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import { parseProspectDatafields } from "../../utils";

const LeadHover = ({ current, container }) => {
  const lang = useLanguage((s) => s.language);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );
  const [loading, setLoading] = useState(false);

  const activities = useMemo(() => {
    let h = [
      ...(current?.history || []),
      {
        history_type: "creation",
        date: current?.created_on,
        event: "email_open",
        workflow_step_id: 0,
        workflow_programmation_id: 0,
      },
    ];
    let history =
      h.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)) || [];

    const filtered = history.filter((x) => x.history_type === "note");

    const groupedActivities = {};

    filtered.forEach((activity) => {
      const date = activity.date.split("T")[0]; // Extracting only date part
      if (groupedActivities[date]) {
        groupedActivities[date].push(activity);
      } else {
        groupedActivities[date] = [activity];
      }
    });

    return groupedActivities;
  }, [current?.created_on, current?.history]);

  useEffect(() => {
    setLoading(true);
    findProspect(current.id)
      .then((r) => {
        console.log(current.id);
        updateContainerItem(container.id, current.id, {
          ...parseProspectDatafields(r.data.contact_profile),
        });
      })
      .finally(() => setLoading(false));
  }, [container.id, current.id]);

  return (
    <Stack miw={300}>
      {loading && (
        <Center h={"100%"} w={"100%"}>
          <Loader size={32} />
        </Center>
      )}

      {Object.keys(activities).length > 0 ? (
        <Stack mah={"80vh"} style={{ overflow: "auto" }} pt={16}>
          {Object.keys(activities).map((date) => (
            <ActivityGroup
              key={date}
              date={date}
              group={activities[date]}
              outside
            />
          ))}
        </Stack>
      ) : loading ? (
        ""
      ) : (
        <Text c={"dimmed"} ta={"center"}>
          {lang.prm.details.no_note}
        </Text>
      )}
    </Stack>
  );
};

export default LeadHover;
