import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  NumberInput,
  Stack,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import {
  IconArrowRight,
  IconBaselineDensityMedium,
  IconSettings,
  IconSignature,
  IconSparkles,
} from "@tabler/icons-react";
import GlobalLanguageSelector from "../../../../../components/Selectors/GlobalLanguageSelector";
import EmailAccountSelector from "../../../../../components/Selectors/EmailAccountSelector";
import EmailSignatureSelector from "../../../../../components/Selectors/EmailSignatureSelector";
import AddTokens from "../../../../../components/Adder/AddTokens";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import QuickCampaignLoading from "./QuickCampaignLoading";
import { incrementHour } from "../../../../../functions/dates";
import { defaultNodeConfigs } from "../../../workflows/CreateCampaign/flow/flowSettings";
import { createMEmail } from "../../../../../api/ModelsApi";
import { createWorkflow } from "../../../../../api/WorkflowAPI";
import { useNavigate } from "react-router-dom";
import { getSignatures } from "../../../../../api/ConfigsApi";
import AIIllustration from "./AIIllustration";
import { useProfileStore } from "../../../../../stores/UserStore";

const QuickCampaignContent = ({ current, handleClose }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [loading, setLoading] = useState(false);
  const [sender, setSender] = useState(null);
  const [sign, setSign] = useState(null);
  const [data, setData] = useState(null);

  const [activeStep, setActiveStep] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const datafields = useConfigStore((s) => s.datafields);
  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    df = [
      {
        label: lang.components.variable_manager.dialog.signature_variable_name,
        value: "%signature%",
        name: lang.components.variable_manager.dialog.signature_variable_name,
      },
      {
        label:
          lang.components.variable_manager.dialog.unsubscribe_variable_name,
        value: "%unsubscribe%",
        name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.webversion_variable_name,
        value: "%webversion%",
        name: lang.components.variable_manager.dialog.webversion_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.b64_variable_name,
        value: "%email_b64%",
        name: lang.components.variable_manager.dialog.b64_variable_name,
      },
      ...df,
    ];

    return df;
  }, [datafields, lang]);

  const [loadingStep, setLoadingStep] = useState(0);
  const navigate = useNavigate();
  const handleModels = useCallback(
    (model_data) => {
      let timezoneOffset = new Date().getTimezoneOffset() / 60;
      const time_start_sending = incrementHour(
        defaultNodeConfigs.time_start_sending,
        timezoneOffset
      );
      const time_stop_sending = incrementHour(
        defaultNodeConfigs.time_stop_sending,
        timezoneOffset
      );

      createMEmail(model_data)
        .then((response) => {
          const newModelId = response.data.model_id;

          const firstStep = {
            action_type: "email",
            allow_massmailing: false,
            blacklist_ids: [],
            email_linkedin_exclude: [],
            integration_id: sender.id,
            is_initial: true,
            limit_send_per_day: 0,
            model_id: newModelId,
            parent_ids: [],
            pool_id: null,
            position_x: 40,
            position_y: 168,
            sending_weekends_allowed: false,
            show_reply_as_conversation: false,
            signature_id: sign?.id || null,
            step_id: {
              id: 1,
              type: "new",
            },
            step_type: "action",
            time_start_sending: time_start_sending,
            time_stop_sending: time_stop_sending,
            use_smtp_fallback_server: false,
          };

          setLoadingStep(1);
          createWorkflow({
            name: "Generated Workflow AI",
            auto_remove_responders: true,
            steps: [firstStep],
          })
            .then((workflowResponse) => {
              setLoadingStep(10);
              setLoading(false);

              navigate(
                `/campaign/create?workflow=${workflowResponse.data.workflow_id}&list=${current.id}&quick=true`
              );
            })
            .catch(() => setLoading(false));
        })
        .catch(() => setLoading(false));
    },
    [current.id, navigate, sender?.id, sign?.id]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleModels(data);
  };

  const handleCollapse = () => {
    setExpanded((s) => !s);
  };

  const handleNext = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    data.number_paragraphs = parseInt(data.number_paragraphs);
    data.subject = data.subject || null;

    let model_data = {
      number_paragraphs: parseInt(data.number_paragraphs),
      subject: data.subject || null,
      name: "AI model",

      is_ai: true,
      ai_prompt: data.prompt,
      ai_language: data.language,
    };

    setData(model_data);
    setActiveStep((s) => s + 1);
  };

  useEffect(() => {
    getSignatures();
  }, []);

  return (
    <>
      <LoadingOverlay
        visible={loading}
        w={"100%"}
        h={"100%"}
        overlayProps={{
          blur: 15,
        }}
        loaderProps={{
          children: <QuickCampaignLoading loadingStep={loadingStep} />,
        }}
      />

      <Group align="stretch" gap={"xl"}>
        <Stack
          p={"xl"}
          bg={"gray.0"}
          style={{
            borderRadius: 12,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          w={"40%"}
        >
          <Box w={200}>
            <AIIllustration />
          </Box>
          <Text c={"dimmed"}>{lang.quick_campaign.text}</Text>
          <Stepper
            mt={16}
            active={activeStep}
            orientation="vertical"
            color="secondary"
            styles={{
              verticalSeparator: {
                borderWidth: 2,
                borderColor: "var(--mantine-color-secondary-2)",
              },
            }}
          >
            <Stepper.Step
              label="Configuration du modèle"
              description="Modèle IA"
            />
            <Stepper.Step
              label="Configuration du scénario"
              description="Options de l'expéditeur"
            />
          </Stepper>
        </Stack>
        <Stack
          component={"form"}
          onSubmit={activeStep === 0 ? handleNext : handleSubmit}
          flex={1}
          pr={12}
        >
          {activeStep === 0 ? (
            <>
              <Group wrap="nowrap" gap={24}>
                <TextInput
                  size="md"
                  id="email-subject"
                  label={lang.model_emails.create.dialog.subject}
                  placeholder={lang.model_emails.create.dialog.subject}
                  description={lang.quick_campaign.subject_desc}
                  name="subject"
                  w="100%"
                  styles={{
                    input: {
                      paddingLeft: 42,
                    },
                  }}
                  leftSection={
                    loading ? (
                      <Loader type="dots" size={16} />
                    ) : (
                      <AddTokens
                        items={datafieldTokens}
                        handleAdd={(item) => {
                          let input = document.getElementById("email-subject");
                          input.value += `{${item?.value.replaceAll("%", "")}}`;
                          input.focus();
                        }}
                      />
                    )
                  }
                />
              </Group>

              <Group pos={"relative"} wrap="nowrap" gap={0} align="stretch">
                <Textarea
                  size="md"
                  id="ai_prompt"
                  label={"Prompt"}
                  placeholder={lang.quick_campaign.prompt_placeholder}
                  description={lang.quick_campaign.prompt_desc}
                  defaultValue={lang.quick_campaign.default_prompt
                    .replace(
                      "$",
                      profile?.organizations?.[0]?.name ||
                        lang.quick_campaign.company_name
                    )
                    .replace(
                      "#",
                      profile?.activity_description ||
                        lang.quick_campaign.company_desc
                    )}
                  name="prompt"
                  rows={7}
                  w={"100%"}
                  maxLength={500}
                  required
                  styles={{
                    input: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                />
                <AddTokens
                  items={datafieldTokens}
                  handleAdd={(item) => {
                    let input = document.getElementById("ai_prompt");
                    input.value += `{${item?.value.replaceAll("%", "")}}`;
                    input.focus();
                  }}
                  long
                  buttonProps={{
                    size: "xs",
                    variant: "subtle",
                    style: {
                      position: "absolute",
                      top: 4,
                      right: 0,
                    },
                  }}
                />
                <Tooltip label={lang.profile.advanced}>
                  <ActionIcon
                    variant="light"
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    h={"auto"}
                    mt={39}
                    mb={1}
                    w={32}
                    onClick={handleCollapse}
                  >
                    <IconSettings size={18} />
                  </ActionIcon>
                </Tooltip>
              </Group>

              <Collapse in={expanded}>
                <Group wrap="nowrap" gap={24} mt={-8}>
                  <GlobalLanguageSelector
                    name={"language"}
                    withinPortal={false}
                    size="sm"
                    w={"100%"}
                    description={lang.quick_campaign.language_desc}
                  />

                  <NumberInput
                    name="number_paragraphs"
                    label={lang.model_emails.create.dialog.paragraphs}
                    description={lang.quick_campaign.paragraph_desc}
                    defaultValue={3}
                    leftSection={
                      <Tooltip
                        label={lang.model_emails.create.dialog.paragraphs}
                      >
                        <IconBaselineDensityMedium size={16} />
                      </Tooltip>
                    }
                    w={"100%"}
                    size="sm"
                    required
                    mr={"auto"}
                    min={1}
                    max={4}
                  />
                </Group>
              </Collapse>
            </>
          ) : (
            <>
              <EmailAccountSelector
                label={lang.emails_account.columns.sender}
                description={lang.quick_campaign.email_desc}
                size={"md"}
                required
                onChange={(v) => {
                  console.log(v);
                  setSender(v);
                  if (v?.email_signatures_profile) {
                    setSign(v.email_signatures_profile);
                  }
                }}
                value={sender?.id}
              />

              <EmailSignatureSelector
                description={lang.quick_campaign.sign_desc}
                value={sign?.id}
                onChange={setSign}
                label={"Signature"}
                leftSection={<IconSignature size={18} />}
                size={"md"}
                w={"100%"}
              />
            </>
          )}

          <Divider mt={"auto"} />

          <Group justify="end" gap={"xl"}>
            <BackButton
              onClick={
                activeStep === 0
                  ? handleClose
                  : () => setActiveStep((s) => s - 1)
              }
              label={activeStep === 0 ? lang.global.close : lang.global.back}
              size="md"
            />

            {activeStep === 0 ? (
              <Button
                rightSection={<IconArrowRight size={18} />}
                color="primary"
                size="md"
                type="submit"
              >
                {lang.global.next}
              </Button>
            ) : (
              <Button
                leftSection={<IconSparkles size={18} />}
                loading={loading}
                color="primary"
                type="submit"
                size="md"
              >
                {lang.contact_lists.icebreaker.dialog.submit_button}
              </Button>
            )}
          </Group>
        </Stack>
      </Group>
    </>
  );
};

export default QuickCampaignContent;
