import { useConfigStore } from "../stores/ConfigStore";
import { mainAxios as axios } from "./axios";

export const getTags = async () => {
  let tags = await axios.get("/tags");

  if (tags.data.state) {
    useConfigStore.getState().setTags(tags.data.tags_list);
    return tags;
  }
};

export const createTag = async ({ name }) => {
  let r = await axios.post("/tags", {
    name: name,
  });

  return r;
};

export const editTag = async ({ id, name, sharing }) => {
  return axios.put(`/tags/${id}`, {
    name: name,
    sharing: sharing,
  });
};

export const deleteTag = async ({ id }) => {
  let a = axios.delete(`/tags/${id}`);

  return a;
};

export const handleTags = async (t) => {
  let myTags = useConfigStore.getState().tags;
  let promises = [];
  t.forEach((tag) => {
    let tagExist = myTags?.find((x) => x.name === tag);
    if (!tagExist) {
      promises.push(createTag(tag));
    } else {
      console.log("tag exist !");
    }
  });

  let p = await Promise.all(promises);

  if (p) {
    console.log(p);
    if (promises.length) return getTags();
    return {
      data: {
        tags_list: myTags,
      },
    };
  }
};
