import axios from "axios";
import { getStringFromError } from "../functions/errorGetter";
import {
  useProfileStore,
  useSessionStore,
  useTempSessionStore,
} from "../stores/UserStore";
import { jwtDecode } from "jwt-decode";
import { notifications } from "@mantine/notifications";
import QueryString from "qs";
import { modals } from "@mantine/modals";
import ErrorModal from "../layouts/ErrorModal/ErrorModal";
import MaintenancePage from "../pages/unprotected/errors/MaintenancePage";

export const api_url =
  localStorage.getItem("api_url") && localStorage.getItem("api_url") !== "null"
    ? localStorage.getItem("api_url")
    : window?._env_?.API_URL || "https://api.magileads.net";

export const mainAxios = axios.create({
  baseURL: api_url,
  paramsSerializer: (params) =>
    QueryString.stringify(params, { arrayFormat: "repeat" }),
  headers: {
    "Content-Type": "application/json",
  },
});

let refreshing_switch = false;
let refreshing = false;

async function handleMainRefresh() {
  const session = JSON.parse(localStorage.getItem("session"))?.state.session;
  console.log("refreshing main");

  const response = await mainAxios.post(
    "/users/authentication/refresh",
    {
      refresh_token: session?.refresh_token,
    },
    {
      headers: {
        "Is-Refresh": true,
        "Is-Main": true,
      },
    }
  );

  return response?.data;
}

async function handleSwitchRefresh(user_switch) {
  const session = JSON.parse(localStorage.getItem("session"))?.state.session;
  console.log("refreshing switch");
  const response = await mainAxios
    .post(`/users/switch/to/${user_switch?.id}`, undefined, {
      headers: {
        "Is-Main": true,
        Authorization: `Bearer ${session?.access_token}`,
        "Is-Refresh-Switch": true,
      },
    })
    .catch((reason) => {
      console.log(reason);
    });

  return response.data;
}

async function refreshToken(original, isMain) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const user_switch = useSessionStore.getState().user_switch;

    let refreshFunction = null;
    if (user_switch) {
      if (original.headers["Is-Main"] || isMain) {
        refreshFunction = handleMainRefresh;
        refreshing = true;
      } else {
        refreshFunction = () => handleSwitchRefresh(user_switch);
        refreshing_switch = true;
      }
    } else {
      refreshFunction = handleMainRefresh;
      refreshing = true;
    }
    const data = await refreshFunction();

    if (data?.state) {
      if (data?.token) {
        // Get the current timestamp in milliseconds
        const currentTimestamp = Date.now();
        const currentDate = new Date(currentTimestamp);
        // Add delay (minutes)
        currentDate.setMinutes(currentDate.getMinutes() + 50);
        const updatedTimestamp = currentDate.getTime();

        let new_user_switch = {
          ...user_switch,
          token: data.token,
          exp: updatedTimestamp,
        };

        refreshing_switch = false;
        useSessionStore.getState().setUserSwitch(new_user_switch);
      } else {
        let new_session = {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
        };

        useSessionStore.getState().setSession(new_session);
        refreshing = false;
      }

      return resolve(data);
    } else {
      refreshing = false;
      return reject(Error("refresh fail: " + data?.state_message));
    }
  });
}

async function waitingRefresh(config) {
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      const new_session = JSON.parse(localStorage.getItem("session"))?.state
        .session;
      const new_user_switch = JSON.parse(localStorage.getItem("session"))?.state
        ?.user_switch;
      if (!refreshing) {
        if (
          !config.headers["Is-Main"] &&
          new_user_switch &&
          !refreshing_switch
        ) {
          clearInterval(interval);
          config.headers[
            "Authorization"
          ] = `Bearer ${new_session?.access_token}`;
          config.headers["X-API-Key"] = new_user_switch?.token;
          return resolve(config);
        } else if (config.headers["Is-Main"] || !new_user_switch) {
          clearInterval(interval);
          config.headers[
            "Authorization"
          ] = `Bearer ${new_session?.access_token}`;
          return resolve(config);
        }
      }
    }, 1000); // Check every second
  });
}

//#region Request
mainAxios.interceptors.request.use(
  async (config) => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_switch = JSON.parse(localStorage.getItem("session"))?.state
      .user_switch;
    const session = JSON.parse(localStorage.getItem("session"))?.state.session;
    const apiKey = urlParams.get("apiKey") || localStorage.getItem("api_key");

    //#region APIKEY
    if (
      apiKey &&
      apiKey !== "false" &&
      apiKey !== "null" &&
      apiKey?.length > 16
    ) {
      config.headers = {
        ...config.headers,
        "X-API-Key": apiKey,
      };

      return config;
    }
    //#endregion

    //#region Switch
    let reloaded = useTempSessionStore.getState().isReloaded;
    if ((user_switch && reloaded) || config.headers["X-API-Key"]) {
      // Continue to add Authorization if Is-Main = true
      if (!config.headers["Is-Main"]) {
        config.headers["X-API-Key"] = user_switch?.token;
      }
    }
    //#endregion

    if (session?.access_token) {
      config.headers["Authorization"] = `Bearer ${session?.access_token}`;
      const { exp } = jwtDecode(session?.access_token);

      const expired = Date.now() >= exp * 1000 - 60000; // 60,000 for 2 minutes
      const expired_switch = Date.now() >= user_switch?.exp - 60000;

      if (refreshing) {
        if (config.headers["Is-Refresh"]) {
          return config;
        }
        return waitingRefresh(config);
      }

      if (refreshing_switch) {
        if (
          !config.headers["Is-Refresh-Switch"] &&
          !config.headers["Is-Refresh"]
        ) {
          return waitingRefresh(config);
        }
      }

      if (expired_switch && !refreshing_switch) {
        return await refreshToken(config).then((data) => {
          const session = JSON.parse(localStorage.getItem("session"))?.state
            .session;
          config.headers["Authorization"] = `Bearer ${session?.access_token}`;
          if (data?.token) config.headers["X-API-Key"] = data.token;
          return config;
        });
      }

      if (expired) {
        return await refreshToken(config, true).then((data) => {
          config.headers["Authorization"] = `Bearer ${data?.access_token}`;
          return config;
        });
      }

      return config;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

//#endregion

//#region Response

function handleErrorDisplay(error) {
  if (error?.message === "Request aborted") {
    return;
  }
  if (!error?.response?.data || error?.message === "Network Error") {
    return notifications.show({ message: "Network error", color: "red" });
  }

  let error_message = getStringFromError(error);
  if (error?.response?.data.state_message === "validation_exception") {
    error_message = `Error: ${JSON.stringify(error?.response?.data?.errors)}`;
  }

  if (
    error?.response?.data?.state_message?.includes("reached") ||
    error?.response?.data?.state_message?.includes(
      "unauthorized_permission_value"
    )
  ) {
    return modals.open({
      modalId: "error-modal",
      centered: true,
      children: <ErrorModal message={error.response.data.state_message} />,
      size: "lg",
    });
  }

  if (typeof error?.response?.data?.error_details === "string") {
    error_message = error?.response?.data.error_details;
  }

  notifications.show({
    message: error_message,
    color: "red",
  });
}

const responseErrorInterceptor = async (error) => {
  console.log("interceptor error", error);

  if (error.response?.data?.state_message === "token_expired") {
    return Promise.reject(error);
  }

  if (
    error.response?.data?.state_message === "in_maintenance" ||
    error.response?.status === 503
  ) {
    return modals.open({
      modalId: "error-modal",
      children: <MaintenancePage />,
      size: "100%",
      withCloseButton: false,
      fullScreen: true,
      zIndex: 9999999,
    });
  }

  if (
    error.response?.data?.state_message === "refresh_token_expired" ||
    error.response?.data?.state_message === "refresh_token_already_used" ||
    error.response?.data?.state_message === "refresh_token_does_not_exist" ||
    error.response?.data?.state_message === "token_not_exist" ||
    error.response?.data?.state_message === "user_account_disabled" ||
    error.response?.data?.state_message === "you_must_re_authenticate" ||
    error.response?.data?.state_message === "token_empty" ||
    error.response?.data?.state_message === "too_many_attempts"
  ) {
    useSessionStore.getState().setUserSwitch(null);
    useSessionStore.getState().setSession(null);
    useProfileStore.getState().setProfile(null);

    return window.location.replace(
      `/login?destination=${window.location.pathname}`
    );
  }

  handleErrorDisplay(error);

  return Promise.reject(error);
};

const responseResponseInterceptor = (response) => {
  const user_switch = JSON.parse(localStorage.getItem("session"))?.state
    .user_switch;
  if (!user_switch) useTempSessionStore.getState().setIsReloaded(false);

  return response;
};

mainAxios.interceptors.response.use(
  (response) => responseResponseInterceptor(response),
  (errors) => responseErrorInterceptor(errors)
);

//#endregion
