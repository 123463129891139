import { useRouteLoaderData } from "react-router-dom";
import { Group } from "@mantine/core";
import ContactsTableView from "./ContactsTableView";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import CreateListMenu from "../components/CreateListMenu";
import { useState } from "react";
import PageTitle from "../../../../components/View/PageTitle";
import { useLanguage } from "../../../../stores/LanguageStore";
import CreateField from "../components/create-field/CreateField";

const ContactListContentListing = ({ inFolder }) => {
  const lang = useLanguage((s) => s.language);
  const listProfile = useRouteLoaderData(
    inFolder ? "folder-contact-list" : "contact-list"
  );
  const [loadProfile, setLoadProfile] = useState(false);

  if (!listProfile) {
    return;
  }

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle
          title={listProfile?.name || lang.contact_lists.browser_tab_title}
        />
        <CreateField />
        <CreateListMenu />
      </Group>
      <TableLayoutBlock>
        <ContactsTableView
          defaultCurrentList={listProfile}
          loadProfile={loadProfile}
          setLoadProfile={setLoadProfile}
        />
      </TableLayoutBlock>
    </>
  );
};

export default ContactListContentListing;
