import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import dayjs from "dayjs";
import { ActionIcon, Button, Group, Select, Tooltip } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconClock } from "@tabler/icons-react";

const FilterDateSelector = ({ placeholder, disabled, setValue, value }) => {
  const lang = useLanguage((s) => s.language);
  const relativeDates = useMemo(() => {
    const actual = dayjs()
      .set("h", 0)
      .set("minutes", 0)
      .set("second", 0)
      .set("milliseconds", 0);

    return [
      {
        value: actual.format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.today,
      },
      {
        value: actual.subtract(1, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_1,
        type: "Passé",
      },
      {
        value: actual.subtract(2, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_2,
        type: "Passé",
      },
      {
        value: actual.subtract(3, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_3,
        type: "Passé",
      },
      {
        value: actual.subtract(4, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_4,
        type: "Passé",
      },
      {
        value: actual.subtract(5, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_5,
        type: "Passé",
      },
      {
        value: actual.subtract(6, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_6,
        type: "Passé",
      },
      {
        value: actual.subtract(7, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_7,
        type: "Passé",
      },
      {
        value: actual.subtract(30, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.past_30,
        type: "Passé",
      },
      {
        value: actual.add(1, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_1,
        type: "Futur",
      },
      {
        value: actual.add(2, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_2,
        type: "Futur",
      },
      {
        value: actual.add(3, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_3,
        type: "Futur",
      },
      {
        value: actual.add(4, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_4,
        type: "Futur",
      },
      {
        value: actual.add(5, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_5,
        type: "Futur",
      },
      {
        value: actual.add(6, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_6,
        type: "Futur",
      },
      {
        value: actual.add(7, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_7,
        type: "Futur",
      },
      {
        value: actual.add(30, "d").format("YYYY-MM-DD"),
        label: lang.table.toolbar.filter.filters_list_label.dates.future_30,
        type: "Futur",
      },
    ];
  }, [lang]);

  const [isExact, setIsExact] = useState(!!value);

  const [dayOnly, setDayOnly] = useState(
    value?.toString()?.length > 12 ? false : true
  );

  const [date, setDate] = useState(value);

  useEffect(() => {
    if (isExact) {
      let d = dayjs(date);
      setValue(dayOnly ? d?.format("YYYY-MM-DD") : d?.toISOString());
      setDayOnly(value?.length > 12 ? false : true);
    } else {
      setDate(dayjs().format("YYYY-MM-DD"));
    }
  }, [isExact]);

  useEffect(() => {
    let d = dayjs(date);
    setValue(dayOnly || !isExact ? d?.format("YYYY-MM-DD") : d?.toISOString());
  }, [dayOnly, date]);

  return (
    <Group wrap="nowrap">
      {isExact ? (
        <>
          <DateTimePicker
            placeholder={placeholder}
            w={dayOnly ? 110 : 158}
            value={new Date(date)}
            disabled={disabled}
            onChange={(d) => {
              console.log(d);
              setDate(d);
            }}
            valueFormat={
              dayOnly ? "DD / MM / YYYY" : "DD / MM / YYYY      HH : mm"
            }
            // minDate={new Date()}
            timeInputProps={{
              disabled: dayOnly,
            }}
            popoverProps={{
              zIndex: 1500
            }}
          />

          <Tooltip label={!dayOnly ? "Avec heures" : "Sans heures"}>
            <ActionIcon
              variant={dayOnly ? "default" : "filled"}
              onClick={() => {
                setDayOnly(!dayOnly);
              }}
            >
              <IconClock size={18} />
            </ActionIcon>
          </Tooltip>
        </>
      ) : (
        <Select
          data={relativeDates}
          defaultValue={
            relativeDates?.find((x) => x.value === value)?.value ||
            relativeDates[0].value
          }
          w={140}
          required
          disabled={disabled}
          onChange={(v) => setDate(v)}
          comboboxProps={{ shadow: "sm", offset: 2 }}
        />
      )}
      <Button
        size="xs"
        variant="outline"
        onClick={() => setIsExact(!isExact)}
        // sx={{ ml: 1, height: 21, fontSize: 10, fontWeight: "bold", width: 64 }}
        // color="secondary"
      >
        {isExact ? "relative" : "exact"}
      </Button>
    </Group>
  );
};

export default FilterDateSelector;
