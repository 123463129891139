import {
  ActionIcon,
  Group,
  Indicator,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useListStore } from "../../../../../stores/ListStore";
import { useEffect, useMemo, useState } from "react";
import { findList } from "../../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import {
  IconAlertCircle,
  IconCheck,
  IconCircleMinus,
  IconClock,
  IconLoader,
} from "@tabler/icons-react";
import JobItemCompleted from "./JobItemCompleted";
import JobItemInProgress from "./JobItemInProgress";
import JobItemCancelled from "./JobItemCancelled";
import JobItemFailed from "./JobItemFailed";
import JobItemInQueue from "./JobItemInQueue";
import ListRefreshTargeting from "../../actions/ListRefreshTargeting";
import ListRefreshTargetingGoogle from "../../actions/ListRefreshTargetingGoogle";
import { getLinkedinAccounts } from "../../../../../api/EmailAPI";

const updateDelay = 5000;

const Jobs = ({
  row,
  setCurrentList,
  apiRef,
  isContent,
  isZapier,
  onDiff,
  isList,
  setLoading,
}) => {
  const lang = useLanguage((s) => s.language);
  const completed = useMemo(
    () => (row ? row.state_details.filter((x) => x.state === "Completed") : []),
    [row]
  );
  const failed = useMemo(
    () => (row ? row.state_details.filter((x) => x.state === "Failed") : []),
    [row]
  );
  const inQueue = useMemo(
    () => (row ? row.state_details.filter((x) => x.state === "InQueue") : []),
    [row]
  );
  const inProgress = useMemo(
    () =>
      row ? row.state_details.filter((x) => x.state === "InProgress") : [],
    [row]
  );
  const cancelled = useMemo(
    () => (row ? row.state_details.filter((x) => x.state === "Cancelled") : []),
    [row]
  );

  const props = useMemo(() => {
    return {
      completed: {
        value: completed,
        color: "tertiary.8",
        icon: <IconCheck size={16} />,
      },
      in_progress: {
        value: inProgress,
        color: "primary",
        icon: <IconLoader size={16} />,
      },
      in_queue: {
        value: inQueue,
        color: "orange",
        icon: <IconClock size={16} />,
      },
      error: {
        value: failed,
        color: "red",
        icon: <IconAlertCircle size={16} />,
      },
      cancelled: {
        value: cancelled,
        color: "purple",
        icon: <IconCircleMinus size={16} />,
      },
    };
  }, [cancelled, completed, failed, inProgress, inQueue]);

  const [type, setType] = useState(null);
  const handleClick = (type) => {
    setType(type);
    getLinkedinAccounts();
  };
  const handleClose = () => {
    setType(null);
  };

  useEffect(() => {
    if (
      row?.state_details?.find(
        (x) => x.state === "InProgress" || x.state === "InQueue"
      )
    ) {
      const interval = setInterval(() => {
        if (isZapier) {
          if (document.getElementById("zapier-reload"))
            document.getElementById("zapier-reload")?.click();
        } else {
          if (!useListStore.getState().autoReloading) {
            console.log("updating List");
            findList(row.id).then((response) => {
              let newProfile = response.data.contact_list_profile;
              if (apiRef) {
                apiRef.current.updateRows([{ ...newProfile }]);
              }

              setCurrentList?.(newProfile);
              let diff =
                row.state_details.filter((x) => x.state === "Completed")
                  .length <
                newProfile.state_details.filter((x) => x.state === "Completed")
                  .length;

              if (diff) {
                onDiff?.();
                notifications.show({
                  message: `${lang.contact_lists.jobs.success_text1} ${
                    row?.name || ""
                  } #${row?.id} ${lang.contact_lists.jobs.success_text2}`,
                });
              }
            });
          }
        }
      }, updateDelay);
      return () => clearInterval(interval);
    }
  }, [
    row.state_details,
    row?.name,
    row.id,
    apiRef,
    lang,
    isZapier,
    isContent,
    setCurrentList,
    onDiff,
  ]);

  return (
    <>
      <Group wrap="nowrap">
        {Object.keys(props).map((key) => {
          let values = props[key].value;
          let color = props[key].color;
          let icon = props[key].icon;
          if (values?.length > 0) {
            return (
              <>
                <Tooltip
                  label={
                    values.length +
                    ` ${lang.contact_lists.jobs.job_name}${
                      values?.length > 1 ? "s" : ""
                    } ${lang.contact_lists.jobs[key].name}`
                  }
                  key={key}
                >
                  <Indicator
                    key={key}
                    size={
                      values?.length > 9 ? (values?.length > 99 ? 24 : 18) : 14
                    }
                    styles={{
                      indicator: {
                        fontSize: 8,
                      },
                    }}
                    label={values?.length > 99 ? "99+" : values?.length}
                    offset={1}
                    position="top-start"
                    color={color}
                    mt={isContent ? 0 : 5}
                  >
                    <ActionIcon
                      variant="light"
                      color={color}
                      onClick={() => handleClick(key)}
                      size={"sm"}
                      radius={"md"}
                      w={24}
                      h={24}
                    >
                      {icon}
                    </ActionIcon>
                  </Indicator>
                </Tooltip>

                {isList &&
                  key === "error" &&
                  row.state_details.find(
                    (x) => x.type.includes("inked") && x.state === "Failed"
                  ) && (
                    <ListRefreshTargeting
                      closeMenu={() => {}}
                      current={row}
                      inJob
                      onFinish={() => setLoading(true)}
                    />
                  )}

                {isList &&
                  key === "error" &&
                  row.state_details.find(
                    (x) => x.type.includes("oogl") && x.state === "Failed"
                  ) && (
                    <ListRefreshTargetingGoogle
                      closeMenu={() => {}}
                      current={row}
                      inJob
                      onFinish={() => setLoading(true)}
                    />
                  )}
              </>
            );
          }
          return "";
        })}
      </Group>

      {!!type && (
        <Modal
          opened={!!type}
          onClose={handleClose}
          title={
            <Text maw={500} truncate="end" mr={16}>
              {lang.contact_lists.jobs[type].title}{" "}
              <b>
                {row.name} #{row.id}
              </b>
            </Text>
          }
          size={"52%"}
        >
          <Stack>
            {props[type]?.value.length > 0 ? (
              props[type]?.value.map((item, i) => {
                if (type === "completed") {
                  return (
                    <JobItemCompleted key={item?.uniqid || i} item={item} />
                  );
                }
                if (type === "in_progress") {
                  return (
                    <JobItemInProgress key={item?.uniqid || i} item={item} />
                  );
                }
                if (type === "in_queue") {
                  return <JobItemInQueue key={item?.uniqid || i} item={item} />;
                }
                if (type === "error") {
                  return <JobItemFailed key={item?.uniqid || i} item={item} />;
                }
                if (type === "cancelled") {
                  return (
                    <JobItemCancelled key={item?.uniqid || i} item={item} />
                  );
                }
                return "";
              })
            ) : (
              <Text c={"dimmed"}>{lang.contact_lists.jobs[type].no_job}</Text>
            )}
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default Jobs;
