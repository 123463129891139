import { ActionIcon, Group, Indicator, Paper, Tooltip } from "@mantine/core";
import { nodeWidth } from "../../flowSettings";
import EventSelector from "./EventSelector";
import DelaySelector from "./DelaySelector";
import { getEvents } from "./events";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useMemo, useState } from "react";
import { useModelsStore } from "../../../../../../../stores/ConfigStore";
import EventFilter from "./EventFilter";
import { IconFilterCog } from "@tabler/icons-react";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import Linker from "./Linker";

const EventNode = ({ data, id, nodeDetail, xPos, yPos }) => {
  const lang = useLanguage((s) => s.language);
  const nodes = useFlowStore((s) => s.nodes);
  const linking = useFlowStore((s) => s.linking);
  const nodeSource = useMemo(
    () => nodes.find((x) => x.id === data?.source),
    [data?.source, nodes]
  );
  const models = useModelsStore((s) => s.model_emails);

  const sourceModel = useMemo(() => {
    return models.find((x) => x.id === nodeSource?.data?.model_id);
  }, [models, nodeSource?.data?.model_id]);

  const goodEvent = useMemo(() => {
    const correctEvents = getEvents(nodeSource?.data?.actionType);
    if (data?.event_type === "link_click") {
      return sourceModel?.links?.length > 0;
    }
    if (correctEvents.find((x) => x.value === data?.event_type)) {
      return true;
    }
    if (nodeSource?.data?.actionType === "call") return true;
  }, [
    data?.event_type,
    nodeSource?.data?.actionType,
    sourceModel?.links?.length,
  ]);

  const [showFilter, setShowFilter] = useState(!!data?.filter);
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Paper
      w={nodeWidth}
      withBorder
      p={"xs"}
      shadow={"sm"}
      className="action-node event"
      pos={"relative"}
      style={{
        borderColor: data?.error ? "red" : nodeDetail.color,
        borderWidth: data?.error ? 2 : 1,
        cursor: "default",
      }}
      radius={"md"}
    >
      <Group wrap="nowrap" align="start" gap={4}>
        <EventSelector
          event_type={data?.event_type}
          event_name={data?.name}
          goodEvent={goodEvent}
          id={id}
          which_links_clicked={data?.which_links_clicked}
          nodeSource={nodeSource}
          sourceModel={sourceModel}
        />
        <Tooltip label={lang.campaign.selectors.event.use_filter}>
          <Indicator
            offset={2}
            color="red"
            position="top-end"
            withBorder
            disabled={!data?.filter || data?.filter?.values?.length === 0}
            styles={{
              indicator: {
                width: 12,
                height: 12,
              },
            }}
            mt={19}
          >
            <ActionIcon
              onClick={handleShowFilter}
              variant={showFilter ? "light" : "default"}
              size={"md"}
              h={30}
            >
              <IconFilterCog size={16} />
            </ActionIcon>
          </Indicator>
        </Tooltip>
      </Group>

      {showFilter && <EventFilter filter={data?.filter} id={id} />}

      <DelaySelector id={id} when_minutes={data?.when_minutes} />

      {!linking && <Linker id={id} xPos={xPos} yPos={yPos} />}
    </Paper>
  );
};

export default EventNode;
