import Sparkles from "../../components/Sparkles/Sparkles";
import { IconPlus } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";
import { Box, Button } from "@mantine/core";
import { Link } from "react-router-dom";

const CreateCampaignButton = ({ ...props }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Button
      component={Link}
      to={"/campaign/create"}
      leftSection={<IconPlus size={18} />}
      id="campaign-button"
      radius={"sm"}
      h={36}
      w={"calc(100% - 24px)"}
      mx={12}
      mt={12}
      justify="start"
      variant="light"
      pos={"relative"}
      style={{
        overflow: "visible",
      }}
      {...props}
    >
      <Box pos={"absolute"} w={"100%"} h={"100%"} top={0} left={0}>
        <Sparkles />
      </Box>
      {lang.header.menus.create_campaign}
    </Button>
  );
};

export default CreateCampaignButton;
