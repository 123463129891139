import {
  Group,
  NumberInput,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useMemo, useState } from "react";
import { IconInfoCircle, IconLineHeight } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import FieldImportSelector from "../../../../../components/Selectors/FieldImportSelector";
import MappingSaver from "./MappingSaver";

const headerStyle = {
  size: "lg",
  w: "33%",
  fw: 600,
};

const itemStyle = {
  pt: "sm",
  justify: "space-between",
  style: {
    borderTop: "1px solid #0000000a",
  },
};

const StepMapping = ({ data, fields, setFields, defaultFields }) => {
  const lang = useLanguage((s) => s.language);

  const columns = useMemo(() => {
    return Object.keys(data[0]);
  }, [data]);

  const [lineForExample, setLineForExample] = useState(1);

  return (
    <Stack gap={"xs"} flex={"1 1 auto"} h={0} style={{ overflow: "auto" }}>
      <Group gap={4} wrap="nowrap">
        <ThemeIcon
          variant="subtle"
          opacity={0.5}
          color="black"
          p={0}
          size={"sm"}
        >
          <IconInfoCircle />
        </ThemeIcon>
        <Text size="sm">{lang.contact_lists.import.step_mapping.help}</Text>
      </Group>
      <Group justify="space-between" wrap="nowrap">
        <Text {...headerStyle}>
          {lang.contact_lists.import.step_mapping.header}
        </Text>
        <Group {...headerStyle} gap={"lg"}>
          <Text fw={600}>{lang.contact_lists.import.step_mapping.example}</Text>

          <NumberInput
            size="xs"
            leftSection={
              <Tooltip label="Ligne pour l'exemple">
                <IconLineHeight size={16} />
              </Tooltip>
            }
            value={lineForExample}
            onChange={setLineForExample}
            w={75}
            min={1}
            max={data?.length}
            clampBehavior="strict"
            allowNegative={false}
            allowDecimal={false}
            allowLeadingZeros={false}
          />
        </Group>
        <Text {...headerStyle}>
          {lang.contact_lists.import.step_mapping.attribute}
        </Text>
      </Group>
      {columns.map((column) => (
        <Group key={column} w={"100%"} {...itemStyle} wrap="nowrap">
          <Text truncate="end" w={"33%"}>
            {column}
          </Text>
          <Text truncate="end" w={"33%"}>
            {data[lineForExample - 1 || 0][column] || "-"}
          </Text>
          <FieldImportSelector
            setFields={setFields}
            fields={fields || defaultFields}
            column={column}
          />
        </Group>
      ))}
      <MappingSaver fields={fields} />
    </Stack>
  );
};

export default StepMapping;
