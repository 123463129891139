import { useState } from "react";
import Selector from "../../Selectors/Selector";
import { useLeadsStore } from "../../../stores/LeadsStore";

const FilterLazyValue = ({
  label,
  setValue,
  value,
  getter,
  responseField,
  storeKey,
  setStoreKey,
  nameField,
  groups,
}) => {
  const options = useLeadsStore((s) => s[storeKey]);
  const setOptions = useLeadsStore((s) => s[setStoreKey]);
  const [loading, setLoading] = useState(false);

  const getOptions = async () => {
    setLoading(true);
    if (groups) {
      let data = [];
      for (const group of groups) {
        let response = await group.getter();
        if (response) {
          data.push({
            label: group.name,
            options: response.data[group.responseField].map((x) => ({
              ...x,
              option_name: x[nameField] || "-",
            })),
          });
        }
      }
      setLoading(false);
      setOptions(data);
    } else
      getter()
        .then((response) => {
          setOptions([
            {
              label,
              options:
                response.data[responseField]
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((x) => ({ ...x, option_name: x[nameField] })) || [],
            },
          ]);
        })
        .finally(() => setLoading(false));
  };

  console.log(options);

  return (
    <Selector
      options={options}
      hasGroup
      placeholder={label}
      loading={loading}
      value={value === "" ? null : parseInt(value)}
      onChange={(v) => setValue(v.id.toString())}
      required
      size={"sm"}
      getOptionName={(o) => o[nameField] + " #" + o.id}
      onDropdownOpen={getOptions}
      rightOptionSection={(item) => {
        return (
          item?.id && (
            <span style={{ fontSize: 10, opacity: 0.35 }}>#{item?.id}</span>
          )
        );
      }}
      unclearable
    />
  );
};

export default FilterLazyValue;
