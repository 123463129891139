import {
  IconAlertTriangle,
  IconArchive,
  IconPlayerPause,
  IconPlayerPlay,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Center, Indicator, SegmentedControl } from "@mantine/core";

const CampaignFilter = ({ value, onChange, archivedNumber }) => {
  const lang = useLanguage((s) => s.language);
  const filters = useMemo(() => {
    return [
      {
        value: "all",
        label: (
          <Center style={{ gap: 10 }}>
            <span>{lang.statistics.filters.all}</span>
          </Center>
        ),
      },
      {
        value: "running",
        label: (
          <Center style={{ gap: 10 }}>
            <IconPlayerPlay size={18} />
            <span>{lang.statistics.filters.running}</span>
          </Center>
        ),
        color: "#0a0",
      },
      {
        value: "paused",
        label: (
          <Center style={{ gap: 10 }}>
            <IconPlayerPause size={18} />
            <span>{lang.statistics.filters.paused}</span>
          </Center>
        ),
        color: "yellow",
      },
      {
        value: "error",
        label: (
          <Center style={{ gap: 10 }}>
            <IconAlertTriangle size={18} />
            <span>{lang.statistics.filters.error}</span>
          </Center>
        ),
        color: "red",
      },
      {
        value: "archived",
        label: (
          <Center style={{ gap: 10 }}>
            <IconArchive size={18} />
            <span>{lang.statistics.filters.archived}</span>
          </Center>
        ),
        color: "purple",
      },
    ];
  }, [
    lang.statistics.filters.all,
    lang.statistics.filters.archived,
    lang.statistics.filters.error,
    lang.statistics.filters.paused,
    lang.statistics.filters.running,
  ]);

  return (
    <Indicator
      size={archivedNumber > 9 ? (archivedNumber > 99 ? 24 : 18) : 14}
      styles={{
        indicator: {
          fontSize: 8,
        },
      }}
      label={archivedNumber > 99 ? "99+" : archivedNumber}
      offset={6}
      position="top-end"
      color={"red"}
      disabled={archivedNumber === 0}
    >
      <SegmentedControl
        value={value}
        radius={"sm"}
        onChange={onChange}
        size="sm"
        color={filters.find((x) => x.value === value)?.color}
        data={filters}
      />
    </Indicator>
  );
};

export default CampaignFilter;
