import { useCallback, useState } from "react";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
  useLeadsStore,
} from "../../../../../../stores/LeadsStore";
import {
  ActionIcon,
  Anchor,
  Group,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { findProspect, updateProspect } from "../../../../../../api/PRMAPI";
import { IconBuilding, IconEdit, IconPlus } from "@tabler/icons-react";
import LeftBioSaver from "./LeftBioSaver";
import { parseProspectDatafields } from "../../../utils";

const LeadLeftBio = ({
  fieldId,
  defaultValue,
  label,
  icon,
  isLink = false,
  isTel = false,
  isMail = false,
  isOrg,
  onOrgClick,
  disableEdit,
  company,
}) => {
  const lang = useLanguage((s) => s.language);
  const currentContainer = useLeadsSheetStore((s) => s.currentContainer);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const fields = useLeadsStore((s) => s.fields);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );

  const getText = useCallback(() => {
    if (isLink || isTel || isMail) {
      return (
        <Anchor
          size="sm"
          c="inherit"
          fw={500}
          href={
            defaultValue
              ? isLink
                ? defaultValue.startsWith("https://")
                  ? defaultValue
                  : "https://" + defaultValue
                : isTel
                ? "tel:" + defaultValue
                : "mailto:" + defaultValue
              : null
          }
          target={"_blank"}
          truncate="end"
        >
          {defaultValue || "-"}
        </Anchor>
      );
    }
    if (isOrg) {
      return (
        <Anchor
          size="sm"
          fw={500}
          href={`https://www.societe.com/cgi-bin/search?champs=${defaultValue?.replaceAll(
            " ",
            "+"
          )}`}
          target={"_blank"}
          truncate="end"
        >
          {defaultValue || "-"}
        </Anchor>
      );
    }
    return (
      <Text size="sm" fw={500} truncate="end">
        {defaultValue || "-"}
      </Text>
    );
  }, [isLink, isTel, isMail, defaultValue]);

  //#region Editing
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
    setValue(defaultValue);
  };
  const handleStopEdit = () => {
    setIsEdit(false);
  };
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleSubmitEdit = () => {
    setLoading(true);
    updateProspect({
      id: current?.id,
      properties: [{ data_field_id: fieldId, value: value }],
    })
      .then((response) => {
        findProspect(current?.id).then((response) => {
          let newProperties = parseProspectDatafields(
            response.data.contact_profile
          )?.properties;

          if (currentContainer?.id === 0) {
            let contact_data = { ...current, properties: newProperties };
            contact_data.properties.forEach((prop) => {
              let current_field = fields.find(
                (x) => x.id === prop.data_field_id
              );
              if (current_field)
                contact_data = {
                  ...contact_data,
                  [current_field.identifier_placeholder]: prop.value,
                };
            });
            setCurrent(contact_data);
            setLoading(false);
            handleStopEdit();
          } else {
            updateContainerItem(currentContainer?.id, current?.id, {
              properties: newProperties,
            });
            setCurrent({ ...current, properties: newProperties });
            setLoading(false);
            handleStopEdit();
          }
        });
      })
      .catch(() => {
        setLoading(false);
        handleStopEdit();
      });
  };

  console.log(current);

  //#endregion

  return (
    <Stack
      pos="relative"
      w="100%"
      gap={0}
      className="lead-bio"
      style={{ borderBottom: "1px solid #0000000a" }}
      pb={8}
      pt={8}
    >
      {isEdit ? (
        <Stack mt={-8} w="100%" gap={8}>
          <TextInput
            label={label}
            placeholder={label}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus
            size="xs"
            leftSection={icon}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !loading) {
                handleSubmitEdit();
              }
            }}
          />
          <LeftBioSaver
            handleClose={handleStopEdit}
            handleSave={handleSubmitEdit}
            loading={loading}
          />
        </Stack>
      ) : (
        <Group wrap="nowrap" w={"100%"} gap={0}>
          {icon}

          <Stack gap={0} w={0} flex={1} ml={8}>
            {[16, 17].includes(fieldId) && company ? (
              <Anchor
                size="xs"
                href={
                  "https://www.societe.com/cgi-bin/search?champs=" +
                  company?.split(" ").join("+")
                }
                target="_blank"
                fw={500}
              >
                {label}
              </Anchor>
            ) : (
              <Text size="xs">{label}</Text>
            )}

            {getText()}
          </Stack>

          <Tooltip
            label={defaultValue ? lang.prm.details.edit : lang.prm.details.add}
          >
            <ActionIcon
              variant="subtle"
              onClick={handleEdit}
              disabled={disableEdit}
              className="on-hover"
            >
              {defaultValue ? <IconEdit size={18} /> : <IconPlus size={18} />}
            </ActionIcon>
          </Tooltip>

          {isOrg && (
            <Tooltip label={lang.prm.details.view_same_org}>
              <ActionIcon
                variant="subtle"
                onClick={onOrgClick}
                className="on-hover"
              >
                <IconBuilding size={16} />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      )}
    </Stack>
  );
};

export default LeadLeftBio;
