import { useParams } from "react-router-dom";
import {
  useLeadsContainerStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";
import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { exportPrm, exportUserPrm } from "../../../../api/PRMAPI";
import {
  Button,
  SegmentedControl,
  Select,
  Stack,
  Tooltip,
} from "@mantine/core";
import PopOver from "../../../../components/View/PopOver";
import {
  IconChevronDown,
  IconCircleArrowDownFilled,
} from "@tabler/icons-react";

const defaults = ["opener", "clicker", "answerer", "to_call"];

const ExportContent = () => {
  const lang = useLanguage((s) => s.language);
  const filter = useLeadsStore((s) => s.filter);
  const query = useLeadsStore((s) => s.query);

  const selectedIds = useLeadsContainerStore((s) => s.selectedIds);
  const unselectedIds = useLeadsContainerStore((s) => s.unselectedIds);
  const selectedColumns = useLeadsContainerStore((s) => s.selectedColumns);

  const { userId } = useParams();
  const [format, setFormat] = useState("xlsx");
  const [loading, setLoading] = useState(false);
  const [isSelection, setIsSelection] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const handleExport = (type, handleClose) => {
    setLoading(true);
    setIsSelection(type === 1);
    setIsAll(type === 0);

    const exportFunc = userId ? exportUserPrm : exportPrm;

    let mainFilterValues = [];
    let excluded = [];
    if (selectedColumns.length > 0) {
      selectedColumns.forEach((col) => {
        if (defaults.includes(col)) {
          let v = [
            {
              field_name: "status",
              type: "equals",
              value: col,
            },
            {
              field_name: "custom_status",
              type: "equals",
              value: "null",
            },
          ];

          if (query) {
            v.push({
              field_name: "any_datafield",
              type: "equals",
              value: query,
            });
          }

          if (filter) {
            v.push(filter);
          }

          mainFilterValues.push({
            mode: "and",
            values: v,
          });
        } else {
          let v = [
            {
              field_name: "custom_status",
              type: "equals",
              value: col.toString(),
            },
          ];

          if (filter) {
            v.push(filter);
          }

          mainFilterValues.push({
            mode: "and",
            values: v,
          });
        }
        if (unselectedIds[col]) {
          excluded.push(...unselectedIds[col]);
        }
      });
    }

    let parsedFilter = {
      mode: "or",
      values: mainFilterValues,
    };

    exportFunc({
      user_id: parseInt(userId),
      file_type: format,
      filter: parsedFilter,
      contact_ids: Object.values(selectedIds).flatMap((x) => x),
      excluded_contact_ids: excluded,
    })
      .then((response) => {
        handleClose();
      })
      .finally(() => {
        setLoading(false);
        setIsAll(false);
        setIsSelection(false);
      });
  };

  const canExport = () => {
    if (Object.values(selectedIds).flatMap((x) => x).length > 0) {
      return true;
    }
    if (selectedColumns.length > 0) {
      return true;
    }

    return false;
  };

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.prm.toolbar.export}>
          <Button
            ref={ref}
            className="toolbar-action-button"
            variant="light"
            color="tertiary.8"
            justify="start"
            rightSection={<IconChevronDown size={16} />}
            style={{ borderTopWidth: 1, borderTopLeftRadius: 4 }}
            onClick={handleOpen}
          >
            <IconCircleArrowDownFilled size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Stack gap={"xs"}>
          <SegmentedControl
            data={[
              {
                label: ".XLSX",
                value: "xlsx",
              },
              {
                label: ".CSV",
                value: "csv",
              },
              {
                label: ".JSON",
                value: "json",
              },
            ]}
            value={format}
            onChange={setFormat}
          />

          <Button
            loading={isSelection}
            disabled={!canExport() || loading}
            onClick={() => handleExport(1, handleClose)}
          >
            {lang.prm.toolbar.export_selected}
          </Button>

          <Button
            loading={isAll}
            disabled={loading}
            onClick={() => handleExport(0, handleClose)}
          >
            {lang.prm.toolbar.export_all}
          </Button>
        </Stack>
      )}
    />
  );
};

export default ExportContent;
