import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { Center, Loader, Stack, Text } from "@mantine/core";
import { findProspect } from "../../../../../api/PRMAPI";
import AnswerItem from "./AnswerItem";
import AnswerResponder from "./AnswerResponder";

const AnswersContent = ({
  container,
  setCurrent,
  current,
  loading,
  setLoading,
  handleClose,
  about,
  inPrm,
  index,
}) => {
  const lang = useLanguage((s) => s.language);
  const [prospect, setProspect] = useState(null);

  const items = useMemo(() => {
    let newItems = [];
    prospect?.history?.forEach((h) => {
      if (h.history_type === "reply" || h.history_type === "sent") {
        newItems.push(h);
      }
    });

    return newItems;
  }, [prospect]);

  useEffect(() => {
    setLoading(true);
    findProspect(current.id)
      .then((response) => {
        setProspect(response.data.contact_profile);
      })
      .finally(() => setLoading(false));
  }, [current.id]);

  return (
    <Stack w={"80%"} h={"75vh"} px={"sm"} gap={0}>
      <Stack
        flex={"1 1 auto"}
        style={{ overflow: "auto" }}
        pr={"lg"}
        mb={"sm"}
        gap={0}
      >
        {loading ? (
          <Center h={"calc(100% - 103px)"}>
            <Loader type="oval" size={48} />
          </Center>
        ) : items.length > 0 ? (
          <Stack h={"100%"} mb={"sm"}>
            {items.map((item, index) => (
              <AnswerItem
                key={index}
                item={item}
                type={item.type}
                about={about}
                current={prospect}
                setCurrent={setProspect}
                container={container}
              />
            ))}
          </Stack>
        ) : (
          <Text c={"dimmed"} mt={"xs"}>
            {lang.statistics.responses_viewer.no_answers}
          </Text>
        )}
      </Stack>

      <AnswerResponder
        container={container}
        current={current}
        setCurrent={setCurrent}
        handleClose={handleClose}
        inPrm={inPrm}
        index={index}
        items={items}
      />
    </Stack>
  );
};

export default AnswersContent;
