import NavbarLinkButton from "./NavbarLinkButton";
import { Group } from "@mantine/core";

export function LinksGroup({
  icon,
  name,
  path,
  rightSection,
  onClick,
  navbarClosed,
  id,
  hasLinks,
  opened,
  noNav,
  pr,
  ml,
  customIcon,
  isHorizontal,
  ...props
}) {
  return (
    <Group wrap="nowrap" gap={0} pr={pr} pos={"relative"} ml={ml}>
      <NavbarLinkButton
        opened={opened}
        closed={navbarClosed}
        hasLinks={hasLinks}
        name={name}
        path={path}
        icon={icon}
        onClick={onClick}
        id={id}
        noNav={noNav}
        customIcon={customIcon}
        isHorizontal={isHorizontal}
        {...props}
      />

      {rightSection}
    </Group>
  );
}
