import Path from "../../../../layouts/Path";
import {
  Button,
  Group,
  Stack,
  TagsInput,
  TextInput,
  Title,
} from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../components/View/BackButton";
import { createField } from "../../../../api/ContactAPI";
import PageTitle from "../../../../components/View/PageTitle";

const CreateDatafieldPage = () => {
  const lang = useLanguage((s) => s.language);
  const [possibleValues, setPossibleValues] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    createField({ name: data?.name, possible_values: possibleValues })
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message: lang.datafields.row_highlight.created,
            color: "tertiary.8",
          });
        }, 300);
        navigate(`../?highlight=${response.data.data_field_id}`);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.datafields.browser_tab_title} />
      </Group>
      <Stack
        className="layout-block"
        w={"40%"}
        mx={"auto"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Title order={5}>{lang.datafields.create.dialog.title}</Title>
        <TextInput
          name="name"
          label={lang.datafields.create.dialog.name}
          placeholder={lang.datafields.create.dialog.name}
          autoFocus
          required
        />

        <TagsInput
          label={lang.datafields.create.dialog.possible_values}
          placeholder={lang.datafields.create.dialog.possible_values}
          description={lang.datafields.create.dialog.possible_values_desc}
          value={possibleValues}
          onChange={setPossibleValues}
          onBlur={(e) => {
            let v = e.target.value;
            if (v) {
              setPossibleValues([...possibleValues, v]);
              setSearchValue("");
            }
          }}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
        />

        <Group justify="flex-end" mt={"md"}>
          <BackButton />
          <Button type="submit" loading={loading}>
            {lang.datafields.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default CreateDatafieldPage;
