import { useEffect, useState } from "react";
import CreatableSelector from "./CreatableSelector";
import { useLanguage } from "../../stores/LanguageStore";
import { createBlacklist, getBlacklists } from "../../api/BlacklistAPI";
import { useConfigStore } from "../../stores/ConfigStore";

const CreatableBlacklistSelector = ({
  value,
  setValue,
  required,
  noLabel,
  label,
  disabled,
  autoFocus,
  id,
  disableCreate,
  description,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const data = useConfigStore((s) => s.blacklists);

  useEffect(() => {
    if (loading) {
      getBlacklists().finally(() => setLoading(false));
    }
  }, [loading]);

  return (
    <CreatableSelector
      id={id}
      label={noLabel ? null : label || "Blacklist"}
      placeholder={lang.contact_lists.content.copy_selection.dialog.list}
      loading={loading}
      createLabel="Créer la liste :"
      description={
        description
          ? description
          : "Créez une nouvelle liste en entrant son nom"
      }
      data={data}
      createFunction={({ name }) => createBlacklist({ name })}
      value={value}
      setValue={setValue}
      onDropdownOpen={() => setLoading(true)}
      onNewValueCreated={() => setLoading(true)}
      created_field_id={"blacklist_id"}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      disableCreate={disableCreate}
      {...props}
    />
  );
};

export default CreatableBlacklistSelector;
