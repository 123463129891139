import { Loader, MultiSelect } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconMapPin } from "@tabler/icons-react";
import { searchLocation } from "../../../../../api/TargetingLinkedInAPI";
import { useProfileStore } from "../../../../../stores/UserStore";
import { useCallback, useState } from "react";
import { filterDeaccentedSearch } from "../../../../../functions/utilFunctions";

const defaultOptions = [
  { label: "France", value: 105015875 },
  { label: "USA", value: 103644278 },
  { label: "UK", value: 101165590 },
  { label: "Germany", value: 101282230 },
  { label: "Italie", value: 103350119 },
  { label: "Suisse", value: 106693272 },
  { label: "Luxembourg", value: 104042105 },
  { label: "Belgique", value: 100565514 },
  { label: "Pays Bas", value: 102890719 },
  { label: "Espagne", value: 105646813 },
  { label: "Maroc", value: 102787409 },
  { label: "Auvergne-Rhône-Alpes", value: 103623254 },
  { label: "Bourgogne-Franche-Comté", value: 103286073 },
  { label: "Bretagne", value: 103737322 },
  { label: "Centre-Val de Loire", value: 102215960 },
  { label: "Corse", value: 106926833 },
  { label: "Grand Est", value: 101735443 },
  { label: "Hauts-de-France", value: 105007536 },
  { label: "Ile-de-France", value: 104246759 },
  { label: "Normandie", value: 104433326 },
  { label: "Nouvelle-Aquitaine", value: 105563475 },
  { label: "Occitanie", value: 103876217 },
  { label: "Pays de la Loire", value: 104731846 },
  { label: "Provence Alpes Côte d'Azur", value: 102203735 },
];

function removeDuplicates(array) {
  // Create an empty object to keep track of unique values
  var uniqueValues = {};

  // Filter the array to keep only the objects with unique "value" property
  return array.filter(function (obj) {
    // Check if the current value is already in the uniqueValues object
    // If not, add it to the uniqueValues object and return true (to keep the object)
    if (!uniqueValues[obj.value]) {
      uniqueValues[obj.value] = true;
      return true;
    }
    // If the value already exists in uniqueValues, return false (to filter out the duplicate)
    return false;
  });
}

const LocationsSelector = ({ values, setValues, isHeadQuarters }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [options, setOptions] = useState(defaultOptions);

  const onChange = (v) => {
    setValues(v);
  };

  const [searching, setSearching] = useState(false);
  const onSearchChange = useCallback(
    (v) => {
      if (v) {
        setSearching(true);
        searchLocation(v)
          .then((res) => {
            let result = res.data.locations;
            let newOptions = [...options];

            result.forEach((x) => {
              if (!options.find((x) => x.value === x.id))
                newOptions.push({
                  label: x[profile.language === "FRA" ? "name_fr" : "name_en"],
                  value: x.id,
                });
            });

            setOptions(removeDuplicates(newOptions));
          })
          .finally(() => setSearching(false));
      }
    },
    [options, profile.language]
  );

  return (
    <MultiSelect
      label={
        lang.targeting_linkedin_google[
          isHeadQuarters ? "head_quarters" : "locations"
        ]
      }
      placeholder={lang.targeting_linkedin_google.search}
      value={values}
      onChange={onChange}
      searchable
      onSearchChange={onSearchChange}
      styles={{
        pill: {
          maxWidth: 175,
        },
        label: { fontSize: 14 },
      }}
      filter={filterDeaccentedSearch}
      rightSection={searching ? <Loader size={16} /> : undefined}
      w={"100%"}
      size="sm"
      data={options.map((x) => ({ ...x, value: x.value.toString() }))}
      leftSection={<IconMapPin size={18} />}
      clearable
    />
  );
};

export default LocationsSelector;
