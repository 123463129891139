import { TagsInput } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconBriefcase } from "@tabler/icons-react";
import { lazy, useState } from "react";
const FileImport = lazy(() => import("./FileImport"));

const TitlesSelector = ({ currentTitles, setCurrentTitles }) => {
  const lang = useLanguage((s) => s.language);
  const [searchValue, setSearchValue] = useState("");
  const onChange = (v) => {
    setCurrentTitles(v);
  };

  return (
    <TagsInput
      label={lang.targeting_linkedin_google.titles}
      placeholder={lang.tags.enter_to_add}
      value={currentTitles}
      onChange={onChange}
      onBlur={(e) => {
        let v = e.target.value;
        if (v) {
          onChange([...currentTitles, v]);
          setSearchValue("");
        }
      }}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      w={"100%"}
      size="sm"
      styles={{ label: { fontSize: 13 } }}
      leftSection={<IconBriefcase size={18} />}
      clearable
      rightSection={<FileImport setValues={setCurrentTitles} />}
    />
  );
};

export default TitlesSelector;
