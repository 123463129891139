import { useMemo, useState } from "react";
import { useAccountsStore } from "../../../../stores/ConfigStore";
import MultiSelector from "../../../../components/Selectors/MultiSelector";
import { Link } from "react-router-dom";
import { getTypeIcon } from "../../workflows/CreateCampaign/flow/flowSettings";
import { getLinkedinAccounts } from "../../../../api/EmailAPI";

function getOptionName(option) {
  return `${option.name} #${option.id}`;
}

const LinkedinAccountsSelector = ({
  label,
  placeholder,
  size,
  values,
  onChange,
  disabled,
  leftSection,
  inputHeight = 102,
  dropdownHeight = 150,
}) => {
  const accounts = useAccountsStore((s) => s.linkedin_accounts);
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    getLinkedinAccounts().finally(() => setLoading(false));
  };

  const options = useMemo(() => {
    return accounts.map((item) => ({
      ...item,
      option_name: getOptionName(item),
    }));
  }, [accounts]);

  return (
    <MultiSelector
      options={options}
      label={label}
      onDropdownOpen={handleLoad}
      loading={loading}
      values={values}
      onChange={onChange}
      placeholder={placeholder}
      leftSection={leftSection}
      leftOptionSection={(item) =>
        getTypeIcon(item.account_type, false, 18, {
          variant: "subtle",
          size: "sm",
        })
      }
      rightOptionSection={(item) => {
        return <Link to={`senders/linkedin/${item.id}`}>#{item.id}</Link>;
      }}
      size={size}
      disabled={disabled}
      w={"100%"}
      searchable
      checkIconPosition="left"
      dropdownHeight={dropdownHeight}
      styles={{
        input: {
          borderColor: "var(--mantine-primary-color-4)",
          borderWidth: 2,
          minHeight: inputHeight,
        },
      }}
      autoFocus={false}
    />
  );
};

export default LinkedinAccountsSelector;
