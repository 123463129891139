import {
  Blockquote,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import EmailsSelector from "./EmailsSelector";
import { useEffect, useMemo, useState } from "react";
import {
  IconAlertTriangle,
  IconArrowBadgeUpFilled,
  IconTrashX,
} from "@tabler/icons-react";
import {
  deleteEmail,
  deleteLinkedinAccount,
  getEmails,
  getLinkedinAccounts,
} from "../../../../api/EmailAPI";
import LinkedinAccountsSelector from "./LinkedinAccountsSelector";
import { useProfileStore } from "../../../../stores/UserStore";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import BackButton from "../../../../components/View/BackButton";

const RequiredActions = ({
  defaultOpened,
  setOpened,
  defaultEmailsToDelete,
  defaultLinkedinsToDelete,
  isError,
}) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const setProfile = useProfileStore((s) => s.setProfile);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [linkedins, setLinkedins] = useState([]);

  const emailsToDelete = useMemo(() => {
    return (
      parseInt(
        profile.actions_required?.find(
          (x) => x.action === "smtp_account_to_delete"
        )?.value
      ) ||
      defaultEmailsToDelete ||
      0
    );
  }, [defaultEmailsToDelete, profile.actions_required]);

  const linkedinAccountsToDelete = useMemo(() => {
    return (
      parseInt(
        profile.actions_required?.find(
          (x) => x.action === "linkedin_account_to_delete"
        )?.value
      ) ||
      defaultLinkedinsToDelete ||
      0
    );
  }, [defaultLinkedinsToDelete, profile.actions_required]);

  const handleEmailChange = (v) => {
    if (v.length <= emailsToDelete) {
      setEmails(v);
    }
  };
  const handleLinkedinChange = (v) => {
    if (v.length <= linkedinAccountsToDelete) {
      setLinkedins(v);
    }
  };

  useEffect(() => {
    getLinkedinAccounts();
    getEmails();
  }, []);

  const handleDelete = () => {
    let requests = [];
    setLoading(true);

    emails?.forEach((email) => {
      requests.push(
        new Promise((resolve, reject) => {
          deleteEmail({ id: email.id }).then((res) => {
            resolve();
          });
        })
      );
    });
    linkedins?.forEach((linkedin) => {
      requests.push(
        new Promise((resolve, reject) => {
          deleteLinkedinAccount({ id: linkedin.id }).then((res) => {
            resolve();
          });
        })
      );
    });

    Promise.all(requests).finally(() => {
      setLoading(false);
      setProfile({ ...profile, actions_required: [] });
    });
  };

  return (
    <Modal
      opened={setOpened ? defaultOpened : profile.actions_required.length > 0}
      size={816}
      withCloseButton={false}
      styles={{
        content: {
          boxShadow: "red 0 0 6px",
          border: "1px solid #a00",
        },
        title: {
          maxWidth: "92%",
        },
      }}
    >
      <Stack gap={"sm"} pos={"relative"}>
        <LoadingOverlay visible={loading} />
        <Text size="lg">
          {profile?.subscriptions?.trial
            ? lang.components.required_actions.text_over
            : lang.components.required_actions.text_trial}
        </Text>

        {setOpened ? (
          <Blockquote color="red" radius={"md"} py={"xs"} pl={"sm"}>
            <Group wrap="nowrap">
              <ThemeIcon color="red" variant="subtle">
                <IconAlertTriangle size={28} />
              </ThemeIcon>
              <Text c={"red"}>
                {lang.components.required_actions.reseller_text}
              </Text>
            </Group>
          </Blockquote>
        ) : (
          <Blockquote color="red" radius={"md"} py={"xs"} pl={"sm"}>
            <Group wrap="nowrap">
              <ThemeIcon color="red" variant="subtle">
                <IconAlertTriangle size={28} />
              </ThemeIcon>
              <Text c={"red"}>{lang.components.required_actions.warning}</Text>
            </Group>
          </Blockquote>
        )}

        {emailsToDelete > 0 && (
          <>
            <Text mb={-8} fw={600}>
              {lang.components.required_actions.del_email.replace(
                "#",
                emailsToDelete - emails.length
              )}
            </Text>
            <EmailsSelector
              values={emails}
              onChange={handleEmailChange}
              dropdownHeight={linkedinAccountsToDelete ? 125 : 250}
              placeholder={lang.components.required_actions.placeholder}
            />
          </>
        )}

        {linkedinAccountsToDelete > 0 && (
          <>
            <Text mb={-8} fw={600}>
              {lang.components.required_actions.del_linkedin.replace(
                "#",
                linkedinAccountsToDelete - linkedins.length
              )}
            </Text>
            <LinkedinAccountsSelector
              values={linkedins}
              onChange={handleLinkedinChange}
              dropdownHeight={emailsToDelete ? 125 : 250}
              placeholder={lang.components.required_actions.placeholder}
            />
          </>
        )}
        <Group justify="end" mt={"md"}>
          {setOpened ? (
            <BackButton onClick={() => setOpened(false)} />
          ) : (
            <Button
              variant="light"
              component={Link}
              to={"/account-settings/subscription"}
              leftSection={<IconArrowBadgeUpFilled size={18} />}
            >
              {lang.components.required_actions.upgrade}
            </Button>
          )}

          <Button
            color="red"
            loading={loading}
            leftSection={<IconTrashX size={18} />}
            onClick={handleDelete}
            disabled={
              emails.length < emailsToDelete ||
              linkedins.length < linkedinAccountsToDelete
            }
          >
            {lang.components.required_actions.confirm}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default RequiredActions;
