import { Group, Stack } from "@mantine/core";
import { useCallback, useEffect, useMemo } from "react";
import TargetingLinkedin from "../import/linkedin/TargetingLinkedin";
import ManualImport from "../import/manualImport/ManualImport";
import { notifications } from "@mantine/notifications";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import CreateEmptyList, { listParentRoute } from "./CreateEmptyList";
import { useListStore } from "../../../../stores/ListStore";
import TargetingDatabase from "../import/database/TargetingDatabase";
import PageTitle from "../../../../components/View/PageTitle";

const ImportListPage = () => {
  const lang = useLanguage((s) => s.language);
  const isChild = useListStore((s) => s.isChild);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isChild = Boolean(params.get("isChild"));

    if (isChild) {
      useListStore.getState().setIsChild(isChild);
    }
  }, []);

  const { method } = useParams();
  const listProfile = useRouteLoaderData("contact-list");

  const navigate = useNavigate();
  const handleImportFinish = useCallback(
    (listId) => {
      if (isChild) {
        window.opener.postMessage(listId, "*");
        setTimeout(() => {
          window.close();
        }, 500);
        return window.open("", window.opener.name).focus();
      }

      if (method !== "empty")
        notifications.show({
          title: lang.contact_lists.import.success,
          message: lang.contact_lists.import.success_text,
        });

      navigate(
        listProfile
          ? listParentRoute + `/${listId}`
          : listParentRoute + `/?highlight=${listId}`
      );
    },
    [isChild, listProfile, method, navigate]
  );

  const component = useMemo(() => {
    if (method === "empty") {
      return <CreateEmptyList onFinish={handleImportFinish} />;
    }
    if (method === "file" || method === "copy") {
      return (
        <ManualImport
          onFinish={handleImportFinish}
          isCopyPaste={method === "copy"}
          listId={listProfile ? listProfile.id : null}
        />
      );
    }
    if (method === "database") {
      return <TargetingDatabase onFinish={handleImportFinish} />;
    }
    return (
      <TargetingLinkedin
        onFinish={handleImportFinish}
        defaultMethod={method}
        listId={listProfile ? listProfile.id : null}
      />
    );
  }, [method, handleImportFinish, listProfile]);

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.contact_lists.import.button_label} />
      </Group>
      <Stack
        className="layout-block"
        px={"xs"}
        flex={1}
        style={{ overflow: "auto" }}
      >
        {component}
      </Stack>
    </>
  );
};

export default ImportListPage;
