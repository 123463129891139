import { Box, Group, ThemeIcon, UnstyledButton, rem } from "@mantine/core";
import { IconChevronDown, IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import classes from "./NavbarLinksGroup.module.css";
import { Link, useMatches } from "react-router-dom";
import { forwardRef } from "react";

const NavbarLinkButton = forwardRef(
  (
    {
      hasLinks,
      icon: Icon,
      path,
      opened,
      closed,
      name,
      noNav,
      customIcon,
      isHorizontal,
      ...others
    },
    ref
  ) => {
    const matches = useMatches();

    return (
      <UnstyledButton
        {...others}
        component={noNav ? undefined : Link}
        to={path}
        className={classes.control}
        ref={ref}
        py="xs"
        px="md"
        pl={16}
        style={{
          color: matches.find((x) => x.pathname.includes(path))
            ? "var(--mantine-primary-color-filled)"
            : "var(--mantine-color-dark-3)",
          // borderLeft: "6px solid",
          // borderLeftColor: matches.find((x) => x.pathname.includes(path))
          //   ? "var(--mantine-primary-color-filled)"
          //   : "transparent",
          background: matches.find((x) => x.pathname.includes(path))
            ? "var(--mantine-color-primary-0)"
            : "transparent",
        }}
      >
        <Group justify="space-between" gap={0} wrap="nowrap">
          <Box style={{ display: "flex", alignItems: "center" }}>
            {customIcon || (
              <ThemeIcon
                variant={"filled"}
                size={28}
                color={
                  matches.find((x) => x.pathname.includes(path))
                    ? "primary"
                    : "gray.2"
                }
              >
                <Icon
                  style={{
                    width: rem(20),
                    height: rem(20),
                    color: matches.find((x) => x.pathname.includes(path))
                      ? "white"
                      : "var(--mantine-color-gray-6)",
                  }}
                />
              </ThemeIcon>
            )}
            {!closed && (
              <Box
                ml="md"
                fw={matches.find((x) => x.pathname.includes(path)) ? 650 : 550}
              >
                {name}
              </Box>
            )}
          </Box>
          {hasLinks &&
            !closed &&
            (isHorizontal ? (
              <IconChevronDown
                className={classes.chevron}
                stroke={1.5}
                style={{
                  marginLeft: 8,
                  width: rem(16),
                  height: rem(16),
                  transform: opened ? "rotate(-90deg)" : "none",
                }}
              />
            ) : (
              <IconChevronRight
                className={classes.chevron}
                stroke={1.5}
                style={{
                  marginRight: -8,
                  width: rem(16),
                  height: rem(16),
                  transform: opened ? "rotate(-90deg)" : "none",
                }}
              />
            ))}
        </Group>
      </UnstyledButton>
    );
  }
);

NavbarLinkButton.displayName = "NavbarLinkButton";

export default NavbarLinkButton;
