import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";

const CreateLinkedinSender = () => {
  const { poolId } = useParams();
  const lang = useLanguage((s) => s.language);
  const route = poolId
    ? `/senders/linkedin/pool/${poolId}/create`
    : `/senders/linkedin/create`;

  return (
    <Button
      leftSection={<IconPlus size={18} />}
      radius={"sm"}
      color="primary"
      component={Link}
      to={route}
    >
      {lang.senders.linkedin.add_account}
    </Button>
  );
};

export default CreateLinkedinSender;
