import {
  Anchor,
  Flex,
  Group,
  HoverCard,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import { useLeadsSheetStore } from "../../../../../../../stores/LeadsStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import numberColumn from "../../../../../../../layouts/Table/columns/numberColumn";
import { parseDate } from "../../../../../../../functions/dates";
import { status_list } from "../../../../utils";
import MainTable from "../../../../../../../layouts/Table/MainTable";
import { IconCircleFilled, IconListDetails } from "@tabler/icons-react";
import ScoreCell from "./ScoreCell";

const ListDisplay = ({ lists }) => {
  return (
    <HoverCard openDelay={300} position="top" offset={2} withArrow>
      <HoverCard.Target>
        <ThemeIcon size={"sm"} variant="light" color="secondary" ml={"auto"}>
          <IconListDetails size={18} />
        </ThemeIcon>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Stack>
          {lists.length > 0 ? (
            lists?.map((list) => (
              <Group key={list.contact_list_id} gap={"xs"}>
                <IconCircleFilled size={6} color="black" />
                <Anchor
                  size="sm"
                  target="_blank"
                  fw={600}
                  href={`/contact-lists/${list.id}`}
                  c={"secondary"}
                >
                  {list.name} #{list.id}
                </Anchor>
              </Group>
            ))
          ) : (
            <Text c={"dimmed"}>Aucune liste</Text>
          )}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const ProspectCampaigns = () => {
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const apiRef = useGridApiRef();
  const rows = useMemo(() => {
    return current?.programmations || [];
  }, [current]);

  const columns = useMemo(
    () => [
      numberColumn("programmation_id"),
      {
        field: "workflow_id",
        headerName: lang.prm.details.campaigns_table.workflow,
        flex: 1,
        minWidth: 150,
        renderCell: ({ row, value }) =>
          value ? (
            <Group wrap="nowrap">
              <Anchor
                href={`/workflows/edit/${row?.workflow_id}`}
                c="inherit"
                fontSize={12}
                fontWeight={500}
                target="_blank"
                truncate="end"
              >
                {row?.workflow_name} #{row?.workflow_id}
              </Anchor>

              <ListDisplay lists={row.contact_lists} />
            </Group>
          ) : (
            "-"
          ),
      },
      {
        field: "status",
        headerName: lang.prm.details.campaigns_table.status,
        width: 150,
        valueGetter: ({ value }) => {
          return status_list()?.find((x) => x.value === value)?.label || "-";
        },
      },
      {
        field: "date_start",
        headerName: lang.prm.details.campaigns_table.date_start,
        width: 200,
        valueGetter: ({ value }) => (value ? parseDate(value) : "-"),
      },
      {
        field: "score",
        headerName: lang.prm.details.campaigns_table.score,
        width: 320,
        renderCell: ({ row }) => <ScoreCell row={row} />,
      },
    ],
    [lang]
  );

  return (
    <Stack gap={0} h={"100%"} style={{ overflow: "auto" }} w={"100%"}>
      <Flex flex={1} bg={"white"}>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          id={"reminder-table"}
          getRowId={(row) =>
            row?.programmation_contact_id ||
            row?.contact_list_contact_id + row?.contact_list_id
          }
        />
      </Flex>
    </Stack>
  );
};

export default ProspectCampaigns;
