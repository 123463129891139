import { Group, Input, Select } from "@mantine/core";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import {
  dayOptions,
  getDelay,
  getTimeOptions,
  hourOptions,
  minuteOptions,
} from "./events";
import { useCallback, useMemo, useState } from "react";

const DelaySelector = ({ id, when_minutes }) => {
  const updateNodeData = useFlowStore((s) => s.updateNodeData);
  const [timeType, setTimeType] = useState(getDelay(when_minutes).type);
  const [timeValue, setTimeValue] = useState(getDelay(when_minutes).value);
  const lang = useLanguage((s) => s.language);

  const handleDelayTypeChange = (newType) => {
    setTimeType(newType);
    if (newType === "1") handleTimeChange(newType, dayOptions()[0]);
    else if (newType === "2") handleTimeChange(newType, hourOptions()[0]);
    else if (newType === "3") handleTimeChange(newType, minuteOptions()[0]);
  };

  const timeValueOptions = useMemo(() => {
    if (timeType === "1") {
      return dayOptions();
    }
    if (timeType === "2") {
      return hourOptions();
    }
    if (timeType === "3") {
      return minuteOptions();
    }
  }, [timeType]);

  const handleTimeChange = useCallback(
    (type, value) => {
      let newWhenMinutes = 0;
      if (type === "1") {
        setTimeValue(value || dayOptions[0]);
        newWhenMinutes = parseInt(value.split(" ")[0]) * 24 * 60;
      } else if (type === "2") {
        setTimeValue(value || hourOptions[0]);
        newWhenMinutes = parseInt(value.split(" ")[0]) * 60;
      } else if (type === "3") {
        setTimeValue(value || minuteOptions[0]);
        newWhenMinutes = parseInt(value.split(" ")[0]);
      }

      updateNodeData(id, { when_minutes: newWhenMinutes });
    },
    [id, updateNodeData]
  );

  return (
    <>
      <Input.Label size="xs" mt={0}>
        {lang.workflows.nodes.events.delay.name}
      </Input.Label>
      <Group mt={0} wrap="nowrap" gap={4}>
        <Select
          value={getDelay(when_minutes).type}
          onChange={handleDelayTypeChange}
          size="xs"
          data={getTimeOptions()}
          w={timeType !== "4" ? 110 : "100%"}
          variant="filled"
          comboboxProps={{
            size: "sm",
            width: 140,
          }}
          searchable
        />
        <Select
          value={timeValue}
          onChange={(v) => {
            setTimeValue(v);
            handleTimeChange(timeType, v);
          }}
          size="xs"
          required
          variant="filled"
          data={timeValueOptions}
          w={135}
          comboboxProps={{
            size: "sm",
          }}
          searchable
        />
      </Group>
    </>
  );
};

export default DelaySelector;
