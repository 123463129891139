import { Stack, Text } from "@mantine/core";
import Path from "../../layouts/Path";

const PageTitle = ({ title, maw }) => {
  document.title = ` ${title}`;
  return (
    <Stack gap={8} maw={maw || "60%"}>
      <Text size="xl" fw={600} truncate>
        {title}
      </Text>
      <Path />
    </Stack>
  );
};

export default PageTitle;
