import { IconFileImport, IconList, IconUserBolt } from "@tabler/icons-react";
import { IconGmaps } from "../../../components/Icons/IconGmaps";
import { IconLinkedin } from "../../../components/Icons/IconLinkedin";
import { IconMagileads } from "../../../components/Icons/IconMagileads";
import { IconSalesNavigator } from "../../../components/Icons/IconSalesNavigator";
import { useLanguage } from "../../../stores/LanguageStore";
import { useSessionStore } from "../../../stores/UserStore";

const icon_props = { style: { height: 28 } };

export function getListMethod(listId) {
  const lang = useLanguage.getState().language;
  const reseller = useSessionStore.getState().reseller;

  let result = [
    {
      icon: <IconUserBolt {...icon_props} />,
      title: lang.contact_lists.import.methods.quick.label,
      text: lang.contact_lists.import.methods.quick.text,
      value: "quick",
      isLinkedin: true,
      isPopular: true,
    },
    {
      icon: <IconGmaps {...icon_props} />,
      title: lang.contact_lists.import.methods.maps.label,
      text: lang.contact_lists.import.methods.maps.text,
      value: "google",
      isPopular: true,
      permission: "displayTargetingGoogle",
    },
    {
      icon: (
        <IconMagileads
          {...icon_props}
          style={{ height: 28, paddingBottom: 6 }}
        />
      ),
      title: lang.contact_lists.import.methods.database.label + reseller?.name,
      text: lang.contact_lists.import.methods.database.text + reseller?.name,
      value: "database",
      isBeta: true,
      permission: "displayTargetingDatabase",
    },
    {
      icon: <IconSalesNavigator {...icon_props} />,
      title: lang.contact_lists.import.methods.sales.label,
      text: lang.contact_lists.import.methods.sales.text,
      value: "linkedin",
      isLinkedin: true,
      isPopular: true,
    },
    {
      icon: <IconSalesNavigator {...icon_props} />,
      title: lang.contact_lists.import.methods.linkedin_companies.label,
      text: lang.contact_lists.import.methods.linkedin_companies.text,
      value: "companies",
      isLinkedin: true,
    },
    {
      icon: <IconLinkedin {...icon_props} />,
      title: lang.contact_lists.import.methods.linkedin_people.label,
      text: lang.contact_lists.import.methods.linkedin_people.text,
      value: "linkedin",
      isLinkedin: true,
    },
    {
      icon: <IconLinkedin {...icon_props} />,
      title: lang.contact_lists.import.methods.linkedin_likers.label,
      text: lang.contact_lists.import.methods.linkedin_likers.text,
      value: "likers",
      isLinkedin: true,
    },
    {
      icon: <IconLinkedin {...icon_props} />,
      title: lang.contact_lists.import.methods.linkedin_commenters.label,
      text: lang.contact_lists.import.methods.linkedin_commenters.text,
      value: "commenters",
      isLinkedin: true,
    },
    {
      icon: <IconLinkedin {...icon_props} />,
      title: lang.contact_lists.import.methods.linkedin_jobs.label,
      text: lang.contact_lists.import.methods.linkedin_jobs.text,
      value: "jobs",
      isLinkedin: true,
    },
    {
      icon: <IconLinkedin {...icon_props} />,
      title: lang.contact_lists.import.methods.linkedin_connections.label,
      text: lang.contact_lists.import.methods.linkedin_connections.text,
      value: "connections",
      isLinkedin: true,
    },
    {
      icon: <IconFileImport {...icon_props} />,
      title: lang.contact_lists.import.methods.manual.label,
      text: lang.contact_lists.import.methods.manual.text,
      value: "file",
    },
  ];

  if (!listId) {
    result.push({
      icon: <IconList {...icon_props} />,
      title: lang.contact_lists.import.methods.empty.label,
      text: lang.contact_lists.import.methods.empty.text,
      value: "empty",
    });
  }

  return result;
}

export function getChangedKeys(obj1, obj2) {
  const changedKeys = [];

  // Get all keys from both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check for keys that are present in obj1 but not in obj2
  for (let key of keys1) {
    if (!(key in obj2)) {
      changedKeys.push(key);
    } else if (obj1[key] !== obj2[key]) {
      changedKeys.push(key);
    }
  }

  // Check for keys that are present in obj2 but not in obj1
  for (let key of keys2) {
    if (!(key in obj1)) {
      changedKeys.push(key);
    }
  }

  return changedKeys;
}
