import { lazy, useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { Button, Group, LoadingOverlay, Stack, Stepper } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import StepMapping from "./StepMapping";
import MappingStats from "./MappingStats";

import system_map from "./default_system_fields.json";
import StepImportSettings from "./StepImportSettings";
import CreateEmailSender from "../CreateEmailSender";
import Path from "../../../../../layouts/Path";
import { createSMTP } from "../../../../../api/EmailAPI";
import { useNavigate } from "react-router-dom";
import { defaultSMTPConfigs } from "../../../tools/emails/components/defaultConfigs";

const StepImportFile = lazy(() => import("./StepImportFile"));

const ImportSmtp = ({ onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const [activeStep, setActiveStep] = useState(1);

  const defaultFields = useMemo(() => {
    let result = {};

    Object.keys(system_map).forEach((key) => {
      system_map[key].forEach((value) => {
        result[value] = key;
      });
    });

    return result;
  }, []);

  const options = useMemo(
    () => [
      {
        label: "Nom d'expéditeur",
        value: "sender",
      },
      {
        label: "Email",
        value: "email",
      },
      {
        label: "Mot de passe",
        value: "password",
      },
      {
        label: "Serveur SMTP",
        value: "smtp_host",
      },
      {
        label: "Port SMTP",
        value: "smtp_port",
      },
      {
        label: "Serveur IMAP",
        value: "imap_host",
      },
      {
        label: "Port IMAP",
        value: "imap_port",
      },
    ],
    []
  );

  const handleNext = useCallback(() => {
    setActiveStep((prev) => prev + 1);
  }, []);

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [fields, setFields] = useState(defaultFields);
  const [importing, setImporting] = useState(false);

  const getNextDisabled = () => {
    if (activeStep === 1) {
      return !file || data?.length === 0;
    }
    if (activeStep === 2) {
      let statFields = {};
      Object.keys(fields || {}).forEach((field) => {
        if (Object.keys(data?.[0]).includes(field)) {
          statFields = { ...statFields, [field]: fields[field] };
        }
      });
      return Object.values(statFields).filter((x) => !!x).length === 0;
    }
    return false;
  };

  const navigate = useNavigate();
  const handleImport = useCallback(
    async ({ tlsSmtp, tlsImap }) => {
      const parsedFields = {};
      Object.keys(data[0]).forEach((key) => {
        parsedFields[key] = options.find((x) => x.value === fields[key])?.value;
      });

      let emails = data.map((contact) => {
        let props = {};
        Object.keys(contact).forEach((column) => {
          if (parsedFields[column] && contact[column]) {
            props[parsedFields[column]] = contact[column];
          }
        });

        return props;
      });

      // Divide requests
      async function mainFunction() {
        let requests = [];
        const numberOfUploads = emails.length;

        const importFunction = (slicedData) => {
          let parsedData = {
            ...defaultSMTPConfigs,
            name: slicedData.sender,
            sender: slicedData.sender,
            email: slicedData.email,
            reply_to: [slicedData.email],
            smtp_username: slicedData.email,
            smtp_password: slicedData.password,
            smtp_host: slicedData.smtp_host,
            smtp_port: parseInt(slicedData.smtp_port),
            smtp_secure: tlsSmtp ? "tls" : "ssl",
            imap_username: slicedData.email,
            imap_password: slicedData.password,
            imap_host: slicedData.imap_host,
            imap_port: parseInt(slicedData.imap_port),
            imap_secure: tlsImap ? "tls" : "ssl",
          };

          return createSMTP(parsedData);
        };

        for (let i = 0; i < numberOfUploads; i++) {
          requests.push(
            new Promise((resolve, reject) =>
              importFunction(emails[i])
                .then((res) => resolve(res))
                .catch((err) => reject(err))
            )
          );
        }

        setImporting(true);
        return Promise.allSettled(requests)
          .then((values) => {
            console.log(values);
            if (!values.find((x) => x.status === "rejected")) {
              navigate("/senders/email");
            }
          })
          .finally(() => {
            setImporting(false);
          });
      }

      return mainFunction();
    },
    [data, fields, navigate, options]
  );

  const getStepComponent = useCallback(() => {
    if (activeStep === 1) {
      return (
        <StepImportFile
          file={file}
          setFile={setFile}
          data={data}
          setData={setData}
        />
      );
    }
    if (activeStep === 2) {
      return (
        <StepMapping
          data={data}
          fields={fields}
          setFields={setFields}
          options={options}
        />
      );
    }
    if (activeStep === 3) {
      return (
        <StepImportSettings
          file={file}
          data={data}
          fields={fields}
          handlePrev={handlePrev}
          handleImport={handleImport}
          setImporting={setImporting}
          onFinish={onFinish}
        />
      );
    }
  }, [activeStep, file, data, fields, options, handleImport, onFinish]);

  return (
    <>
      <Group className="layout-block top" justify="space-between">
        <Path />

        <CreateEmailSender />
      </Group>

      <Stack flex={1} pos={"relative"} w={"100%"} className="layout-block">
        <LoadingOverlay visible={importing} overlayProps={{ blur: 1 }} />
        <Stepper active={activeStep - 1} iconSize={24} w={"95%"}>
          <Stepper.Step
            label={lang.contact_lists.import.steps_title.file}
            description={
              file?.name || lang.contact_lists.import.steps_title.file_text
            }
            styles={{
              stepDescription: {
                maxWidth: 210,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
            }}
          />
          <Stepper.Step
            label={lang.contact_lists.import.steps_title.mapping}
            description={lang.contact_lists.import.steps_title.mapping_text}
          />
          <Stepper.Step
            label={lang.contact_lists.import.steps_title.importing}
            description={lang.contact_lists.import.steps_title.importing_text}
          />
        </Stepper>

        {getStepComponent()}

        {activeStep !== 3 && (
          <Group mt={"auto"}>
            {activeStep === 2 && <MappingStats fields={fields} data={data} />}
            <Group ml={"auto"}>
              {activeStep !== 1 && (
                <Button
                  variant="subtle"
                  leftSection={<IconChevronLeft />}
                  onClick={handlePrev}
                >
                  {lang.global.back}
                </Button>
              )}
              <Button
                rightSection={<IconChevronRight />}
                onClick={handleNext}
                disabled={getNextDisabled()}
              >
                {lang.global.next}
              </Button>
            </Group>
          </Group>
        )}
      </Stack>
    </>
  );
};

export default ImportSmtp;
