import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import { ActionIcon, Group, Tooltip } from "@mantine/core";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import MainTable from "../../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import PageTitle from "../../../../components/View/PageTitle";
import { deleteAIKey, editAIKey, getAIKeys } from "../../../../api/ConfigsApi";
import { CommCode } from "../../community/CommCode";
import CreateAiKey from "./CreateAiKey";
import { IconEdit } from "@tabler/icons-react";
import { Link } from "react-router-dom";

const AIKeyListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: () => `/extra/openai-api-keys`,
        editFunction: () => editAIKey,
        getDisabled: (row) => row.created_by === null,
        apiRef,
      }),
      {
        field: "api_key",
        headerName: lang.api_keys.columns.key,
        width: 450,
        renderCell: ({ value }) => {
          return <CommCode token={value} />;
        },
      },
      dateColumn(),
    ],
    [lang, apiRef]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={"xs"}>
          <Tooltip label={lang.global.edit}>
            <ActionIcon component={Link} to={`${row.id}`} variant="subtle">
              <IconEdit size={18} />
            </ActionIcon>
          </Tooltip>
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteAIKey}
            deletedMessage={lang.openai_keys.delete_success}
            id={row.id}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.openai_keys.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <SelectionDeleter
        handleDelete={deleteAIKey}
        apiRef={apiRef}
        setTableLoading={setLoading}
        ids={selectedIds}
      />
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getAIKeys,
    responseField: "openai_api_keys_list",
    setRows,
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.openai_keys.browser_tab_title} />

        <CreateAiKey setTableLoading={setLoading} />
      </Group>

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          actionsCellWidth={75}
        />
      </TableLayoutBlock>
    </>
  );
};

export default AIKeyListing;
