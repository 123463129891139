import { Avatar, Button, Group, Menu, Stack, Text } from "@mantine/core";
import {
  IconAffiliate,
  IconBrandOpenai,
  IconBuildingCommunity,
  IconChevronDown,
  IconColumns,
  IconFiles,
  IconKey,
  IconLink,
  IconLock,
  IconLogout,
  IconTag,
  IconTools,
  IconUser,
  IconUsersGroup,
} from "@tabler/icons-react";
import { useProfileStore, useSessionStore } from "../../stores/UserStore";
import { useMemo, useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { Link, useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import HeaderTools from "./HeaderTools";
import HeaderStats from "./HeaderStats";

const iconSize = 21;

const HeaderProfile = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const [opened, setOpened] = useState(false);

  const links = useMemo(() => {
    return [
      [
        {
          icon: <IconUser size={iconSize} />,
          name: lang.header.menus.link_groups.profile,
          url: "/account-settings/profile",
        },
        {
          icon: <IconUsersGroup size={iconSize} />,
          name: lang.header.menus.link_groups.teams,
          url: "/community/teams",
        },
        {
          icon: <IconBuildingCommunity size={iconSize} />,
          name: lang.header.menus.link_groups.organizations,
          url: "/community/organizations",
        },
        {
          icon: <IconTools size={iconSize} />,
          name: lang.header.menus.link_groups.tools,
          children: [
            {
              icon: <IconFiles size={iconSize} />,
              name: lang.header.menus.link_groups.files,
              url: "/extra/files",
            },
            {
              icon: <IconLink size={iconSize} />,
              name: lang.header.menus.link_groups.url_shortener,
              url: "/extra/url-shortener",
            },
            {
              icon: <IconTag size={iconSize} />,
              name: lang.header.menus.link_groups.tags,
              url: "/extra/tags",
            },
            {
              icon: <IconColumns size={iconSize} />,
              name: lang.header.menus.link_groups.datafields,
              url: "/extra/datafields",
            },
            {
              icon: <IconBrandOpenai size={iconSize} />,
              name: lang.header.menus.link_groups.openai_keys,
              url: "/extra/openai-api-keys",
            },
          ],
        },
      ],
      [
        {
          icon: <IconAffiliate size={iconSize} />,
          name: lang.header.menus.link_groups.referral,
          url: "/resellers/ref",
        },
        {
          icon: <IconLock size={iconSize} />,
          name: lang.header.menus.link_groups.admin,
          url: "/resellers/admin",
        },
        {
          icon: <IconKey size={iconSize} />,
          name: lang.header.menus.link_groups.api_keys,
          url: "/api",
        },
      ],
    ];
  }, [lang]);

  const navigate = useNavigate();
  const handleLogout = () => {
    function main() {
      let s = useSessionStore.getState();
      s.setUserSwitch(null);
      s.setSession(null);
      let api_url = localStorage.getItem("api_url");

      localStorage.clear();

      setTimeout(() => {
        let p = useProfileStore.getState();
        p.setProfile(null);
        navigate("/login?logged_out=true");

        localStorage.setItem("api_url", api_url);
      }, 50);
    }

    modals.openConfirmModal({
      title: "Se déconnecter ?",
      children: (
        <Text>Êtes-vous sûr de vouloir déconnecter de la plateforme ?</Text>
      ),
      labels: {
        confirm: lang.header.menus.logout,
        cancel: lang.components.navigation_blocker.rest,
      },
      confirmProps: { color: "red" },
      onConfirm: main,
      onCancel: () => modals.closeAll(),
    });
  };

  return (
    <Menu
      opened={opened}
      onChange={setOpened}
      offset={-4}
      withArrow
      width={250}
    >
      <Menu.Target>
        <Button
          rightSection={<IconChevronDown size={16} />}
          variant="subtle"
          px={12}
          size="lg"
          color="white"
        >
          <Group gap={4}>
            <Avatar src={profile?.avatar} size={40} color="white" />
            <Stack ml={"sm"} ta={"start"} gap={0} mr={8}>
              <Text fw={600} truncate="end" flex={"1 1 auto"} c={"white"}>
                {profile?.first_name} {profile?.last_name}
              </Text>
              <Text opacity={0.5} fz={"sm"} c={"white"}>
                #{profile?.id}
              </Text>
            </Stack>
          </Group>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <HeaderStats />
        {links.map((group, index) => (
          <Stack key={index} gap={0}>
            {group.map((item, i) => {
              if (item.children) {
                return <HeaderTools key={i} item={item} />;
              }
              return (
                <Menu.Item
                  leftSection={item.icon}
                  component={Link}
                  to={item.url}
                  key={item.url}
                  fz={13}
                >
                  <Group ml={8}>{item.name}</Group>
                </Menu.Item>
              );
            })}
            {index < links.length - 1 && <Menu.Divider />}
          </Stack>
        ))}
        <Menu.Divider />
        <Menu.Item
          leftSection={<IconLogout />}
          onClick={handleLogout}
          color="red"
        >
          <Group ml={5} fw={500}>
            {lang.header.menus.logout}
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default HeaderProfile;
