import { useState } from "react";
import { forgotPwd } from "../../../api/AuthAPI";
import { useLanguage } from "../../../stores/LanguageStore";
import {
  Button,
  Group,
  Radio,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconMail } from "@tabler/icons-react";
import BackButton from "../../../components/View/BackButton";

const PR1 = ({ handleNext }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    let formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    console.log(data);
    forgotPwd(data)
      .then((response) => {
        handleNext();
      })
      .catch((error) => {
        if (error.response.data.state_message === "incorrect_email") {
          setError(true);
          document.getElementById("forget-email").focus();
        }
        if (error.response.data.state_message === "empty_email") {
          setError(true);
          document.getElementById("forget-email").focus();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack component="form" onSubmit={handleSubmit} gap={"xs"}>
      <Title order={5}>{lang.password_recovery.step_verify.title}</Title>
      <Text mt={8}>{lang.password_recovery.step_verify.text}</Text>

      <TextInput
        type="email"
        name="email"
        id="forget-email"
        label={lang.password_recovery.step_verify.mail}
        placeholder={lang.password_recovery.step_verify.mail}
        leftSection={<IconMail size={18} />}
        error={error}
        helperText={error && lang.errors.helper_texts.incorrectEmail}
        required
        size="md"
      />

      <Text fw={500} ta={"left"} mt={8}>
        {lang.password_recovery.step_verify.method}
      </Text>

      <Radio.Group name="method" defaultValue={"email"}>
        <Group direction={"row"} alignItems={"center"}>
          <Radio
            value={"email"}
            defaultChecked
            label={lang.password_recovery.step_verify.method_email}
          />

          <Radio
            value={"sms"}
            label={lang.password_recovery.step_verify.method_sms}
          />
        </Group>
      </Radio.Group>

      <Button type="submit" loading={loading} w="100%" mt={"md"}>
        {lang.password_recovery.step_verify.button_label}
      </Button>
      <BackButton url={"/login"} />
    </Stack>
  );
};

export default PR1;
