import React, { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  Button,
  Checkbox,
  Group,
  Select,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import CodeEditor from "../../../../../components/ExternalEditors/CodeEditor";
import { IconChevronRight, IconInfoCircle } from "@tabler/icons-react";

const separators = [";", ",", "\t"];

const StepCopyPaste = ({
  pastedText,
  setPastedText,
  separator,
  setSeparator,
  consent,
  setConsent,
  setData,
  handleNext,
  isUnsub,
  isBlacklist,
}) => {
  const lang = useLanguage((s) => s.language);
  const separatorOptions = useMemo(
    () => [
      {
        value: "0",
        label: lang.contact_lists.import.step_copy_paste.separator_semicolon,
      },
      {
        value: "1",
        label: lang.contact_lists.import.step_copy_paste.separator_coma,
      },
      {
        value: "2",
        label: lang.contact_lists.import.step_copy_paste.separator_tab,
      },
    ],
    [lang]
  );
  const [text, setText] = useState(pastedText);
  const [separatorState, setSeparatorState] = useState(separator);

  const verifyNext = useCallback(() => {
    let lines = text.split(/\n/);
    let arr = [];
    for (var i = 0; i < lines.length; i++) {
      if (lines[i]) arr.push(lines[i]);
    }

    if (isUnsub) {
      setPastedText(text);
      setData(lines.map((x) => ({ value: x })));
      setSeparator(separatorState);
      return handleNext();
    }

    let columns = [];
    arr[0]
      .trim()
      .split(separators[separatorState])
      .forEach((a) => {
        if (a.length) {
          columns.push(a);
        }
      });
    arr.splice(0, 1);

    let result = arr.map((line) => {
      let lineArr = line.split(separators[separatorState]);
      let temp = {};
      columns.forEach((col, i) => {
        temp = {
          ...temp,
          [col]: lineArr[i]?.trim(),
        };
      });
      return temp;
    });

    setPastedText(text);
    setData(result);
    setSeparator(separatorState);
    handleNext();
  }, [text, separatorState, setPastedText, setSeparator, setData, handleNext]);

  const getNextDisabled = () => {
    if (isUnsub) {
      return text.trim().length <= 1;
    }
    return (
      (text.split(/\n/).length > 1 ? text.split(/\n/)[1].length < 1 : true) ||
      !consent
    );
  };

  return (
    <Stack flex={"1 1 auto"} gap={0} h={"100%"} w={"100%"}>
      <button
        id="copy-paste-verifier"
        onClick={verifyNext}
        style={{ display: "none" }}
      />
      <Group gap={4}>
        <ThemeIcon
          variant="subtle"
          opacity={0.5}
          color="black"
          p={0}
          size={"sm"}
        >
          <IconInfoCircle />
        </ThemeIcon>
        {isUnsub ? (
          <Text size="sm">
            Un email - domaine - SIREN/SIRET - Code NAF - Lien Linkedin - Raison
            sociale par ligne
          </Text>
        ) : (
          <Text size="sm">
            La première ligne sera utilisée pour le nom des colonnes
          </Text>
        )}
      </Group>

      {isUnsub ? (
        <Select
          label={lang.contact_lists.import.step_copy_paste.separator}
          w={150}
          value={"line"}
          data={[{ label: "Saut de ligne", value: "line" }]}
          mt={"xs"}
          radius={0}
          disabled
        />
      ) : (
        <Select
          label={lang.contact_lists.import.step_copy_paste.separator}
          w={150}
          value={separatorState}
          onChange={setSeparatorState}
          data={separatorOptions}
          mt={"xs"}
          radius={0}
        />
      )}
      <CodeEditor
        text={text}
        setText={setText}
        placeholder={
          isUnsub
            ? lang.unsubscribers.import.step_config.copy_placeholder
            : lang.contact_lists.import.step_copy_paste.editor_placeholder
        }
      />
      {!isUnsub && !isBlacklist && (
        <Checkbox
          mt={"xs"}
          size="sm"
          label={lang.contact_lists.import.confirm_text}
          checked={consent}
          onChange={(e) => setConsent(e.target.checked)}
        />
      )}
      <Group mt={"sm"} ml={"auto"}>
        <Button
          rightSection={<IconChevronRight />}
          onClick={verifyNext}
          disabled={getNextDisabled()}
        >
          {lang.global.next}
        </Button>
      </Group>
    </Stack>
  );
};

export default StepCopyPaste;
