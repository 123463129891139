import { Button, Stack, Text, Tooltip } from "@mantine/core";
import PopOver from "../View/PopOver";
import { IconChevronDown, IconTrash } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { transformFilterToServer } from "../../functions/utilFunctions";
import { modals } from "@mantine/modals";

const TableFilterDelete = ({
  isGlobal,
  handleDelete,
  id,
  apiRef,
  onFinish,
  selectedIds,
  filter,
  filterResultNumber,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(null);

  const main = (type, handleClose) => {
    setLoading(type);
    return handleDelete({
      id: id,
      ids: type === "selection_inverse" ? [] : selectedIds || [],
      excluded_ids: type === "selection_inverse" ? selectedIds : [],
      filter:
        type === "filter"
          ? transformFilterToServer(filter)
          : { mode: "or", values: [] },
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.delete_contacts.success,
          color: "tertiary.8",
        });

        if (apiRef && type !== "selection_inverse") {
          apiRef.current.setRowSelectionModel([]);

          selectedIds.forEach((id) => {
            document.querySelectorAll(`[data-id='${id}']`).forEach((el) => {
              el.style.animation = `forwards 1.5s deletedAnim ease`;
            });
          });
          setTimeout(() => {
            apiRef.current.updateRows(
              selectedIds.map((id) => ({ id, _action: "delete" }))
            );
          }, 750);
        }

        onFinish?.(type);
        handleClose();
      })
      .finally(() => {
        setLoading(null);
      });
  };

  const handleModal = (type, handleClose) => {
    const getLabel = () => {
      if (type === "selection_inverse") {
        return {
          text: "Tout sauf la séléction",
          number: filterResultNumber - selectedIds.length,
        };
      }
      if (type === "filter") {
        return {
          text: "Tous les résultats du filtre",
          number: filterResultNumber,
        };
      }
      if (type === "selection") {
        return {
          text: "Tous les séléctionnés",
          number: selectedIds.length,
        };
      }
      return {
        text: "Tous",
        number: 0,
      };
    };

    modals.openConfirmModal({
      title: <Text>Attention</Text>,
      children: (
        <Text fw={600} c="red">
          Cette suppression sera définitive, vous ne pourrez pas recupérer ces
          contacts:{" "}
          <b>
            {getLabel().text} ({getLabel().number} contacts)
          </b>
        </Text>
      ),
      labels: {
        confirm: "Supprimer",
        cancel: "Annuler",
      },
      confirmProps: {
        color: "red",
        leftSection: <IconTrash size={18} />,
      },
      onConfirm: () => {
        main(type, handleClose);
      },
      onCancel: () => {},
      zIndex: 2000,
    });
  };

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.contact_lists.delete_contacts.tooltip}>
          <Button
            className="toolbar-action-button"
            variant="light"
            color="red"
            ref={ref}
            disabled={selectedIds?.length === 0 && filter?.items?.length === 0}
            rightSection={<IconChevronDown size={16} />}
            onClick={handleOpen}
          >
            <IconTrash size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Stack gap={"xs"}>
          {!isGlobal && (
            <Tooltip
              label={lang.contact_lists.delete_contacts.no_filter}
              disabled={filter?.items?.length > 0}
            >
              <Button
                loading={loading === "filter"}
                onClick={() => handleModal("filter", handleClose)}
                disabled={!filter || filter?.items?.length < 1}
                variant="subtle"
                size="sm"
                color="red"
              >
                {lang.contact_lists.delete_contacts.delete_filter}{" "}
                {filter?.items?.length > 0 ? `( ${filterResultNumber} )` : ""}
              </Button>
            </Tooltip>
          )}
          <Tooltip
            label={lang.contact_lists.delete_contacts.no_selection}
            disabled={selectedIds?.length > 0}
          >
            <Button
              loading={loading === "selection"}
              onClick={() => handleModal("selection", handleClose)}
              disabled={!selectedIds || selectedIds?.length < 1}
              variant="subtle"
              size="sm"
              color="red"
            >
              {lang.contact_lists.delete_contacts.delete_selection}{" "}
              {selectedIds?.length > 0 ? `( ${selectedIds.length} )` : ""}
            </Button>
          </Tooltip>
          {!isGlobal && (
            <Tooltip
              label={lang.contact_lists.delete_contacts.no_selection}
              disabled={selectedIds?.length > 0}
            >
              <Button
                loading={loading === "selection_inverse"}
                onClick={() => handleModal("selection_inverse", handleClose)}
                disabled={!selectedIds || selectedIds?.length < 1}
                variant="subtle"
                size="sm"
                color="red"
              >
                {lang.contact_lists.delete_contacts.delete_all_but_selection}{" "}
                {selectedIds?.length > 0
                  ? `( ${filterResultNumber - selectedIds.length} )`
                  : ""}
              </Button>
            </Tooltip>
          )}
        </Stack>
      )}
    />
  );
};

export default TableFilterDelete;
