import { Select } from "@mantine/core";

const FilterValueSelector = ({ options, value, setValue, disabled, label }) => {
  return (
    <Select
      data={options}
      value={value}
      onChange={(v) => setValue(v)}
      clearable={false}
      disabled={disabled}
      placeholder={label}
      autoFocus
    />
  );
};

export default FilterValueSelector;
