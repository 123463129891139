import {
  Button,
  Group,
  Modal,
  NumberFormatter,
  NumberInput,
  Radio,
  Stack,
  Text,
  TextInput,
  Timeline,
  TimelineItem,
} from "@mantine/core";
import { useState } from "react";
import { extractResults } from "../../../../../api/TargetingDatabaseAPI";
import BackButton from "../../../../../components/View/BackButton";
import GlobalLanguageSelector from "../../../../../components/Selectors/GlobalLanguageSelector";
import { IconListDetails, IconSparkles } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../../stores/UserStore";
import CreatableContactListSelector from "../../../../../components/Selectors/CreatableContactListSelector";
import { notifications } from "@mantine/notifications";

const DatabaseModal = ({ handleClose, filters, listProfile, count }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [extracting, setExtracting] = useState(false);

  const [list, setList] = useState(listProfile);
  const [listState, setListState] = useState(listProfile ? 2 : 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    let f = new FormData(e.target);
    let data = Object.fromEntries(f);

    let emptyFilter = [{ field: "linkedin_url", exists: true }];
    data = {
      ...data,
      contact_list_name: listState === 1 ? data?.contact_list_name : null,
      contact_list_id: listState === 2 ? list?.id : null,
      max_results: parseInt(data?.max_results),
      filters: filters.length > 0 ? filters : emptyFilter,
    };

    setExtracting(true);
    extractResults(data)
      .then((response) => {
        handleClose();
        notifications.show({ message: "Import lancé", color: "tertiary.6" });
        // onFinish?.(response.data.contact_list_id);
      })
      .finally(() => setExtracting(false));
  };

  return (
    <Modal
      opened
      onClose={handleClose}
      title={lang.database.modal.title}
      size={650}
    >
      <Stack
        mih={listProfile ? 250 : 350}
        pos={"relative"}
        component={"form"}
        onSubmit={handleSubmit}
        id="submit-form"
      >
        <Text size="lg">
          {lang.database.modal.text}{" "}
          <b>
            <NumberFormatter value={count || " - "} thousandSeparator=" " />{" "}
            {lang.database.contacts}
          </b>{" "}
          {lang.database.found}
        </Text>

        <Timeline
          styles={{
            item: {
              marginBottom: -12,
            },
            itemContent: {
              marginLeft: 8,
            },
            itemBullet: {
              top: 6,
            },
          }}
          lineWidth={2}
          mb={16}
          bulletSize={12}
          color="tertiary.8"
        >
          <TimelineItem>
            <Group wrap="nowrap" gap={"xl"}>
              <NumberInput
                name="max_results"
                label={lang.database.modal.max}
                required
                min={1}
                max={profile?.level === "admin" ? 100000 : 10000}
                clampBehavior="strict"
                description={
                  profile?.level === "admin" ? "max: 100 000" : "max: 10 000"
                }
                defaultValue={10000}
                w="100%"
                allowNegative={false}
                allowDecimal={false}
              />
              <Group w={"100%"}></Group>
            </Group>
          </TimelineItem>

          <TimelineItem>
            <Group w={"100%"} wrap="nowrap" gap={"xl"}>
              <GlobalLanguageSelector
                defaultValue={
                  listProfile?.language || profile?.contact_list_language
                }
                name={"contact_list_language"}
                size="sm"
                w="100%"
              />
              <Stack w={"100%"}></Stack>
              {/* <CountrySelector
                defaultValue={
                  listProfile?.country || profile?.contact_list_country
                }
                name={"contact_list_country"}
                required
                w="100%"
              /> */}
            </Group>
          </TimelineItem>

          <TimelineItem>
            <Group wrap="nowrap" align="end" gap={"xl"}>
              <Stack w="100%" gap={8}>
                <Radio
                  label={lang.targeting_linkedin_google.create_new_list}
                  name="list_state"
                  checked={listState === 1}
                  size="sm"
                  onChange={(e) => setListState(1)}
                />
                <TextInput
                  id="new-list-name"
                  name="contact_list_name"
                  w={"100%"}
                  disabled={listState !== 1}
                  styles={{ label: { fontSize: 13 } }}
                  label={lang.targeting_linkedin_google.list_name}
                  placeholder={lang.targeting_linkedin_google.list_name}
                  description={lang.contact_lists.create.dialog.desc}
                  required={listState === 1}
                />
              </Stack>
              <Stack w="100%" gap={8}>
                <Radio
                  label={lang.targeting_linkedin_google.import}
                  name="list_state"
                  size="sm"
                  checked={listState === 2}
                  onChange={(e) => setListState(2)}
                />
                <CreatableContactListSelector
                  value={list}
                  setValue={setList}
                  label={lang.targeting_linkedin_google.select_list}
                  placeholder={lang.contact_lists.create.dialog.desc}
                  w={"100%"}
                  disabled={listState !== 2}
                  styles={{ label: { fontSize: 13 } }}
                  required={listState === 2}
                  leftSection={<IconListDetails size={16} />}
                  disableCreate
                  description={lang.contact_lists.create.dialog.desc}
                />
              </Stack>
            </Group>
          </TimelineItem>
        </Timeline>
      </Stack>

      <Group justify="end" mt={"md"}>
        <BackButton onClick={handleClose} />

        <Button
          variant="gradient"
          leftSection={<IconSparkles />}
          loading={extracting}
          type="submit"
          px={32}
          // disabled={filters?.length === 0}
          form="submit-form"
        >
          {
            lang.targeting_linkedin_google[
              listProfile ? "submit_button_add" : "submit_button"
            ]
          }
        </Button>
      </Group>
    </Modal>
  );
};

export default DatabaseModal;
