import {
  Box,
  CloseButton,
  Divider,
  Group,
  Paper,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useEffect, useMemo, useRef } from "react";
import { getNodeDetails } from "../../flowSettings";
import NodePanelAdvanced from "./NodePanelAdvanced";
import NodePanelMain from "./NodePanelMain";

const NodeActionPanel = ({ reactFlowInstance }) => {
  const nodes = useFlowStore((s) => s.nodes);
  const selectedNode = useFlowStore((s) => s.selectedNode);
  const setSelectedNode = useFlowStore((s) => s.setSelectedNode);

  const actualNode = useMemo(() => {
    return nodes.find((x) => x.id === selectedNode?.id);
  }, [selectedNode, nodes]);

  const nodeDetail = useMemo(
    () => getNodeDetails(actualNode?.data.actionType),
    [actualNode]
  );

  const handleClose = () => {
    setSelectedNode(null);

    setTimeout(() => {
      let actual = reactFlowInstance?.getViewport();
      reactFlowInstance?.setViewport(
        { x: actual.x + 250, y: actual.y },
        { duration: 500 }
      );
    }, 201);
  };

  useEffect(() => {
    let n = nodes.find((x) => x.id === selectedNode?.id);
    if (!n) {
      setSelectedNode(null);
    }
  }, [nodes, selectedNode, setSelectedNode]);

  const paper = useRef(null);

  useEffect(() => {
    if (paper?.current) {
      if (selectedNode?.toAdvanced) {
        const offsetTop = document.getElementById(
          "panel-advaced-settings"
        ).offsetTop;

        setTimeout(() => {
          if (paper?.current) paper.current.scrollTop = offsetTop;
        }, 200);
      } else {
        setTimeout(() => {
          if (paper?.current) paper.current.scrollTop = 0;
        }, 200);
      }
    }
  }, [selectedNode?.toAdvanced, paper]);

  return (
    <Paper
      w={actualNode ? "90%" : 0}
      mr={actualNode ? 0 : nodes.length > 0 ? "-90%" : 0}
      opacity={actualNode ? 1 : 0}
      style={{
        transition: "all ease .2s",
      }}
      withBorder
      pos={"relative"}
    >
      <CloseButton
        onClick={handleClose}
        pos={"absolute"}
        top={0}
        left={-42}
        size={"md"}
      />
      <Box
        h={"100%"}
        p={"sm"}
        style={{ overflow: "auto", scrollBehavior: "smooth" }}
        ref={paper}
      >
        <Group w={"100%"}>
          <ThemeIcon variant="light" color={nodeDetail?.color} size={"lg"}>
            {nodeDetail?.icon}
          </ThemeIcon>
          <Text fz={"lg"}>{nodeDetail?.label}</Text>
          <Text ml="auto" opacity={0.35}>
            #{actualNode?.id}
          </Text>
        </Group>
        <Divider my={"sm"} />
        <NodePanelMain />
        {actualNode?.data?.source !== "0" && (
          <NodePanelAdvanced node={actualNode} key={actualNode?.id} />
        )}
      </Box>
    </Paper>
  );
};

export default NodeActionPanel;
