import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { handleLayout } from "../layouter";
import { useDisclosure } from "@mantine/hooks";
import { getOutgoers } from "reactflow";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import DontShowAgain from "../../../../../../components/View/DontShowAgain";
import { useProfileStore } from "../../../../../../stores/UserStore";

function deleteNodeAndChildren(graph, nodeID) {
  // Recursive function to find and delete children nodes
  function deleteChildren(currentNodeID) {
    console.log(graph.nodes);
    // Find edges connected to the current node
    const connectedEdges = graph.edges.filter(
      (edge) => edge.source === currentNodeID
    );

    // Recursively delete children nodes
    connectedEdges.forEach((edge) => {
      let targetNode = graph.nodes.find((x) => x.id === edge.target);
      console.log(targetNode);  
      const nextNodeID = Array.isArray(targetNode?.data?.source)
        ? null
        : edge.target;
      if (nextNodeID) deleteChildren(nextNodeID);
    });

    // Delete the current node and connected edges
    graph.nodes = graph.nodes.filter((node) => node.id !== currentNodeID);
    graph.edges = graph.edges.filter((edge) => edge.source !== currentNodeID);
  }

  // Start the deletion process with the specified nodeID
  deleteChildren(nodeID);
}

export function getNodeIdsOfChildren(edges, nodeID, nodes) {
  const childNodeIds = [];

  // Recursive function to find and collect children node IDs
  function collectChildrenIds(currentNodeID) {
    // Find edges connected to the current node
    const connectedEdges = edges.filter(
      (edge) => edge.source === currentNodeID
    );

    // Collect the ID of the current node
    childNodeIds.push(currentNodeID);

    // Recursively collect children node IDs
    connectedEdges.forEach((edge) => {
      let targetNode = nodes.find((x) => x.id === edge.target);
      const nextNodeID = Array.isArray(targetNode?.data?.source)
        ? null
        : edge.target;

      if (nextNodeID) collectChildrenIds(nextNodeID);
    });
  }

  // Start the collection process with the specified nodeID
  collectChildrenIds(nodeID);

  return childNodeIds;
}

function showChildren({ nodes, edges, id }) {
  let childIds = getNodeIdsOfChildren(edges, id, nodes).filter(
    (x) => !x?.includes("trail")
  );

  console.log(childIds);

  childIds.forEach((id) => {
    let dom_element = document.querySelector(`[data-id="${id}"]`);
    dom_element.querySelector(`.action-node`).classList.add("to-delete");
  });
}

const NodeDeleter = ({ id, actionType, source, outgoers, disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { close, open }] = useDisclosure(false);

  const handleDeletion = () => {
    const nodes = useFlowStore.getState().nodes;
    const edges = useFlowStore.getState().edges;
    const setNodes = useFlowStore.getState().setNodes;
    const setEdges = useFlowStore.getState().setEdges;
    const updateNodeData = useFlowStore.getState().updateNodeData;

    let newEdges = [...edges];
    let newNodes = [...nodes];
    let child = nodes.find((x) => x.data.source === id);
    let grandChild = nodes.find((x) => x.data.source === child?.id);
    const graph = { nodes, edges };

    // If has 2 branch, delete every children
    if (outgoers.length > 1) {
      deleteNodeAndChildren(graph, id);
      newEdges = graph.edges.filter((x) => x.target !== id);
      newNodes = graph.nodes;
    }

    if (child) {
      let childOutgoers = getOutgoers(child, nodes, edges);
      if (childOutgoers.length > 1) {
        deleteNodeAndChildren(graph, child?.id);
        newEdges = graph.edges.filter((x) => x.target !== child?.id);
        newNodes = graph.nodes;
      }
    }

    newEdges = newEdges.filter(
      (x) => x.target !== id && x.source !== id && !x.id.includes("trail")
    );
    newNodes = newNodes.filter((x) => x.id !== id && !x.id.includes("trail"));

    // If Event node
    newNodes = newNodes.filter((x) => x.id !== child?.id);
    newEdges = newEdges.filter((x) => x.source !== child?.id);

    if (grandChild) {
      if (grandChild?.type !== "trail") {
        newEdges.push({
          id: `e-${source}-${grandChild.id}`,
          source: source,
          target: grandChild.id,
          data: { fromEvent: actionType !== "event" },
        });
      }
      updateNodeData(grandChild?.id, { source });
    }

    let childLinked = nodes.find(
      (x) => Array.isArray(x.data.source) && x.data.source?.includes(id)
    );
    let grandChildLinked = nodes.find(
      (x) => Array.isArray(x.data.source) && x.data.source?.includes(child?.id)
    );
    if (childLinked) {
      let newChildSource = childLinked.data.source?.filter((x) => x !== id);
      // console.log();
      updateNodeData(childLinked?.id, {
        source:
          newChildSource.length === 1 ? newChildSource[0] : newChildSource,
      });
    }
    if (grandChildLinked) {
      let newChildSource = grandChildLinked.data.source?.filter(
        (x) => x !== child?.id
      );
      // console.log();
      updateNodeData(grandChildLinked?.id, {
        source:
          newChildSource.length === 1 ? newChildSource[0] : newChildSource,
      });
    }

    setEdges(newEdges.filter((x) => !x.id.includes("trail")));
    setNodes(newNodes.filter((x) => !x.id.includes("trail")));

    close();
    setTimeout(() => {
      handleLayout();
      // setTimeout(() => {
      //   let nodes = useFlowStore.getState().nodes;
      //   let n = nodes.find((x) =>
      //     source === "0" ? x.data.source === "0" : x.id === source
      //   );
      //   if (n) reactFlow.fitView({ nodes: [n], duration: 1000 });
      // }, 100);
    }, 10);
  };

  const onMouseEnter = () => {
    const nodes = useFlowStore.getState().nodes;
    const edges = useFlowStore.getState().edges;
    document
      .querySelector(`[data-id="${id}"] .action-node`)
      .classList.add("to-delete");
    if (outgoers.length > 1) {
      showChildren({ nodes, edges, id });
    }

    if (outgoers.length === 1 || source === "0") {
      let child = nodes.find((x) => x.data.source === id);

      if (child) {
        let childOutgoers = getOutgoers(child, nodes, edges);

        if (childOutgoers.length > 1) {
          showChildren({ edges, id: child?.id, nodes });
        }
        return document
          .querySelector(`[data-id="${child?.id}"] .action-node`)
          ?.classList.add("to-delete");
      }
    }
  };

  const onMouseLeave = () => {
    document.querySelectorAll(`.to-delete`).forEach((element) => {
      element.classList.remove("to-delete");
    });
  };

  return (
    <>
      <Tooltip label={lang.workflows.cant_delete} disabled={!disabled}>
        <ActionIcon
          variant="subtle"
          pos={"absolute"}
          right={4}
          top={4}
          onClick={(e) => {
            e.stopPropagation();

            if (
              useProfileStore.getState().profile.dont_show_values?.step_delete
            )
              open();
            else {
              handleDeletion();
            }
          }}
          size={"sm"}
          color="red"
          className="on-hover-opacity"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          disabled={disabled}
        >
          <IconX size={16} />
        </ActionIcon>
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={lang.campaign.nodes.deletion.title}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
          centered
        >
          {outgoers?.length > 1 ? (
            <Text>
              {lang.campaign.nodes.deletion.text1}{" "}
              <b>{lang.campaign.nodes.deletion.text2}</b>{" "}
              {lang.campaign.nodes.deletion.text3}
            </Text>
          ) : (
            <Text>
              {lang.campaign.nodes.deletion.text1}{" "}
              <b>{lang.campaign.nodes.deletion.text4}</b>{" "}
              {lang.campaign.nodes.deletion.text5}
            </Text>
          )}
          <Group justify="space-between" mt={"sm"}>
            <DontShowAgain />
            <Button
              color="red"
              //   leftSection={<IconTrash size={18} />}
              onClick={(e) => {
                e.stopPropagation();
                handleDeletion();
              }}
            >
              {lang.campaign.nodes.deletion.confirm}
            </Button>
          </Group>
        </Modal>
      )}
    </>
  );
};

export default NodeDeleter;
