import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  Group,
  Image,
  NumberInput,
  Paper,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { DateTimePicker, TimeInput } from "@mantine/dates";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CampaignAdvancedSettings from "./CampaignAdvancedSettings";
import campaignIcon from "../../../../../assets/campaignSettings.svg";
import { defaultProgramData } from "../flow/flowSettings";
import { IconChevronRight, IconSettings, IconShare } from "@tabler/icons-react";
import { useFlowStore } from "../../../../../stores/WorkflowStore";
import { useForm } from "react-hook-form";
import SharingController from "../../../../../components/Sharing/SharingController";
import CampaignSharer from "./CampaignSharer";

const ProgramPanel = ({
  programData,
  handleProgramConfigs,
  isPast,
  isReprog,
}) => {
  const lang = useLanguage((s) => s.language);

  const sharing = useFlowStore((s) => s.sharing);
  const setSharing = useFlowStore((s) => s.setSharing);

  const sharingForm = useForm({
    defaultValues: {
      sharing: sharing?.map((x) => ({
        ...x,
        sharing_targets: x.sharing_targets.map((sharing_target) => ({
          ...sharing_target,
          option_name: sharing_target.name,
        })),
      })),
    },
  });

  const handleSharing = (e) => {
    const sharing = sharingForm
      .getValues()
      .sharing.filter((x) => x.sharing_targets.length > 0)
      .map((share) => ({
        ...share,
        ids_list: share.sharing_targets.map((x) => x.id),
        sharing_targets: share.sharing_targets,
      }));

    setSharing(sharing);
  };

  return (
    <Paper
      p={"sm"}
      w={"75%"}
      component="form"
      id="program-form"
      onSubmit={handleProgramConfigs}
      mx={"auto"}
      style={{
        overflow: "auto",
        boxShadow: "#00000044 4px -2px 16px",
        zIndex: 100,
      }}
      withBorder
    >
      <Group w={"50%"} wrap="nowrap">
        <Image src={campaignIcon} w={42} alt="CampaignIcon" />
        <Text fz={"lg"} fw={500}>
          {lang.campaign.preview.title}
        </Text>
      </Group>
      <Divider my={"sm"} />

      <Stack gap={"lg"} mb={"xl"}>
        <Tooltip label={lang.campaign.already_programmed} disabled={!isPast}>
          <DateTimePicker
            dropdownType="modal"
            label={lang.workflows.program.step_config.date_start}
            defaultValue={
              isReprog
                ? new Date()
                : programData?.date_start
                ? new Date(programData.date_start)
                : new Date()
            }
            name="date_start"
            size="md"
            required
            disabled={isPast}
          />
        </Tooltip>

        <TimeInput
          label={lang.workflows.program.step_config.time_start_sending}
          name="time_start_sending"
          size="md"
          w={"100%"}
          required
          defaultValue={
            programData?.time_start_sending
              ? programData.time_start_sending
              : defaultProgramData.time_start_sending
          }
          withSeconds
        />
        <NumberInput
          label={lang.workflows.program.step_config.daily_send_limit}
          description={lang.workflows.program.step_config.daily_send_limit_0}
          name="daily_send_limit"
          defaultValue={
            programData?.daily_send_limit
              ? programData.daily_send_limit
              : defaultProgramData.daily_send_limit
          }
          min={0}
          size="md"
        />
      </Stack>

      <Accordion defaultValue={null} variant="contained" mb={16}>
        <Accordion.Item value={"advanced"}>
          <Accordion.Control
            bg={"var(--mantine-color-gray-1)"}
            icon={<IconSettings size={16} />}
            px={"xs"}
          >
            {lang.campaign.nodes.advanced_settings.label}
          </Accordion.Control>
          <Accordion.Panel>
            <CampaignAdvancedSettings
              programData={programData}
              isPast={isPast}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Accordion variant="contained">
        <Accordion.Item value={"sharing"}>
          <Accordion.Control
            icon={<IconShare size={16} />}
            px={"xs"}
            bg={"var(--mantine-color-gray-1)"}
          >
            {lang.components.sharing.title}
          </Accordion.Control>
          <Accordion.Panel>
            <CampaignSharer />
            <SharingController
              form={sharingForm}
              max={1}
              disablePermissions={true}
              disableDelete
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Group mt={"xl"} justify="end">
        <Button
          variant="light"
          size="md"
          rightSection={<IconChevronRight />}
          disabled={programData?.contactlist_ids?.length === 0}
          w={lang.id === "FRA" ? 130 : 120}
          justify="flex-end"
          radius={"sm"}
          id="programmation-config-submitter"
          type="submit"
          onClick={handleSharing}
        >
          {lang.global.next}
        </Button>
      </Group>
    </Paper>
  );
};

export default ProgramPanel;
