import {
  ActionIcon,
  Group,
  Indicator,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconMessage } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import AnswersLeft from "./AnswersLeft";
import AnswersContent from "./AnswersContent";

const Answers = ({ container, index, disabled, inSheet, inPrm }) => {
  const lang = useLanguage((s) => s.language);
  const [current, setCurrent] = useState(container?.items[index]);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const about = useMemo(() => {
    const aboutList = [
      { key: "%last_name%", value: "lastName" },
      { key: "%first_name%", value: "firstName" },
      { key: "%job_title%", value: "jobTitle" },
      { key: "%company%", value: "company" },
      { key: "%email%", value: "email" },
    ];
    let result = {};
    aboutList.forEach((item) => {
      result[item.value] =
        current?.properties?.find((x) => x.identifier_placeholder === item.key)
          ?.value || " - ";
    });

    return result;
  }, [current]);

  const handleOpen = () => {
    open()
    setCurrent(container?.items[index])
  }

  return (
    <>
      <Tooltip
        label={
          inSheet
            ? lang.prm.details.history.view_response
            : `${current?.number_of_replies || " 0 "} ${
                lang.statistics.responses_viewer.answer
              }${(current?.number_of_replies || 0) > 1 ? "s" : ""}`
        }
      >
        <Indicator
          color="red"
          inline
          offset={2}
          disabled={
            inSheet ? true : disabled ? true : current?.number_of_replies === 0
          }
          styles={{
            indicator: {
              width: 8,
              height: 8,
              minWidth: 0,
            },
          }}
          size={16}
        >
          <ActionIcon
            size={inSheet ? "sm" : "xs"}
            variant="subtle"
            color="primary"
            className="lead-item-button"
            disabled={disabled}
            onClick={handleOpen}
          >
            <IconMessage size={inSheet ? 18 : 14} />
          </ActionIcon>
        </Indicator>
      </Tooltip>

      {opened && (
        <Modal
          title={
            <Text truncate="end">
              {lang.statistics.responses_viewer.answers_of}{" "}
              <b>
                {about.firstName + " " + about.lastName} #{current.id}{" "}
                {current["%email%"] ? `(${current["%email%"]})` : ""}
              </b>
            </Text>
          }
          opened={opened}
          onClose={close}
          size={"100%"}
        >
          <Group w={"100%"} gap={0} align="start">
            <AnswersLeft
              container={container}
              index={index}
              current={current}
              setCurrent={setCurrent}
              loading={loading}
            />
            <AnswersContent
              container={container}
              setCurrent={setCurrent}
              loading={loading}
              current={current}
              setLoading={setLoading}
              handleClose={close}
              about={about}
              inPrm={inPrm}
              index={index}
            />
          </Group>
        </Modal>
      )}
    </>
  );
};

export default Answers;
