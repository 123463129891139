import { Group, Stack } from "@mantine/core";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import CreateEmail from "./CreateModel";
import EditModelEmail from "./emails/EditModelEmail";
import { useCallback } from "react";
import EditModelLinkedin from "./linkedin/EditModelLinkedin";
import PageTitle from "../../../components/View/PageTitle";

const ModelPageEdit = ({ loaderId }) => {
  const { folderId, modelType } = useParams();
  const modelProfile = useRouteLoaderData(loaderId);
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (folderId) {
      navigate(`/models/folder/${folderId}`);
    } else {
      navigate(`/models/${modelType}`);
    }
  }, []);

  const onEdited = useCallback(
    () =>
      navigate(
        `/models${
          folderId ? `/folder/${folderId}` : `/${modelType}`
        }/?highlight=${modelProfile?.id}`
      ),
    [folderId, modelProfile?.id, modelType, navigate]
  );

  const getComponent = useCallback(() => {
    if (modelType === "email") {
      return (
        <EditModelEmail
          model={modelProfile}
          onEdited={onEdited}
          handleClose={handleBack}
        />
      );
    }
    if (modelType === "linkedin-invitation") {
      return (
        <EditModelLinkedin
          type={"linkedin_invitation"}
          model={modelProfile}
          onEdited={onEdited}
          handleClose={handleBack}
        />
      );
    }
    if (modelType === "linkedin-message") {
      return (
        <EditModelLinkedin
          type={"linkedin_message"}
          model={modelProfile}
          onEdited={onEdited}
          handleClose={handleBack}
          maxChars={null}
        />
      );
    }
    if (modelType === "sms") {
      return (
        <EditModelLinkedin
          type={"sms"}
          model={modelProfile}
          onEdited={onEdited}
          handleClose={handleBack}
          maxChars={160}
        />
      );
    }
  }, [handleBack, modelProfile, modelType, onEdited]);

  return (
    <>
      <Group className="layout-block top" justify="space-between">
        <PageTitle title={modelProfile?.name} />

        <CreateEmail variant="light" />
      </Group>

      <Stack
        mx={"auto"}
        className="layout-block"
        flex={modelProfile?.is_ai ? false : 1}
        px={"sm"}
        pt={"sm"}
        gap={"xs"}
        w={modelProfile?.is_ai ? "60%" : undefined}
      >
        {getComponent()}
      </Stack>
    </>
  );
};

export default ModelPageEdit;
