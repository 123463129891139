import {
  Anchor,
  Box,
  Group,
  HoverCard,
  Indicator,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CampaignColumnChildCell from "./CampaignColumnChildCell";
import {
  IconAlertTriangle,
  IconCircleMinus,
  IconInfoCircle,
  IconMessageForward,
} from "@tabler/icons-react";
import { parseDate } from "../../../../../functions/dates";

function convertIsoDateToLocaleString(inputString) {
  // Regular expression to match ISO date pattern
  const isoDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/;

  // Find the ISO date in the input string
  const isoDateMatch = inputString.match(isoDateRegex);

  if (isoDateMatch) {
    // Extract the ISO date from the match
    const isoDate = isoDateMatch[0];

    // Create a Date object from the ISO date string
    const dateObj = new Date(isoDate);

    // Convert the date to a locale-specific string
    const localeDateString = parseDate(dateObj);

    const resultString = inputString.replace(isoDateRegex, `: ${localeDateString}`);

    return resultString;
  }
  return null;
}

function getLevelColor(level) {
  if (level === "error") {
    return "red";
  }
  if (level === "warning") {
    return "orange";
  }
  if (level === "critical") {
    return "purple";
  }
  return "gray";
}

function filterStepsWithTypeDetails(program, type) {
  const criticalSteps = [];

  program?.steps?.forEach((step) => {
    const hasCriticalDetail = step.details.some(
      (detail) => detail.level === type
    );
    if (hasCriticalDetail) {
      criticalSteps.push(step);
    }
  });

  return criticalSteps;
}

const ChildCell = ({ step }) => {
  return (
    <CampaignColumnChildCell>
      <Group w={"100%"} pos={"relative"}>
        <Tooltip
          label={
            step?.details?.length > 0
              ? convertIsoDateToLocaleString(step?.details[0]?.message) ||
                step?.details[0]?.message
              : null
          }
        >
          <Anchor
            size="sm"
            maw={"90%"}
            truncate="end"
            fw={500}
            style={{
              cursor: step.details[0]?.integration_id ? "pointer" : "text",
            }}
            c={getLevelColor(step?.details[0]?.level)}
            underline={step.details[0]?.integration_id ? "hover" : "never"}
            href={(() => {
              if (step.details[0]?.integration_id) {
                let parentLink = "";
                if (step.action_type === "email") parentLink = "email";
                else parentLink = "linkedin";

                return `/senders/${parentLink}/?highlight=${step.details[0]?.integration_id}&message=${step.details[0]?.message}`;
              }
              return null;
            })()}
          >
            {step?.details?.length > 0
              ? convertIsoDateToLocaleString(step?.details[0]?.message) ||
                step?.details[0]?.message
              : "-"}
          </Anchor>
        </Tooltip>
        {step?.details?.length > 1 && (
          <HoverCard withArrow position="top" offset={2} openDelay={300}>
            <HoverCard.Target>
              <Box pos="absolute" right={0}>
                <Text fz={15} fw={600}>
                  +{step.details.length - 1}
                </Text>
              </Box>
            </HoverCard.Target>

            <HoverCard.Dropdown>
              <Stack mah={170} gap={"xs"} maw={200}>
                {step.details.slice(1).map((detail, i) => (
                  <Anchor
                    key={i}
                    fontSize={12}
                    size="sm"
                    style={{
                      cursor: detail?.integration_id ? "pointer" : "text",
                    }}
                    underline={detail?.integration_id ? "hover" : "never"}
                    href={(() => {
                      if (detail?.integration_id) {
                        let parentLink = "";
                        if (step.action_type === "email") parentLink = "email";
                        else parentLink = "linkedin";

                        return `/senders/${parentLink}/?highlight=${detail.integration_id}&message=${detail.message}`;
                      }
                      return null;
                    })()}
                  >
                    -{" "}
                    {convertIsoDateToLocaleString(detail?.message) ||
                      detail?.message ||
                      ""}
                  </Anchor>
                ))}
              </Stack>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </Group>
    </CampaignColumnChildCell>
  );
};

const DetailsItem = ({ field, icon, type, color }) => {
  const detailMapped =
    field?.length > 0
      ? field?.flatMap((x) => {
          let e = x.details.filter((detail) => detail.level === type);
          return e.map((detail) => ({
            ...detail,
            stepId: x.id,
            isInitial: x?.is_initial,
            type: x?.action_type,
          }));
        })
      : [];

  return (
    <HoverCard
      disabled={detailMapped?.length === 0}
      withArrow
      offset={2}
      position="top"
      openDelay={300}
    >
      <HoverCard.Target>
        <Indicator
          disabled={detailMapped?.length === 0}
          color={color}
          offset={4}
          size={
            detailMapped?.length > 9
              ? detailMapped?.length > 99
                ? 24
                : 18
              : 12
          }
          styles={{
            indicator: {
              fontSize: 8,
              height: 12,
            },
          }}
          label={detailMapped?.length > 99 ? "99+" : detailMapped?.length}
        >
          <ThemeIcon
            variant="light"
            opacity={detailMapped?.length > 0 ? 1 : 0.35}
            color={color}
          >
            {icon}
          </ThemeIcon>
        </Indicator>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Stack mah={170} maw={200} style={{ overflow: "auto" }}>
          {detailMapped.map((detail, i) => (
            <Anchor
              size="sm"
              key={i}
              fw={600}
              style={{
                cursor: detail?.integration_id ? "pointer" : "text",
              }}
              underline={detail?.integration_id ? "hover" : "never"}
              href={(() => {
                let parentLink = "";
                if (detail.type === "email") parentLink = "email";
                else parentLink = "linkedin";

                return detail?.integration_id
                  ? `/senders/${parentLink}/${detail.integration_id}`
                  : null;
              })()}
              target="_blank"
            >
              <b>#{detail?.stepId || "- "}</b> :{" "}
              {convertIsoDateToLocaleString(detail.message) || detail.message}{" "}
              <br />
            </Anchor>
          ))}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const ParentCell = ({ row }) => {
  const criticals = filterStepsWithTypeDetails(row, "critical");
  const errors = filterStepsWithTypeDetails(row, "error");
  const warnings = filterStepsWithTypeDetails(row, "warning");
  const infos = filterStepsWithTypeDetails(row, "info");
  return (
    <Group w="100%" wrap="nowrap" gap={"xs"}>
      <DetailsItem
        field={criticals}
        icon={<IconCircleMinus size={20} />}
        type={"critical"}
        color="red"
      />

      <DetailsItem
        field={errors}
        icon={<IconAlertTriangle size={20} />}
        type={"error"}
        color="orange"
      />

      <DetailsItem
        field={warnings}
        icon={<IconInfoCircle size={20} />}
        type={"warning"}
        color="blue"
      />

      <DetailsItem
        field={infos}
        icon={<IconMessageForward size={20} />}
        type={"info"}
        color="tertiary.8"
      />
    </Group>
  );
};

export function CampaignColumnDetails() {
  const lang = useLanguage.getState().language;
  return {
    field: "details",
    headerName: lang.statistics.columns.details.header_name,
    width: 200,
    renderCell: ({ row, value }) => {
      if (!row) {
        return;
      }
      if (row.isChild) {
        return (
          <Stack py={"xs"} w={"100%"}>
            {row.steps.map((step, index) => {
              return <ChildCell step={step} key={step.id} />;
            })}
          </Stack>
        );
      }

      return <ParentCell row={row} value={value} />;
    },
  };
}
