import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useLeadsSheetStore } from "../../../../../../../../stores/LeadsStore";

const AddNote = () => {
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const handleAdd = () => {
    let alreadyThere = document.getElementById("note-creator");

    if (alreadyThere) {
      alreadyThere.focus();
    } else {
      let newNote = {
        date: new Date().toISOString(),
        id: "note-creator",
        date_updated: null,
        note: true,
        history_type: "note",
        isCreate: true,
      };
      setCurrent({
        ...current,
        history: [newNote, ...(current?.history || [])],
      });
    }
  };

  return (
    <Button
      ml={"auto"}
      leftSection={<IconPlus size={18} />}
      color="secondary"
      onClick={handleAdd}
    >
      Note
    </Button>
  );
};

export default AddNote;
