import {
  Button,
  Center,
  Group,
  Modal,
  Paper,
  SegmentedControl,
} from "@mantine/core";
import { IconSettings, IconTimeline } from "@tabler/icons-react";
import { lazy, useCallback, useMemo, useState } from "react";
import WarmupSettings from "./WarmupSettings";
import BackButton from "../../../../../components/View/BackButton";
import { updateWarmup } from "../../../../../api/EmailAPI";
import { notifications } from "@mantine/notifications";
const WarmupStats = lazy(() => import("./WarmupStats"));

const WarmupModal = ({ handleClose, row, apiRef }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("stats");
  const tabs = useMemo(
    () => [
      {
        value: "stats",
        label: (
          <Center style={{ gap: 10 }}>
            <IconTimeline size={16} />
            <span>Statistiques</span>
          </Center>
        ),
      },
      {
        value: "configs",
        label: (
          <Center style={{ gap: 10 }}>
            <IconSettings size={16} />
            <span>Configurations</span>
          </Center>
        ),
      },
    ],
    []
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const fd = new FormData(e.target);
      const data = Object.fromEntries(fd);

      data.total_warmup_per_day = parseInt(data.total_warmup_per_day) || 1;
      data.daily_rampup = parseInt(data.daily_rampup) || 1;
      data.reply_rate_percentage = parseInt(data.reply_rate_percentage) || 1;
      data.warmup_enabled = Boolean(data.warmup_enabled);

      console.log(data);

      setLoading(true);
      updateWarmup({ id: row.id, ...data })
        .then((res) => {
          notifications.show({ message: "Modification Warmup enregistrée" });
          apiRef?.current?.updateRows([{ id: row.id, warmup_settings: data }]);
        })
        .finally(() => setLoading(false));
    },
    [apiRef, row.id]
  );

  return (
    <Modal opened size={"70%"} title="Warm-up" onClose={handleClose}>
      <SegmentedControl
        data={tabs}
        size="md"
        value={tab}
        onChange={setTab}
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        radius={"lg"}
      />
      <Paper
        withBorder
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        p={"sm"}
        bg={"var(--mantine-color-gray-0)"}
      >
        {tab === "configs" ? (
          <WarmupSettings row={row} handleSubmit={handleSubmit} />
        ) : (
          <WarmupStats row={row} />
        )}
      </Paper>

      <Group justify="end" mt={"md"}>
        <BackButton onClick={handleClose} />

        <Button
          form="warmup-settings"
          type="submit"
          loading={loading}
          disabled={tab !== "configs"}
        >
          Sauvegarder
        </Button>
      </Group>
    </Modal>
  );
};

export default WarmupModal;
