import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import Selector from "./Selector";
import { useConfigStore } from "../../stores/ConfigStore";
import { useMemo, useState } from "react";
import { createField, getFields } from "../../api/ContactAPI";
import { IconShare3 } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../stores/LanguageStore";

function getOptionName(option) {
  return `${option.name}`;
}

const FieldImportSelector = ({ setFields, fields, column }) => {
  const lang = useLanguage((s) => s.language);
  const datafields = useConfigStore((s) => s.datafields);
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    getFields().finally(() => {
      setLoading(false);
    });
  };
  const options = useMemo(() => {
    let result = [
      {
        label: "",
        options: [
          {
            name: lang.contact_lists.import.new_field,
            option_name: lang.contact_lists.import.new_field,
            id: "",
          },
        ],
      },
      {
        label: lang.global.defaults,
        options: [],
      },
      { label: lang.global.created, options: [] },
    ];
    datafields?.forEach((item) => {
      let default_datafield = item?.from_sharing && item?.created_by === null;
      result[default_datafield ? 1 : 2].options.push({
        ...item,
        option_name: getOptionName(item),
      });
    });
    return datafields?.length > 0 ? result : [];
  }, [datafields]);

  const [opened, { open, close }] = useDisclosure();

  const onChange = (v) => {
    if (v?.id === "") {
      open();
    } else {
      setFields({ ...fields, [column]: v?.identifier_placeholder || null });
    }
  };

  const [creatingDatafield, setCreatingDatafield] = useState(false);
  const createDatafield = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);
    setCreatingDatafield(true);
    createField({name: data?.name}).then((res) => {
      getFields()
        .then((fields_response) => {
          let newDatafields = fields_response.data.data_fields_list;
          let newDatafield = newDatafields.find(
            (x) => x.id === res.data.data_field_id
          );
          notifications.show({
            message: lang.datafields.row_highlight.created,
            color: "tertiary.8",
          });
          if (newDatafield) {
            setFields({
              ...fields,
              [column]: newDatafield?.identifier_placeholder,
            });
            close();
          }
        })
        .finally(() => setCreatingDatafield(false));
    });
  };

  return (
    <>
      <Group w={"33%"} wrap="nowrap" gap={"xs"}>
        <Selector
          options={options}
          placeholder={lang.contact_lists.import.no_import_field}
          loading={loading}
          value={
            datafields?.find((x) => x.identifier_placeholder === fields[column])
              ?.id || null
          }
          onChange={(val) => onChange(val)}
          onDropdownOpen={handleLoad}
          getOptionName={getOptionName}
          rightOptionSection={(item) => (
            <>
              {item?.id && (
                <span style={{ fontSize: 10, opacity: 0.35 }}>#{item?.id}</span>
              )}
              {item.from_sharing && item?.created_by && (
                <Tooltip label={lang.prm.details.from_sharing} withArrow>
                  <IconShare3 size={16} />
                </Tooltip>
              )}
            </>
          )}
        />
      </Group>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={lang.contact_lists.create_column.title}
          centered
        >
          <Stack component={"form"} onSubmit={createDatafield} gap={"xs"}>
            <Text>{lang.contact_lists.create_column.text}</Text>
            <TextInput
              name="name"
              label={lang.contact_lists.create_column.label}
              placeholder={lang.contact_lists.create_column.placeholder}
              defaultValue={column}
              data-autofocus
            />
            <Group justify="end" mt={"md"}>
              <Button
                onClick={close}
                variant="subtle"
                disabled={creatingDatafield}
              >
                {lang.global.cancel}
              </Button>
              <Button type="submit" loading={creatingDatafield}>
                {lang.global.create}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default FieldImportSelector;
