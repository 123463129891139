import { Button, Center } from "@mantine/core";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { handleLayout } from "../layouter";

const LinkerOverlay = ({ isEvent, id, data, yPos, xPos, active }) => {
  const linking = useFlowStore((s) => s.linking);
  const setLinking = useFlowStore((s) => s.setLinking);

  const handleLink = () => {
    const edges = useFlowStore.getState().edges;
    const setEdges = useFlowStore.getState().setEdges;
    const nodes = useFlowStore.getState().nodes;
    const setNodes = useFlowStore.getState().setNodes;
    const updateNodeData = useFlowStore.getState().updateNodeData;

    let newEdges = [...edges];
    const newEdge = {
      id: `e-${linking.id}-${id}`,
      source: linking.id,
      target: id,
      data: { fromEvent: true, error: data?.error, linked: true },
    };
    newEdges.push(newEdge);

    let newSource = Array.isArray(data.source)
      ? [...data.source, linking.id]
      : [data.source, linking.id];

    updateNodeData(id, {
      source: newSource,
    });

    setNodes(nodes.filter((x) => x.id !== `${linking.id}-trail`));
    setEdges(newEdges.filter((x) => x.id !== `e-${linking.id}-trail`));
    setLinking(false);

    setTimeout(() => {
      handleLayout();
    }, 10);
  };

  if (!linking) {
    return "";
  }

  if (isEvent) {
    return (
      <Center
        pos={"absolute"}
        left={0}
        top={0}
        w={"100%"}
        h={"100%"}
        bg={!active ? "gray" : "primary.3"}
        style={{
          border: active ? "4px solid var(--mantine-color-primary-8)" : "none",
          borderRadius: 8,
          zIndex: 10,
          opacity: 0.35,
          cursor: active ? "default" : "not-allowed",
        }}
      />
    );
  }

  const valid =
    linking?.yPos < yPos &&
    linking.xPos !== xPos &&
    data.source !== linking.id &&
    (Array.isArray(data?.source) ? !data?.source?.includes(linking.id) : true);

  return (
    <Button
      pos={"absolute"}
      left={0}
      top={0}
      w={"100%"}
      h={"100%"}
      bg={!valid ? "#ff000016" : "#00ff0033"}
      style={{
        border: "2px solid",
        borderColor: !valid
          ? "var(--mantine-color-red-4)"
          : "var(--mantine-color-green-8)",
        borderRadius: 8,
        zIndex: 10,
      }}
      disabled={!valid}
      className="on-hover-opacity"
      onClick={handleLink}
    ></Button>
  );
};

export default LinkerOverlay;
