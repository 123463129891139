import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useProfileStore } from "../../../stores/UserStore";
import { updatePermission } from "../../../api/AdminAPI";
import { notifications } from "@mantine/notifications";
import { Loader, NumberInput } from "@mantine/core";
import { useRouteLoaderData } from "react-router-dom";
import { IconLinkedin } from "../../../components/Icons/IconLinkedin";
import { IconMail, IconUserSearch } from "@tabler/icons-react";

const ResellerPointInput = ({ row, permission }) => {
  const lang = useLanguage((s) => s.language);
  const reseller = useRouteLoaderData("reseller");
  const profile = useProfileStore((s) => s.profile);
  const setProfile = useProfileStore((s) => s.setProfile);

  const mainProfile = useMemo(() => {
    return profile.id === row.id ? profile : row;
  }, [profile, row]);

  const [defaultValue, setDefaultValue] = useState(
    mainProfile.permissions.find((x) => x.name === permission)?.value
  );

  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ newValue }) => {
    if (newValue !== defaultValue) {
      setLoading(true);
      updatePermission({
        permission_name: permission,
        user_id: mainProfile?.id,
        value: newValue,
      })
        .then((response) => {
          let newPermissions = [...profile.permissions];
          let i = newPermissions.findIndex((x) => x.name === permission);

          let newPermissionValue =
            newPermissions[i].value - (newValue - defaultValue);

          newPermissions[i] = {
            ...newPermissions[i],
            value: newPermissionValue,
          };

          console.log(newPermissionValue);

          if (newPermissionValue < 0 && profile?.level !== "admin") {
            setValue(defaultValue);
          } else {
            setProfile({ ...profile, permissions: newPermissions });
            setDefaultValue(newValue);
            return notifications.show({
              message: lang.administration.right.limits.changed,
              color: "tertiary.8",
            });
          }
        })
        .catch((err) => {
          setValue(defaultValue);
        })
        .finally(() => setLoading(false));
    }
  };

  const disableEdition = useMemo(
    () => profile?.level !== "admin" && profile.id !== reseller?.created_by,
    [profile.id, profile?.level, reseller?.created_by]
  );

  const onChange = (v) => {
    setValue(v);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (row.id === profile.id)
      setDefaultValue(
        mainProfile.permissions.find((x) => x.name === permission)?.value
      );
  }, [profile]);

  return (
    <NumberInput
      id={`reseller-input-${mainProfile.id}-${permission}`}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      leftSection={
        permission === "maxIntegrationsLinkedin" ? (
          <IconLinkedin size={16} />
        ) : permission === "maxIntegrationsEmailSMTP" ? (
          <IconMail size={16} />
        ) : (
          <IconUserSearch size={16} />
        )
      }
      onBlur={(e) =>
        handleChange({
          newValue: parseInt(e.target.value),
        })
      }
      step={
        permission === "maxEmailsGeneratedPerMonth"
          ? 1000
          : permission === "maxIntegrationsEmailSMTP"
          ? 5
          : 1
      }
      min={0}
      disabled={
        loading || disableEdition || profile.id === row.id
        // ||
        // (row?.permissions?.find((x) => x.name === permission)?.is_editable ===
        //   false &&
        //   profile.level !== "admin")
      }
      clampBehavior="strict"
      rightSection={loading ? <Loader size={16} /> : undefined}
      rightSectionPointerEvents="all"
      rightSectionProps={{
        onClick: (e) => {
          document
            .getElementById(`reseller-input-${mainProfile.id}-${permission}`)
            ?.focus();
        },
      }}
      radius={0}
      styles={{
        input: {
          borderTop: 0,
          borderBottom: 0,
          borderLeft: 0,
        },
      }}
    />
  );
};

export default ResellerPointInput;
