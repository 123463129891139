import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Group, Modal, ThemeIcon } from "@mantine/core";
import { IconSparkles } from "@tabler/icons-react";
import QuickCampaignContent from "./QuickCampaignContent";

const QuickCampaign = ({ current, inMenu = false, closeMenu }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  const handleClose = useCallback(() => {
    close();
    closeMenu?.();
  }, [close, closeMenu]);

  return (
    <>
      {inMenu ? (
        <Button
          onClick={open}
          leftSection={<IconSparkles size={16} />}
          fw={600}
          color="secondary"
          variant="subtle"
          justify="start"
          w={"100%"}
        >
          {lang.quick_campaign.button_label}
        </Button>
      ) : (
        <Button
          onClick={open}
          leftSection={<IconSparkles size={16} />}
          color="secondary"
          variant="subtle"
          px={"xs"}
          w={"100%"}
        >
          {lang.quick_campaign.button_label}
        </Button>
      )}

      {opened && (
        <Modal
          opened={opened}
          size={"70%"}
          onClose={handleClose}
          title={
            <Group>
              <ThemeIcon variant="subtle" color="secondary">
                <IconSparkles />
              </ThemeIcon>
              {lang.quick_campaign.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </Group>
          }
          styles={{
            title: {
              lineHeight: 1.5,
            },
          }}
        >
          <QuickCampaignContent handleClose={handleClose} current={current} />
        </Modal>
      )}
    </>
  );
};

export default QuickCampaign;
