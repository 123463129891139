import { handleLayout } from "./layouter";
import {
  getLinkedinInvitations,
  getLinkedinMessages,
  getMEmails,
  getSMS,
  getSMV,
} from "../../../../../api/ModelsApi";
import {
  getEmails,
  getLinkedinAccounts,
  getPools,
} from "../../../../../api/EmailAPI";
import { getSignatures } from "../../../../../api/ConfigsApi";
import { findWorkflow } from "../../../../../api/WorkflowAPI";
import { useFlowStore } from "../../../../../stores/WorkflowStore";
import { incrementHour } from "../../../../../functions/dates";
import { getBlacklists } from "../../../../../api/BlacklistAPI";
import { defaultNodeConfigs } from "./flowSettings";
import {
  workflow1,
  workflow2,
  workflow3,
  workflow4,
  workflow5,
} from "../../templates/workflowProfileTemplates";

export const handleWorkflowGet = ({
  id,
  libraryId,
  workflowProfile,
  onFinish,
  isEdit,
}) => {
  let timezoneOffset = new Date().getTimezoneOffset() / 60;
  const setLoadingPage = useFlowStore.getState().setLoadingPage;
  setLoadingPage(true);

  getMEmails();
  getSMV();
  getSMS();
  getPools();
  getLinkedinMessages();
  getSignatures();
  getLinkedinInvitations();
  getEmails();
  getLinkedinAccounts();
  getBlacklists();

  const main = libraryId
    ? async () =>
        new Promise((resolve, reject) =>
          resolve({
            data: {
              workflow_profile: [
                workflow1,
                workflow2,
                workflow3,
                workflow4,
                workflow5,
              ]?.find((x) => x.id === parseInt(libraryId)),
            },
          })
        )
    : workflowProfile
    ? async () =>
        new Promise((resolve, reject) =>
          resolve({ data: { workflow_profile: workflowProfile } })
        )
    : async () => findWorkflow(id);

  main()
    .then((response) => {
      const p = { ...response.data.workflow_profile };

      const setWorkflowProfile = useFlowStore.getState().setProfile;
      const setNodes = useFlowStore.getState().setNodes;
      const setEdges = useFlowStore.getState().setEdges;

      let steps = p.steps;

      let newNodes = [];
      let newEdges = [];
      steps.forEach((node) => {
        let node_id = node.step_id.id.toString();
        // Handle Edges
        if (node.parent_ids?.length === 0) {
          let parentId = node.parent_ids[0]?.id?.toString();
          let parent_node = steps.find(
            (x) => x.step_id?.id?.toString() === parentId
          );

          if (node?.is_initial) {
            newEdges.push({
              id: `e-${0}-${node_id}`,
              source: "0",
              target: node_id,
              data: { fromEvent: false },
            });
          } else {
            newEdges.push({
              id: `e-${parentId}-${node_id}`,
              source: parentId,
              target: node_id,
              data: { fromEvent: !!parent_node?.event_type },
            });
          }
        } else {
          node.parent_ids.forEach((p) => {
            let parentId = p.id?.toString();
            let parent_node = steps.find(
              (x) => x.step_id?.id?.toString() === parentId
            );

            newEdges.push({
              id: `e-${parentId}-${node_id}`,
              source: parentId,
              target: node_id,
              data: { fromEvent: !!parent_node?.event_type },
            });
          });
        }

        // Handle Nodes
        newNodes.push({
          type: "action",
          id: node_id,
          position: {
            x: node.position_x,
            y: node.position_y,
          },
          data: {
            exist: isEdit ? true : false,
            actionType: node?.action_type || node.step_type,
            initial: node?.is_initial,
            model_id: node?.model_id,
            integration_id: node?.integration_id,
            pool_id: node?.pool_id,
            signature_id: node?.signature_id,
            event_type: node?.event_type,
            which_links_clicked: node?.which_links_clicked || [],
            when_minutes:
              node?.when_minutes === null ? 1440 : node?.when_minutes,
            name: node?.name,
            number_contacts: node?.number_contacts,
            filter: node?.filter,

            limit_send_per_day: node?.limit_send_per_day || 0,
            sending_weekends_allowed: node?.sending_weekends_allowed,
            allow_massmailing: node?.allow_massmailing,
            time_start_sending: node?.time_start_sending
              ? incrementHour(node?.time_start_sending, -timezoneOffset)
              : defaultNodeConfigs.time_start_sending,
            time_stop_sending: node?.time_stop_sending
              ? incrementHour(node?.time_stop_sending, -timezoneOffset)
              : defaultNodeConfigs.time_stop_sending,
            blacklist_ids: node?.blacklist_ids?.map((x) => x.id) || [],
            email_linkedin_exclude: node?.email_linkedin_exclude || [],
            show_reply_as_conversation: node?.show_reply_as_conversation,
            use_smtp_fallback_server: node?.use_smtp_fallback_server,

            source: node?.is_initial
              ? "0"
              : node.parent_ids?.length === 1
              ? node.parent_ids?.[0]?.id?.toString()
              : node.parent_ids.map((s) => s.id?.toString()),
          },
        });
      });

      setNodes(newNodes);
      setEdges(newEdges);

      delete p.steps;
      setWorkflowProfile({
        ...p,
        id: isEdit ? id : null,
        name: isEdit ? p.name : p.name + "_copy",
      });

      return { newNodes, newEdges, p };
    })
    .then(({ newNodes, newEdges, p }) => {
      handleLayout().then(() => {
        onFinish?.({ newNodes, newEdges });
      });

      let name_input = document.getElementById("workflow-name");

      setLoadingPage(false);
      if (name_input) {
        name_input.value = isEdit ? p.name : p.name + "_copy";
        // document.getElementById("workflow-name").focus();
      }
    })
    .catch(() => setLoadingPage(false));
};
