import {
  Button,
  Group,
  Menu,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
} from "../../../../../../../stores/LeadsStore";
import { createReminder } from "../../../../../../../api/PRMAPI";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";
import { useProfileStore } from "../../../../../../../stores/UserStore";
import { modals } from "@mantine/modals";
import { reminderTypes } from "../../../../utils";
import { DateTimePicker } from "@mantine/dates";

const defaultCallType = "phone";

const AddReminder = () => {
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const currentContainer = useLeadsSheetStore((s) => s.currentContainer);
  const setIndex = useLeadsSheetStore((s) => s.setIndex);
  const setCurrentContainer = useLeadsSheetStore((s) => s.setCurrentContainer);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const containers = useLeadsContainerStore((s) => s.containers);
  const [loading, setLoading] = useState(false);

  const quickReminders = useMemo(
    () => [
      {
        label: `${lang.prm.details.reminders.in} 15 ${lang.prm.details.reminders.minutes}`,
        value: 15,
      },
      {
        label: `${lang.prm.details.reminders.in} 30 ${lang.prm.details.reminders.minutes}`,
        value: 30,
      },
      {
        label: `${lang.prm.details.reminders.in} 1 ${lang.prm.details.reminders.hour}`,
        value: 60,
      },
      {
        label: `${lang.prm.details.reminders.in} 2 ${lang.prm.details.reminders.hour}s`,
        value: 60 * 2,
      },
      {
        label: `${lang.prm.details.reminders.in} 1 ${lang.prm.details.reminders.day}`,
        value: 60 * 24,
      },
      {
        label: lang.prm.details.reminders.manual,
        value: "manual",
      },
    ],
    [lang]
  );

  const handleCreate = ({ value, date, type, name }) => {
    const convertedDate = dayjs().add(value, "m");
    setLoading(true);
    createReminder({
      prospect_id: current.id,
      call_date: date || convertedDate.toISOString(),
      name: name || lang.prm.details.reminders.default_name,
      type: type || defaultCallType,
    })
      .then((response) => {
        const profile = useProfileStore.getState().profile;
        let newCall = {
          id: response.data.call_id,
          call_date: date || convertedDate.toISOString(),
          name: lang.prm.details.reminders.default_name,
          type: defaultCallType,
          created_by: {
            last_name: profile.last_name,
            first_name: profile.first_name,
            id: profile.id,
          },
        };
        let newItem = {
          ...current,
          calls: [newCall, ...current.calls],
          status: "to_call",
        };
        setCurrent(newItem);

        if (current.status !== "to_call" && !current.custom_status) {
          // Remove from current container
          let newItemsLast = currentContainer.items.filter(
            (item) => item.id !== current.id
          );
          updateContainer(currentContainer.id, {
            items: newItemsLast,
            total: currentContainer.total - 1,
            needCacheReset: true,
          });

          // Add to to_call
          let newContainer = containers.find((x) => x.id === "to_call");
          let newItemsFuture = [...newContainer.items];
          newItemsFuture.unshift({
            ...newItem,
            status_changed_date: new Date(),
          });
          updateContainer("to_call", {
            total: newContainer.total + 1,
            items: newItemsFuture,
            needCacheReset: true,
          });
          setCurrentContainer({
            ...newContainer,
            total: newContainer.total + 1,
            items: newItemsFuture,
          });
          setIndex(0);
          notifications.show({
            message: lang.prm.details.reminders.moved_to_call,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    modals.closeAll();
    return handleCreate({
      date: data?.date,
      type: data?.type,
      name: data?.name,
    });
  };

  const handleClick = (value) => {
    if (value !== "manual") {
      return handleCreate({ value });
    } else {
      modals.open({
        title: (
          <Text>
            {lang.prm.details.reminders.dialog_title}{" "}
            {current?.["%first_name%"] || lang.prm.details.reminders.prospect}{" "}
            {current?.["%last_name%"] || `#${current?.id}`}
          </Text>
        ),
        children: (
          <Stack
            component="form"
            id="create-reminder-form"
            onSubmit={onSubmit}
            gap={"xs"}
          >
            <TextInput
              data-autofocus
              name="name"
              label={lang.prm.details.reminders.name}
              placeholder={lang.prm.details.reminders.name}
              required
            />
            <DateTimePicker
              label={lang.prm.details.reminders.date}
              defaultValue={new Date()}
              minDate={new Date()}
              name="date"
              required
              dropdownType="modal"
              modalProps={{
                zIndex: 9000,
              }}
            />
            <Select
              data={Object.keys(reminderTypes()).map((key) => ({
                label: reminderTypes()[key],
                value: key,
              }))}
              label={lang.prm.details.reminders.type}
              defaultValue={"phone"}
              name="type"
            />

            <Group justify="end">
              <Button color="primary" type="submit">
                {lang.prm.details.reminders.create}
              </Button>
            </Group>
          </Stack>
        ),
      });
    }
  };

  return (
    <Menu withArrow offset={0} withinPortal={false}>
      <Menu.Target>
        <Button
          variant="filled"
          color="primary"
          leftSection={<IconPlus size={18} />}
          loading={loading}
        >
          {lang.prm.details.call}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {quickReminders.map((item) => (
          <Menu.Item key={item.value} onClick={() => handleClick(item.value)}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default AddReminder;
