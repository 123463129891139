import { useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import { IconUserShare } from "@tabler/icons-react";
import BackButton from "./BackButton";
import { notifications } from "@mantine/notifications";
import UserSelector from "../UserSelector/UserSelector";
import { useProfileStore } from "../../stores/UserStore";

const CopyToUser = ({ current, handler, closeMenu, onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    handler({ id: current.id, user_id: user.id })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.copy_to_user.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        onClick={open}
        leftSection={<IconUserShare size={18} />}
        fw={600}
        color="primary"
        variant="subtle"
        justify="start"
      >
        {lang.contact_lists.copy_to_user.label}
      </Button>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.copy_to_user.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>
              {lang.contact_lists.copy_to_user.dialog.text}
            </Text>

            <UserSelector
              label={lang.contact_lists.copy_to_user.dialog.user}
              placeholder={lang.contact_lists.copy_to_user.dialog.user}
              value={user}
              onChange={(v) => {
                setUser(v);
              }}
              required
              isFooter={
                useProfileStore.getState()?.unswitchedProfile?.level === "admin"
              }
              withinPortal={true}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconUserShare size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.copy_to_user.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default CopyToUser;
