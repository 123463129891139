import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useListStore } from "../../../../../stores/ListStore";
import { Link, useParams } from "react-router-dom";
import { Anchor, Flex, Stack, Text, Tooltip } from "@mantine/core";
import { status_list } from "../../../leadsTracking/utils";
import { getProgrammationClickers } from "../../../../../api/StatsAPI";
import MainTable from "../../../../../layouts/Table/MainTable";
import dateColumn from "../../../../../layouts/Table/columns/dateColumn";
import numberColumn from "../../../../../layouts/Table/columns/numberColumn";
import { useContactHandler } from "../../../lists/components/useContactHandler";
import TableFilterExport from "../../../../../components/TableComponents/TableFilterExport";
import { exportPrm } from "../../../../../api/PRMAPI";

function filterPrmById(id) {
  return window.open("/prm?filter_id=" + id);
}

const UrlContacts = ({ url }) => {
  const { campaign, workflow, stepId, type, stepType } = useParams();
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const [total, setTotal] = useState(0);

  const lists = useListStore((s) => s.lists);

  const startingColumns = useMemo(() => {
    return [
      // numberColumn("prm_contacts_id", "ID", 100),
      {
        field: "status",
        id: "status",
        headerName: lang.statistics.columns.contacted.columns.status,
        sortable: false,
        width: 120,
        filterable: false,
        renderCell: ({ value }) => {
          if (value === "opener" && stepType === "linkedin_invitation") {
            return lang.workflows.nodes.events.linkedin_invitation_accepted;
          }
          return value
            ? status_list().find((x) => x.value === value).label
            : "-";
        },
      },
      // dateColumn(
      //   "date_contacted",
      //   lang.statistics.columns.contacted.columns.date_contacted,
      //   false,
      //   {
      //     filterable: stepId,
      //     disabled: !stepId,
      //   }
      // ),
      dateColumn(
        "status_changed_date",
        lang.statistics.columns.contacted.columns.status_changed_date,
        false,
        {
          filterable: true,
        }
      ),
    ];
  }, [lang, stepType]);

  const additionalColumns = useMemo(() => {
    const result = [
      numberColumn(),
      {
        field: "contact_list_id",
        id: "contact_list_id",
        headerName: lang.prm.details.campaigns_table.contact_list,
        filterable: false,
        sortable: false,
        width: 150,
        renderCell: ({ row, value }) => {
          return (
            <Anchor
              component={Link}
              to={`/contact-lists/${value}`}
              target="_blank"
              c="inherit"
            >
              {lists.find((x) => x.id === value)?.name || "Liste"} #{value}
            </Anchor>
          );
        },
      },
      {
        field: "contact_list_contacts_id",
        headerName: lang.prm.details.campaigns_table.id_in_lilst,
        id: "contact_list_contacts_id",
        filterable: false,
        sortable: false,
        width: 90,
        renderCell: ({ row, value }) => (
          <Anchor
            component={Link}
            to={`/contact-lists/${row?.contact_list_id}?contact_id=` + value}
            target="_blank"
            c="inherit"
          >
            {value || "- "}
          </Anchor>
        ),
      },
      {
        field: "prm_contacts_id",
        headerName: lang.statistics.columns.contacted.columns.prm_contacts_id,
        sortable: false,
        id: "prm_contacts_id",
        filterable: false,
        width: 120,
        renderCell: ({ value }) =>
          value ? (
            <Tooltip
              label={lang.statistics.columns.contacted.columns.filter_prm}
            >
              <Text onClick={() => filterPrmById(value)}>{value}</Text>
            </Tooltip>
          ) : (
            "-"
          ),
      },
    ];

    return result;
  }, [lang, lists]);

  const {
    loading,
    columns,
    rows,
    rowCount,
    serverInfo,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    listId: parseInt(campaign),
    getFirstPage: (data) =>
      getProgrammationClickers({
        programmation_id: parseInt(campaign),
        workflow_id: parseInt(workflow),
        step_id: parseInt(stepId),
        contact_type: type,
        link_url: encodeURIComponent(url),
        ...data,
      }),
    searchFunction: ({ query, filter, ...data }) =>
      getProgrammationClickers({
        programmation_id: parseInt(campaign),
        workflow_id: parseInt(workflow),
        step_id: parseInt(stepId),
        contact_type: type,
        link_url: encodeURIComponent(url),
        filter: {
          mode: "and",
          values: [
            ...(filter?.values || []),
            {
              field_name: "any_datafield",
              type: "contains",
              value: query,
            },
          ],
        },
        ...data,
      }),
    disableEdit: true,
    startingColumns,
    additionalColumns,
    idField: "prm_contact_id",
  });

  const toolbarActions = useCallback(
    ({ selectedIds, filter }) => (
      <TableFilterExport
        selectedIds={selectedIds}
        filter={filter}
        handleExport={({
          ids,
          excluded_ids,
          name,
          filter,
          export_type,
          file_type,
        }) =>
          exportPrm({
            filter:
              export_type === "selection"
                ? {
                    mode: "or",
                    values: [],
                  }
                : {
                    mode: "and",
                    values: [
                      {
                        field_name: "link_click",
                        type: "equals",
                        value: url,
                      },
                      {
                        field_name: "programmation_id",
                        type: "equals",
                        value: campaign,
                      },
                      ...(filter?.values || []),
                    ],
                  },
            contact_ids: ids,
            excluded_contact_ids: excluded_ids,
            name,
            file_type,
          })
        }
        name={"Clicker - export"}
        filterResultNumber={rowCount}
        total={total}
      />
    ),
    [campaign, rowCount, total, url]
  );

  useEffect(() => {
    if (apiRef.current?.state) {
      handleLoading(true);
    }
  }, [type, apiRef]);

  useEffect(() => {
    setTotal(Math.max(serverInfo?.number_of_results, total));
  }, [serverInfo?.number_of_results]);

  console.log(rows);

  if (!columns) {
    return;
  }

  return (
    <Stack flex={"1 1 auto"} w={0} miw={"100%"} gap={0} h={"75vh"}>
      <Flex
        flex={"1 1 auto"}
        h={0}
        style={{
          borderLeft: "1px solid #00000021",
          borderRight: "1px solid #00000021",
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <MainTable
          getRowId={(r) => r.prm_contacts_id}
          isClient={false}
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          toolbarActions={toolbarActions}
          setLoading={() => {
            handleLoading();
          }}
          id={"list-table"}
          hasFolder
          onPaginationChange={onPaginationChange}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
          actionsCellWidth={50}
          withBorderTop
        />
      </Flex>
    </Stack>
  );
};

export default UrlContacts;
