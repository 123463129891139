import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  changeUserReseller,
  removeFromOrg,
  removeFromReseller,
  removeFromTeam,
} from "../../../../../api/AdminAPI";
import { notifications } from "@mantine/notifications";
import { Button, Group, Paper, Stack, Text, Tooltip } from "@mantine/core";
import PopOver from "../../../../../components/View/PopOver";
import {
  IconBuildingCommunity,
  IconBuildingStore,
  IconExternalLink,
  IconLogout,
  IconPlus,
  IconShieldLock,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";
import CommunityChanger from "./CommunityChanger";

const Adder = ({ profile, setProfile, type, changingProfile }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Button
          variant="light"
          color="primary"
          ref={ref}
          onClick={handleOpen}
          leftSection={<IconPlus size={18} />}
          loading={loading}
          disabled={changingProfile}
          ml={"auto"}
        >
          {lang.administration.right.community.join}
        </Button>
      )}
      popoverChildren={(closePopover) => (
        <CommunityChanger
          setLoading={setLoading}
          handleClose={closePopover}
          profile={profile}
          setProfile={setProfile}
          type={type}
        />
      )}
    />
  );
};

const Item = ({ item, type, setProfile, profile }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(null);
  const handleRemove = () => {
    setLoading("remove");
    let func =
      type === "teams"
        ? removeFromTeam
        : type === "resellers"
        ? removeFromReseller
        : removeFromOrg;

    func({
      organization_id: item.id,
      team_id: item.id,
      reseller_id: item.id,
      user_id: profile.id,
    })
      .then((response) => {
        notifications.show({ message: lang.profile.edited, color: "green" });
        setProfile((prev) => {
          let newProfile = { ...prev };

          newProfile[type] = newProfile[type].filter((x) => x.id !== item.id);

          return newProfile;
        });
      })
      .finally(() => {
        setLoading(null);
      });
  };

  const onLevelChange = () => {
    setLoading("level");
    let newLevel = item?.user_level === "user" ? "admin" : "user";
    changeUserReseller({
      current_reseller_id: item.id,
      new_reseller_id: item.id,
      user_id: profile.id,
      level: newLevel,
    })
      .then((response) => {
        setProfile((prev) => {
          let newProfile = { ...prev };
          let index = newProfile.resellers.findIndex((x) => x.id === item.id);
          newProfile.resellers[index].user_level = newLevel;

          return newProfile;
        });
        notifications.show({
          message: lang.administration.right.community.resellers.level_changed,
          color: "tertiary.8",
        });
      })
      .finally(() => setLoading(null));
  };

  console.log(item);

  return (
    <Paper
      component={Group}
      style={{
        width: "100%",
        border: "1px solid #00000021",
        position: "relative",
      }}
      p={"xs"}
      py={"sm"}
      radius={"md"}
    >
      <Text size="sm">
        {item?.name} #{item.id}
      </Text>

      {type === "resellers" && (
        <Tooltip
          label={`Cliquer pour changer en ${
            item.user_level === "user" ? "Administrateur" : "Utilisateur"
          }`}
        >
          <Button
            color="red"
            variant="subtle"
            className="on-hover-opacity"
            style={{
              minWidth: 0,
              position: "absolute",
              right: 100,
            }}
            loading={loading === "level"}
            onClick={onLevelChange}
            px={"xs"}
          >
            {item.user_level === "user" ? (
              <IconUser size={18} />
            ) : (
              <IconShieldLock size={18} />
            )}
          </Button>
        </Tooltip>
      )}

      <Tooltip label={lang.global.view}>
        <Button
          px={"xs"}
          variant="subtle"
          component="a"
          href={
            type === "resellers"
              ? `/resellers/white-labels/${item.id}/users/`
              : `/community/${type}/${item.id}/users/`
          }
          target="_blank"
          style={{
            minWidth: 0,
            position: "absolute",
            right: 52,
          }}
          className="on-hover-opacity"
        >
          <IconExternalLink size={18} />
        </Button>
      </Tooltip>

      <Tooltip label="Retirer">
        <Button
          color="red"
          variant="subtle"
          className="on-hover-opacity"
          style={{
            minWidth: 0,
            position: "absolute",
            right: 8,
          }}
          loading={loading === "remove"}
          onClick={handleRemove}
          px={"xs"}
          disabled={type === "resellers"}
        >
          <IconLogout size={18} />
        </Button>
      </Tooltip>
    </Paper>
  );
};

const CommunityChooser = ({ type, profile, setProfile, changingProfile }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Stack w={"100%"}>
      <Group w={"100%"}>
        {type === "teams" ? (
          <IconUsers size={18} />
        ) : type === "resellers" ? (
          <IconBuildingStore size={18} />
        ) : (
          <IconBuildingCommunity size={18} />
        )}
        <Text>
          {type === "teams"
            ? lang.users_list.teams
            : type === "resellers"
            ? lang.users_list.resellers
            : lang.users_list.organizations}
        </Text>

        <Adder
          profile={profile}
          setProfile={setProfile}
          type={type}
          changingProfile={changingProfile}
        />
      </Group>

      <Stack spacing={1} mah={170} style={{ overflow: "auto" }} gap={"sm"}>
        {profile?.[type]?.length > 0 ? (
          profile?.[type]?.map((item) => (
            <Item
              item={item}
              key={item.id}
              type={type}
              profile={profile}
              setProfile={setProfile}
            />
          ))
        ) : (
          <Text c={"dimmed"}>Aucun élément</Text>
        )}
      </Stack>
    </Stack>
  );
};

export default CommunityChooser;
