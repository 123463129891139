import Stats, { getStatsPathName } from "./pages/protected/stats/Stats.jsx";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Protected from "./pages/protected/Protected.jsx";
import Home from "./pages/protected/home/Home.jsx";
import Login from "./pages/unprotected/login/Login.jsx";
import CreateList from "./pages/protected/lists/pages/CreateEmptyList.jsx";
import ContactListContentListing from "./pages/protected/lists/content/ContactListContentListing.jsx";
import ErrorPage from "./pages/unprotected/errors/ErrorPage.jsx";
import Campaigns from "./pages/protected/stats/campaigns/Campaigns.jsx";
import LeadsTracking from "./pages/protected/leadsTracking/LeadsTracking.jsx";
import EmailAddedPage from "./pages/unprotected/success/EmailAddedPage.jsx";
import EmailNotAdded from "./pages/unprotected/errors/EmailNotAdded.jsx";
import AllListSearch from "./pages/protected/lists/pages/AllListSearch.jsx";
import CampaignContacts from "./pages/protected/stats/campaigns/contacts/CampaignContacts.jsx";
import Error404 from "./pages/unprotected/errors/Error404.jsx";
import ImportListPage from "./pages/protected/lists/pages/ImportListPage.jsx";
import ContactListsListing from "./pages/protected/lists/pages/ContactListsListing.jsx";
import { findField, findList } from "./api/ContactAPI.js";
import { findFolder } from "./api/FolderAPI.js";
import { useUIStore } from "./stores/UIStore.js";
import AccountSettings from "./pages/protected/account/AccountSettings.jsx";
import ModelPage from "./pages/protected/models/ModelPage.jsx";
import ModelPageEdit from "./pages/protected/models/ModelPageEdit.jsx";
import ModelPageCreate from "./pages/protected/models/ModelPageCreate.jsx";
import ModelListing from "./pages/protected/models/ModelListing.jsx";
import { getModelTypeProps } from "./pages/protected/models/modelUtils.jsx";
import EmailSignatures from "./pages/protected/senders/signatures/EmailSignatures.jsx";
import SignaturePageCreate from "./pages/protected/senders/signatures/SignaturePageCreate.jsx";
import { findAIKey, findApiKey, findSignature } from "./api/ConfigsApi.js";
import SignaturePage from "./pages/protected/senders/signatures/SignaturePage.jsx";
import SignaturePageEdit from "./pages/protected/senders/signatures/SignaturePageEdit.jsx";
import EmailSendersListing from "./pages/protected/senders/emails/EmailSendersListing.jsx";
import CreateMassMailing from "./pages/protected/senders/emails/massmailing/CreateMassMailing.jsx";
import { findEmail, findLinkedinAccount, findPool } from "./api/EmailAPI.js";
import EmailEditPage from "./pages/protected/senders/emails/EmailEditPage.jsx";
import EmailSmtp from "./pages/protected/senders/emails/smtp/EmailSmtp.jsx";
import LinkedinAccountsListing from "./pages/protected/senders/linkedin/LinkedinAccountsListing.jsx";
import LinkedinPage from "./pages/protected/senders/linkedin/LinkedinPage.jsx";
import UnsubscribersListing from "./pages/protected/exclusions/unsubs/UnsubscribersListing.jsx";
import UnsubsImportPage from "./pages/protected/exclusions/unsubs/UnsubsImportPage.jsx";
import BlacklistListing from "./pages/protected/exclusions/blacklists/BlacklistListing.jsx";
import BlacklistImportPage from "./pages/protected/exclusions/blacklists/BlacklistImportPage.jsx";
import BlacklistsAllSearch from "./pages/protected/exclusions/blacklists/BlacklistsAllSearch.jsx";
import { findBlacklist } from "./api/BlacklistAPI.js";
import BlacklistContent from "./pages/protected/exclusions/blacklists/BlacklistContent.jsx";
import ApiListing from "./pages/protected/api/ApiListing.jsx";
import CreateApiKeyPage from "./pages/protected/api/CreateApiKeyPage.jsx";
import ApiKeyUse from "./pages/protected/api/ApiKeyUse.jsx";
import NetworkError from "./pages/unprotected/errors/NetworkError.jsx";
import ReferralPage from "./pages/protected/resellers/referral/ReferralPage.jsx";
import ResellersListing from "./pages/protected/resellers/ResellersListing.jsx";
import ResellerPage from "./pages/protected/resellers/ResellerPage.jsx";
import { findOrg, findReseller, findTeam, getProfile } from "./api/UserAPI.js";
import UsersList from "./pages/protected/community/UsersList.jsx";
import TeamListing from "./pages/protected/community/teams/TeamListing.jsx";
import CreateTeamPage from "./pages/protected/community/teams/CreateTeamPage.jsx";
import OrgListing from "./pages/protected/community/orgs/OrgListing.jsx";
import CreateOrgPage from "./pages/protected/community/orgs/CreateOrgPage.jsx";
import AdminPage from "./pages/protected/admin/AdminPage.jsx";
import TagsListing from "./pages/protected/tools/tags/TagsListing.jsx";
import DatafieldsListing from "./pages/protected/tools/datafields/DatafieldsListing.jsx";
import CreateDatafieldPage from "./pages/protected/tools/datafields/CreateDatafieldPage.jsx";
import UrlListing from "./pages/protected/tools/urlShortener/UrlListing.jsx";
import CreateUrlPage from "./pages/protected/tools/urlShortener/CreateUrlPage.jsx";
import Signup from "./pages/unprotected/signup/Signup.jsx";
import FilesListing from "./pages/protected/files/FilesListing.jsx";
import FilePage from "./pages/protected/files/FilePage.jsx";
import { findFile } from "./api/FIleAPI.js";
import ListMethodPage from "./pages/protected/lists/pages/ListMethodPage.jsx";
import { getListMethod } from "./pages/protected/lists/listUtils.jsx";
import CreateUserPage from "./pages/protected/resellers/createUser/CreateUserPage.jsx";
import { useLanguage } from "./stores/LanguageStore.js";
import FallbackPage from "./pages/unprotected/errors/FallbackPage.jsx";
import WorkflowListingPage from "./pages/protected/workflows/listing/WorkflowListingPage.jsx";
import AccountVerifiedPage from "./pages/unprotected/success/AccountVerifiedPage.jsx";
import Reporting from "./pages/protected/stats/reporting/Reporting.jsx";
import ImportSmtp from "./pages/protected/senders/emails/import/ImportSmtp.jsx";
import PasswordRecovery from "./pages/unprotected/password-recovery/PasswordRecovery.jsx";
import JoinPage from "./pages/protected/community/JoinPage.jsx";
import Support from "./pages/support/Support.jsx";
import { getKnowledges } from "./pages/support/knowledges.jsx";
import GroupPage from "./pages/support/base/GroupPage.jsx";
import BaseList from "./pages/support/base/BaseList.jsx";
import ContentPage from "./pages/support/base/ContentPage.jsx";
import SubscriptionCancelled from "./pages/unprotected/errors/SubscriptionCancelled.jsx";
import { Suspense } from "react";
import SuspenseFallback from "./pages/unprotected/SuspenseFallback.jsx";
import CreateCampaignComponent from "./pages/protected/workflows/CreateCampaign/CreateCampaign.jsx";
import EditDatafieldPage from "./pages/protected/tools/datafields/EditDatafieldPage.jsx";
import DirectLinkReply from "./pages/unprotected/replies/DirectLinkReply.jsx";
import Test from "./pages/Test.jsx";
import AIKeyListing from "./pages/protected/tools/openai_keys/AIKeyListing.jsx";
import CreateAiKey from "./pages/protected/tools/openai_keys/CreateAiKey.jsx";
import EditAiKeyPage from "./pages/protected/tools/openai_keys/EditAiKeyPage.jsx";
import CreateAiKeyPage from "./pages/protected/tools/openai_keys/CreateAiKeyPage.jsx";

function loader(loaderFunction, responseField) {
  return async ({ params }) => {
    const setLoading = useUIStore.getState().setMainLoading;
    setLoading(true);
    const response = await loaderFunction(params).catch((err) => {
      setLoading(false);
      console.log(err);
      if (err?.response) {
        throw new Response("", { status: err.response.status });
      }
      throw new Response("", { status: 418 });
    });

    setLoading(false);
    if (response) return response?.data?.[responseField];
  };
}

function getPathName(nameField = "name", idField = "id") {
  return (data) => {
    if (data) return `${data?.[nameField] || ""} #${data[idField]}`;
    return "?";
  };
}

function stopLoaderOnChild() {
  return ({ currentUrl, nextUrl }) => {
    return nextUrl.pathname.length <= currentUrl.pathname.length;
  };
}

function getRouteParentProps({
  findFunction = () => {},
  disableDefaultFind,
  param,
  responseField,
  id,
  withHandle = true,
  isFolder,
}) {
  return {
    shouldRevalidate: stopLoaderOnChild(),
    loader: loader(
      (params) =>
        findFunction(disableDefaultFind ? params : parseInt(params[param])),
      responseField
    ),
    id: id,
    handle: withHandle
      ? {
          getName: getPathName(),
          isFolder,
        }
      : undefined,

    errorElement: <ErrorPage />,
  };
}

const getSupportRoutes = (lang) => {
  return (
    <Route>
      <Route index element={<BaseList />} />
      {getKnowledges(lang).map((group) => (
        <Route
          key={group.url}
          path={group.url}
          handle={{
            name: group.title,
          }}
        >
          <Route index element={<GroupPage {...group} />} />
          {group.items.map((item) => (
            <Route
              key={item.url}
              path={item.url}
              element={<ContentPage group={group} {...item} />}
              handle={{
                name: item.title,
              }}
            />
          ))}
        </Route>
      ))}
    </Route>
  );
};

const getRoutes = (lang) => {
  return createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/maintenance" element={<ErrorPage status={503} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/reply" element={<DirectLinkReply />} />

        <Route path="/test" element={<Test />} />

        {/* Support */}
        <Route path="/support" element={<Support />}>
          {getSupportRoutes(lang)}
        </Route>

        {/* Successes */}
        <Route path="/success">
          <Route path="email" element={<EmailAddedPage />} />
          <Route path="account" element={<AccountVerifiedPage />} />
        </Route>

        {/* Errors */}
        <Route path="/error">
          <Route path="email" element={<EmailNotAdded />} />
          <Route path="subscription" element={<SubscriptionCancelled />} />
        </Route>

        <Route path="/" element={<Protected />} errorElement={<ErrorPage />}>
          <Route index element={<Home />} />

          {/* Campaign Creator */}
          <Route path="campaign">
            <Route
              path="create"
              element={<CreateCampaignComponent isCreate />}
            />
            <Route
              path="edit/:campaignId"
              element={<CreateCampaignComponent />}
            />
          </Route>

          {/* List */}
          <Route
            path="contact-lists"
            handle={{
              name: lang.contact_lists.browser_tab_title,
            }}
          >
            <Route index element={<ContactListsListing />} />
            <Route
              path=":contactListId"
              {...getRouteParentProps({
                id: "contact-list",
                param: "contactListId",
                responseField: "contact_list_profile",
                findFunction: findList,
              })}
            >
              <Route index element={<ContactListContentListing />} />
              <Route
                path="import"
                handle={{
                  name: lang.routes.import,
                }}
              >
                <Route index element={<ListMethodPage />} />
                <Route
                  path=":method"
                  element={<ImportListPage />}
                  loader={({ params }) => params}
                  handle={{
                    getName: (params) =>
                      getListMethod().find((x) => x.value === params?.method)
                        ?.title || "?",
                  }}
                />
              </Route>
            </Route>
            <Route
              path="all-contacts"
              element={<AllListSearch />}
              // handle={{
              //   name: lang.contact_lists.tabs_title.all_contacts,
              // }}
            />
            <Route
              path="create"
              element={<CreateList />}
              handle={{
                name: lang.routes.creation,
              }}
            />
            <Route
              path="import"
              handle={{
                name: lang.routes.import,
              }}
            >
              <Route index element={<ListMethodPage />} />
              <Route
                path=":method"
                element={<ImportListPage />}
                loader={({ params }) => params}
                handle={{
                  getName: (params) =>
                    getListMethod().find((x) => x.value === params?.method)
                      ?.title || "?",
                }}
              />
            </Route>

            <Route
              path="folder/:folderId"
              {...getRouteParentProps({
                id: "list-folder",
                param: "folderId",
                responseField: "folder_profile",
                findFunction: findFolder,
                isFolder: true,
              })}
            >
              <Route index element={<ContactListsListing />} />

              <Route
                path="import"
                handle={{
                  name: lang.routes.import,
                }}
              >
                <Route index element={<ListMethodPage />} />
                <Route
                  path=":method"
                  element={<ImportListPage />}
                  loader={({ params }) => params}
                  handle={{
                    getName: (params) =>
                      getListMethod().find((x) => x.value === params?.method)
                        ?.title || "?",
                  }}
                />
              </Route>

              <Route
                path="create"
                element={<CreateList />}
                handle={{
                  name: lang.routes.creation,
                }}
              />
              <Route
                path="list/:contactListId"
                {...getRouteParentProps({
                  id: "folder-contact-list",
                  param: "contactListId",
                  responseField: "contact_list_profile",
                  findFunction: findList,
                })}
              >
                <Route index element={<ContactListContentListing inFolder />} />

                <Route
                  path="import"
                  handle={{
                    name: lang.routes.import,
                  }}
                >
                  <Route index element={<ListMethodPage />} />
                  <Route
                    path=":method"
                    element={<ImportListPage />}
                    loader={({ params }) => params}
                    handle={{
                      getName: (params) =>
                        getListMethod().find((x) => x.value === params?.method)
                          ?.title || "?",
                    }}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Model */}
          <Route path="models" handle={{ name: lang.routes.models.models }}>
            <Route index element={<Navigate to={"email"} replace />} />
            <Route
              path=":modelType"
              loader={({ params }) => params}
              handle={{
                getName: (params) =>
                  getModelTypeProps(params.modelType)?.pathName || "Model",
              }}
            >
              <Route index element={<ModelListing />} />
              <Route
                path="create"
                element={<ModelPageCreate />}
                handle={{ name: lang.routes.creation }}
              />
              <Route
                path="create-ai"
                element={<ModelPageCreate is_ai />}
                handle={{ name: lang.routes.models.ai_email }}
              />
              <Route
                path=":modelId"
                {...getRouteParentProps({
                  id: "model",
                  param: "modelId",
                  responseField: "model_profile",
                  disableDefaultFind: true,
                  findFunction: ({ modelType, modelId }) => {
                    console.log(modelType);
                    const main = getModelTypeProps(modelType)?.findFunction;
                    console.log(main);
                    return main(parseInt(modelId));
                  },
                })}
              >
                <Route index element={<ModelPage loaderId="model" />} />
                <Route
                  path="edit"
                  element={<ModelPageEdit loaderId="model" />}
                  handle={{ name: lang.routes.edit }}
                />
              </Route>
            </Route>

            <Route
              path="email-signatures"
              handle={{
                name: lang.routes.senders.signs,
              }}
            >
              <Route index element={<EmailSignatures />} />
              <Route
                path="create"
                element={<SignaturePageCreate />}
                handle={{ name: lang.routes.creation }}
              />
              <Route
                path=":signatureId"
                {...getRouteParentProps({
                  id: "email-signature",
                  param: "signatureId",
                  responseField: "email_signature_profile",
                  findFunction: findSignature,
                })}
              >
                <Route
                  index
                  element={<SignaturePage loaderId="email-signature" />}
                />
                <Route
                  path="edit"
                  element={<SignaturePageEdit loaderId="email-signature" />}
                  handle={{ name: lang.routes.edit }}
                />
              </Route>
            </Route>

            <Route
              path="folder/:folderId"
              {...getRouteParentProps({
                id: "model-folder",
                param: "folderId",
                responseField: "folder_profile",
                findFunction: findFolder,
                isFolder: true,
              })}
            >
              <Route index element={<ModelListing />} />
              <Route
                path=":modelType/:modelId"
                {...getRouteParentProps({
                  id: "folder-model",
                  param: "modelId",
                  responseField: "model_profile",
                  disableDefaultFind: true,
                  findFunction: ({ modelType, modelId }) => {
                    const main = getModelTypeProps(modelType)?.findFunction;
                    return main(parseInt(modelId));
                  },
                })}
              >
                <Route index element={<ModelPage loaderId="folder-model" />} />
                <Route
                  path="edit"
                  element={<ModelPageEdit loaderId="folder-model" />}
                  handle={{ name: lang.routes.edit }}
                />
              </Route>
              <Route
                path="create/:modelType"
                element={<ModelPageCreate />}
                handle={{ name: lang.routes.creation }}
              />
            </Route>
          </Route>

          {/* Senders */}
          <Route
            path="senders"
            handle={{
              name: lang.routes.senders.senders,
            }}
          >
            <Route index element={<Navigate to={"email"} replace />} />
            <Route
              path="email"
              handle={{
                name: lang.routes.models.emails,
              }}
            >
              <Route index element={<EmailSendersListing />} />
              <Route
                path="create/massmailing"
                element={<CreateMassMailing />}
                handle={{
                  name: lang.routes.senders.create_massmailing,
                }}
              />
              <Route
                path="create/smtp"
                element={<EmailSmtp />}
                handle={{
                  name: lang.routes.senders.create_smtp,
                }}
              />
              <Route
                path="import/smtp"
                element={<ImportSmtp />}
                handle={{
                  name: lang.routes.senders.import_smtp,
                }}
              />
              <Route
                path=":emailId"
                element={<EmailEditPage />}
                {...getRouteParentProps({
                  param: "emailId",
                  responseField: "email_account_profile",
                  findFunction: findEmail,
                })}
              />

              <Route
                path="pool/:poolId"
                {...getRouteParentProps({
                  id: "email-pool",
                  param: "poolId",
                  responseField: "pool_profile",
                  findFunction: findPool,
                  isPool: true,
                })}
              >
                <Route index element={<EmailSendersListing />} />
                <Route
                  path="create/smtp"
                  element={<EmailSmtp />}
                  handle={{
                    name: lang.routes.senders.create_smtp,
                  }}
                />
                <Route
                  path="email/:emailId"
                  element={<EmailEditPage />}
                  {...getRouteParentProps({
                    param: "emailId",
                    responseField: "email_account_profile",
                    findFunction: findEmail,
                  })}
                />
              </Route>
            </Route>

            <Route
              path="linkedin"
              handle={{
                name: "LinkedIn",
              }}
            >
              <Route index element={<LinkedinAccountsListing />} />
              <Route
                path="create"
                element={<LinkedinPage />}
                handle={{
                  name: lang.routes.senders.add_account,
                }}
              />
              <Route
                path=":linkedinId"
                element={<LinkedinPage />}
                {...getRouteParentProps({
                  param: "linkedinId",
                  responseField: "linkedin_account_profile",
                  findFunction: findLinkedinAccount,
                })}
              />

              <Route
                path="pool/:poolId"
                {...getRouteParentProps({
                  id: "linkedin-pool",
                  param: "poolId",
                  responseField: "pool_profile",
                  findFunction: findPool,
                  isPool: true,
                })}
              >
                <Route index element={<LinkedinAccountsListing />} />
                <Route
                  path="create"
                  element={<LinkedinPage />}
                  handle={{
                    name: lang.routes.senders.add_account,
                  }}
                />
                <Route
                  path="linkedin/:linkedinId"
                  element={<LinkedinPage />}
                  {...getRouteParentProps({
                    param: "linkedinId",
                    responseField: "linkedin_account_profile",
                    findFunction: findLinkedinAccount,
                  })}
                />
              </Route>
            </Route>
          </Route>

          {/* Exclusion */}
          <Route
            path="exclusions"
            handle={{ name: lang.routes.exclusion.exclusion }}
          >
            <Route index element={<Navigate to={"unsubscribers"} replace />} />

            {/* Unsubs */}
            <Route
              path="unsubscribers"
              handle={{
                name: lang.routes.exclusion.unsubs,
              }}
            >
              <Route index element={<UnsubscribersListing />} />
              <Route
                path="import"
                element={<UnsubsImportPage />}
                handle={{ name: lang.routes.import }}
              />
            </Route>

            {/* Blacklists */}
            <Route
              path="blacklists"
              handle={{
                name: lang.routes.exclusion.blacklist,
              }}
            >
              <Route index element={<BlacklistListing />} />
              <Route
                path="import"
                element={<BlacklistImportPage />}
                handle={{ name: lang.routes.import }}
              />
              <Route
                path="search"
                element={<BlacklistsAllSearch />}
                handle={{ name: lang.routes.search }}
              />

              <Route
                path=":blacklistId"
                {...getRouteParentProps({
                  id: "blacklist",
                  param: "blacklistId",
                  responseField: "blacklist_profile",
                  findFunction: findBlacklist,
                })}
              >
                <Route index element={<BlacklistContent />} />
                <Route
                  path="import"
                  element={<BlacklistImportPage />}
                  handle={{ name: lang.routes.import }}
                />
              </Route>
            </Route>
          </Route>

          {/* Stats */}
          <Route
            path="statistics"
            handle={{
              name: lang.routes.stats.stats,
            }}
            element={<Stats />}
          >
            <Route index element={<Navigate to={"campaigns"} replace />} />

            <Route
              path="campaigns"
              handle={{
                name: lang.routes.stats.campaigns,
              }}
            >
              <Route index element={<Campaigns />} />
              <Route
                path=":campaign/workflow/:workflow/contacts/:type"
                element={<CampaignContacts />}
                loader={({ params }) => params}
                handle={{
                  getName: getStatsPathName,
                }}
              />
              <Route
                path=":campaign/workflow/:workflow/step/:stepId/contacts/:type/:stepType"
                element={<CampaignContacts />}
                loader={({ params }) => params}
                handle={{
                  getName: getStatsPathName,
                }}
              />
            </Route>

            <Route
              path="global-report"
              element={<Reporting />}
              handle={{ name: lang.routes.senders.reports }}
            />
          </Route>

          <Route path="workflows" handle={{ name: lang.routes.workflows }}>
            <Route index element={<WorkflowListingPage />} />

            <Route
              element={<WorkflowListingPage />}
              path="folder/:folderId"
              {...getRouteParentProps({
                id: "workflow-folder",
                param: "folderId",
                responseField: "folder_profile",
                findFunction: findFolder,
                isFolder: true,
              })}
            />
          </Route>

          {/* Leads */}
          <Route path="leads-tracking" handle={{ name: lang.routes.prm }}>
            <Route index element={<LeadsTracking />} />
            <Route path=":userId" element={<LeadsTracking />} />
          </Route>

          {/* Account Settings */}
          <Route
            path="account-settings"
            handle={{ name: lang.routes["account-settings"] }}
          >
            <Route index element={<Navigate to={"profile"} />} />
            <Route path=":tab" element={<AccountSettings />} />
          </Route>

          {/* API */}
          <Route path="api" handle={{ name: lang.routes.api }}>
            <Route index element={<ApiListing />} />
            <Route
              path="create"
              element={<CreateApiKeyPage />}
              handle={{ name: lang.routes.creation }}
            />
            <Route
              path=":apiKeyId"
              element={<ApiKeyUse />}
              {...getRouteParentProps({
                param: "apiKeyId",
                responseField: "apikey_profile",
                findFunction: findApiKey,
              })}
            />
          </Route>

          {/* Resellers */}
          <Route
            path="resellers"
            handle={{
              name: lang.routes.resellers,
            }}
          >
            <Route index element={<Navigate to={"white-labels"} replace />} />
            <Route
              path="white-labels"
              handle={{
                name: lang.routes["white-label"],
              }}
            >
              <Route index element={<ResellersListing />} />

              <Route
                path="create"
                element={<ResellerPage />}
                handle={{ name: lang.routes.creation }}
              />
              <Route
                path=":resellerId"
                {...getRouteParentProps({
                  param: "resellerId",
                  id: "reseller",
                  responseField: "reseller_profile",
                  findFunction: findReseller,
                })}
              >
                <Route index element={<ResellerPage />} />

                <Route path="users" handle={{ name: lang.routes.users }}>
                  <Route index element={<UsersList />} />

                  <Route
                    path="create"
                    handle={{ name: lang.routes.add }}
                    element={<CreateUserPage />}
                  />
                  <Route
                    path=":userId"
                    {...getRouteParentProps({
                      param: "userId",
                      responseField: "user_profile",
                      findFunction: getProfile,
                    })}
                    element={<AdminPage />}
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="ref"
              element={<ReferralPage />}
              handle={{ name: lang.routes.affiliation }}
            />

            <Route
              path="admin"
              element={<AdminPage />}
              handle={{ name: lang.routes.admin }}
            />
          </Route>

          {/* Community */}
          <Route
            path="community"
            handle={{
              name: lang.routes.community,
            }}
          >
            <Route index element={<Navigate to={"teams"} replace />} />

            <Route
              path="join"
              element={<JoinPage />}
              handle={{
                name: lang.routes.join,
              }}
            />

            <Route
              path="teams"
              handle={{
                name: lang.routes.teams,
              }}
            >
              <Route index element={<TeamListing />} />

              <Route
                path="create"
                element={<CreateTeamPage />}
                handle={{ name: lang.routes.creation }}
              />
              <Route
                path=":teamId"
                {...getRouteParentProps({
                  param: "teamId",
                  id: "team",
                  responseField: "team_profile",
                  findFunction: findTeam,
                })}
              >
                <Route index element={<Navigate to={"users"} replace />} />

                <Route path="users" handle={{ name: lang.routes.users }}>
                  <Route index element={<UsersList />} />
                  <Route
                    path=":userId"
                    {...getRouteParentProps({
                      param: "userId",
                      responseField: "user_profile",
                      findFunction: getProfile,
                    })}
                    element={<AdminPage />}
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="organizations"
              handle={{
                name: lang.routes.organizations,
              }}
            >
              <Route index element={<OrgListing />} />

              <Route
                path="create"
                element={<CreateOrgPage />}
                handle={{ name: lang.routes.creation }}
              />
              <Route
                path=":orgId"
                {...getRouteParentProps({
                  param: "orgId",
                  id: "org",
                  responseField: "organization_profile",
                  findFunction: findOrg,
                })}
              >
                <Route index element={<Navigate to={"users"} replace />} />

                <Route path="users" handle={{ name: lang.routes.users }}>
                  <Route index element={<UsersList />} />
                  <Route
                    path=":userId"
                    {...getRouteParentProps({
                      param: "userId",
                      responseField: "user_profile",
                      findFunction: getProfile,
                    })}
                    element={<AdminPage />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Advanced Settings */}
          <Route
            path="extra"
            handle={{
              name: lang.routes.extra,
            }}
          >
            <Route index element={<Navigate to={"files"} replace />} />

            {/* Files */}
            <Route
              path="files"
              handle={{
                name: lang.routes.files,
              }}
            >
              <Route index element={<FilesListing />} />
              <Route
                path="create"
                element={<FilePage />}
                handle={{
                  name: lang.routes.add,
                }}
              />
              <Route
                path=":fileId/edit"
                element={<FilePage />}
                {...getRouteParentProps({
                  param: "fileId",
                  responseField: "file_profile",
                  findFunction: findFile,
                })}
              />

              <Route
                path="folder/:folderId"
                {...getRouteParentProps({
                  id: "file-folder",
                  param: "folderId",
                  responseField: "folder_profile",
                  findFunction: findFolder,
                  isFolder: true,
                })}
              >
                <Route index element={<FilesListing />} />
                <Route
                  path="create"
                  element={<FilePage />}
                  handle={{
                    name: lang.routes.add,
                  }}
                />
              </Route>
            </Route>

            {/* Datafields */}
            <Route
              path="datafields"
              handle={{
                name: lang.routes.datafields,
              }}
            >
              <Route index element={<DatafieldsListing />} />

              <Route
                path="create"
                element={<CreateDatafieldPage />}
                handle={{ name: lang.routes.creation }}
              />

              <Route
                path=":datafieldId"
                element={<EditDatafieldPage />}
                {...getRouteParentProps({
                  param: "datafieldId",
                  responseField: "data_field_profile",
                  findFunction: findField,
                })}
              />
            </Route>

            {/* Tags */}
            <Route
              path="tags"
              handle={{
                name: "Tags",
              }}
              element={<TagsListing />}
            />

            {/* URL Shortener */}
            <Route
              path="url-shortener"
              handle={{
                name: lang.routes.url_shortener,
              }}
            >
              <Route index element={<UrlListing />} />

              <Route
                path="create"
                element={<CreateUrlPage />}
                handle={{ name: lang.routes.creation }}
              />
            </Route>

            {/* OPENAI Keys */}
            <Route
              path="openai-api-keys"
              handle={{
                name: lang.openai_keys.browser_tab_title,
              }}
            >
              <Route index element={<AIKeyListing />} />

              <Route
                path="create"
                element={<CreateAiKeyPage />}
                handle={{ name: lang.routes.creation }}
              />

              <Route
                path=":openaiKey"
                element={<EditAiKeyPage />}
                {...getRouteParentProps({
                  param: "openaiKey",
                  responseField: "openai_api_key_profile",
                  findFunction: findAIKey,
                })}
              />
            </Route>
          </Route>

          {/* 404 Error */}
          <Route
            path="*"
            element={<Error404 />}
            errorElement={<NetworkError />}
          />
        </Route>
      </>
    )
  );
};

export const Routes = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <RouterProvider
        fallbackElement={<FallbackPage />}
        router={getRoutes(lang)}
      />
    </Suspense>
  );
};
