import { Divider, Group, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CommunityChooser from "./CommunityChooser";

const CommunityManager = ({ profile, setProfile, changingProfile }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Stack gap={"xs"}>
      <CommunityChooser
        changingProfile={changingProfile}
        profile={profile}
        setProfile={setProfile}
        type={"resellers"}
      />
      {/* <ResellerChanger
        profile={profile}
        setProfile={setProfile}
        changingProfile={changingProfile}
      /> */}

      <Group
        w={"100%"}
        wrap="nowrap"
        justify="space-between"
        align="start"
        mt={"sm"}
      >
        <CommunityChooser
          changingProfile={changingProfile}
          profile={profile}
          setProfile={setProfile}
          type={"teams"}
        />

        <Divider orientation="vertical" />

        <CommunityChooser
          changingProfile={changingProfile}
          profile={profile}
          setProfile={setProfile}
          type={"organizations"}
        />
      </Group>
    </Stack>
  );
};

export default CommunityManager;
