import { ActionIcon, Flex, Group, Stack, Text, Tooltip } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import {
  deleteContact,
  deleteFilterContacts,
  downloadFilterList,
  editContact,
  findList,
  getFirstPageContacts,
  searchInContactlist,
} from "../../../../api/ContactAPI";
import MainTable from "../../../../layouts/Table/MainTable";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import ListDetails from "./ListDetails";
import TableFilterDelete from "../../../../components/TableComponents/TableFilterDelete";
import TableFilterExport from "../../../../components/TableComponents/TableFilterExport";
import TableFilterCopyContacts from "../components/copy/TableFilterCopyContacts";
import { useContactHandler } from "../components/useContactHandler";
import SearchListsButton from "../components/SearchListsButton";
import {
  IconLanguage,
  IconMailSearch,
  IconMessage2Bolt,
} from "@tabler/icons-react";
import ListActions from "../actions/ListActions";
import { getChangedKeys } from "../listUtils";
import { notifications } from "@mantine/notifications";
import GenerateDatrafieldContent from "../actions/GenerateDatrafieldContent";

// const desiredOrder = ["__check__", "id"].map((x) => x.toString());

const ContactsTableView = ({
  defaultCurrentList,
  loadProfile,
  setLoadProfile,
}) => {
  const listId = defaultCurrentList.id;

  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const [currentList, setCurrentList] = useState(defaultCurrentList);

  const {
    loading,
    columns,
    rows,
    rowCount,
    serverInfo,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    listId,
    getFirstPage: getFirstPageContacts,
    searchFunction: searchInContactlist,
    additionalMainRequest: () => setLoadProfile(true),
    renderHeader: {
      2: ({ datafield }) => {
        return (
          <Group wrap="nowrap">
            <Text>{datafield?.name}</Text>{" "}
            <Tooltip label={lang.contact_lists.translate.label}>
              <ActionIcon
                size={"sm"}
                variant="light"
                onClick={(e) => {
                  document.getElementById("list-translate-button")?.click();
                  e.stopPropagation();
                }}
              >
                <IconLanguage size={17} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
      1: ({ datafield }) => {
        return (
          <Group wrap="nowrap">
            <Text>{datafield?.name}</Text>{" "}
            <Tooltip label={lang.contact_lists.email_verifier.label}>
              <ActionIcon
                size={"sm"}
                variant="light"
                onClick={(e) => {
                  document.getElementById("email-verifier-button")?.click();
                  e.stopPropagation();
                }}
              >
                <IconMailSearch size={17} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
      29: ({ datafield }) => {
        return (
          <Group wrap="nowrap">
            <Text>{datafield?.name}</Text>{" "}
            <Tooltip label={lang.contact_lists.icebreaker.label}>
              <ActionIcon
                size={"sm"}
                variant="light"
                onClick={(e) => {
                  document.getElementById("icebreaker-button")?.click();
                  e.stopPropagation();
                }}
              >
                <IconMessage2Bolt size={17} />
              </ActionIcon>
            </Tooltip>
          </Group>
        );
      },
    },
    headerRendererCustomField: (datafield) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <GenerateDatrafieldContent
            current={datafield}
            onFinish={() => setLoadProfile(true)}
          />
          <Text>{datafield?.name}</Text>{" "}
        </Group>
      );
    },
    cellEditable: true,
    disableEdit: true,
    pageOrderName: "contact_list_fetch_contacts_order",
  });

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <ItemDeleter
          title_bold={`${row[4]} ${row[3]}`}
          handleDelete={deleteContact}
          deletedMessage={lang.contact_lists.delete_success}
          parentId={listId}
          id={row.id}
          apiRef={apiRef}
        />
      );
    },
    [apiRef, lang.contact_lists.delete_success, listId]
  );

  const toolbarActions = useCallback(
    ({ selectedIds, filter }) => (
      <>
        <SearchListsButton />
        <TableFilterDelete
          id={listId}
          selectedIds={selectedIds}
          filter={filter}
          handleDelete={deleteFilterContacts}
          apiRef={apiRef}
          onFinish={(type) => handleLoading(type !== "selection")}
          filterResultNumber={rowCount}
        />
        <TableFilterExport
          id={listId}
          selectedIds={selectedIds}
          filter={filter}
          handleExport={downloadFilterList}
          name={currentList?.name}
          filterResultNumber={rowCount}
          total={currentList?.number_of_contacts}
        />
        <TableFilterCopyContacts
          id={listId}
          selectedIds={selectedIds}
          filter={filter}
          name={currentList?.name}
          filterResultNumber={rowCount}
          total={currentList?.number_of_contacts}
          onFinish={() => setLoadProfile(true)}
        />

        <ListActions
          current={currentList}
          inside
          onFinish={() => setLoadProfile(true)}
        />
      </>
    ),
    [apiRef, currentList, handleLoading, listId, rowCount, setLoadProfile]
  );

  useEffect(() => {
    if (loadProfile) {
      findList(listId)
        .then((response) => {
          setCurrentList(response.data.contact_list_profile);
        })
        .finally(() => setLoadProfile(false));
    }
  }, [listId, loadProfile]);

  const handleRowUpdate = useCallback(async (updatedRow, originalRow) => {
    const changedKey = getChangedKeys(updatedRow, originalRow);
    if (updatedRow[changedKey] !== originalRow[changedKey]) {
      let cellElement = document.querySelector(
        `[data-id="${originalRow.id}"] > [data-field="${changedKey}"]`
      );
      cellElement.style.opacity = 0.3;
      editContact({
        contact_id: originalRow?.id,
        list_id: originalRow?.contact_list_id,
        properties: [
          {
            data_field_id: parseInt(changedKey),
            value: updatedRow[changedKey],
          },
        ],
      })
        .finally((res) => {
          cellElement.style.opacity = 1;
        })
        .catch((err) => {
          cellElement.querySelector("p").innerHTML =
            originalRow?.[changedKey] || "-";
        });

      return updatedRow;
    }

    return originalRow;
  }, []);

  const handleRowUpdateError = useCallback((err) => {
    return notifications.show({ message: "Contact non modifié", color: "red" });
  }, []);

  if (!columns) {
    return;
  }

  return (
    <Stack flex={"1 1 auto"} w={0} miw={"100%"} gap={0}>
      <ListDetails
        serverInfo={serverInfo}
        currentList={currentList}
        setCurrentList={setCurrentList}
        handleLoading={handleLoading}
        handleGetList={() => setLoadProfile(true)}
      />
      <Flex flex={"1 1 auto"} h={0}>
        <MainTable
          isClient={false}
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          setLoading={() => {
            handleLoading();
          }}
          id={"list-table"}
          hasFolder
          pageName={"contact_lists_contacts"}
          onPaginationChange={onPaginationChange}
          actionsCell={actionsCell}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
          pageOrderName="contact_list_fetch_contacts_order"
          toolbarActions={toolbarActions}
          actionsCellWidth={50}
          withBorderTop
          handleRowUpdate={handleRowUpdate}
          handleRowUpdateError={handleRowUpdateError}
        />
      </Flex>
    </Stack>
  );
};

export default ContactsTableView;
