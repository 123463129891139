import { useLanguage } from "../stores/LanguageStore";

export function parseDateTime(datetimeString, toIso = false) {
  let result = new Date(
    parseInt(datetimeString.substring(6, 10)), // year
    parseInt(datetimeString.substring(3, 5)) - 1, // month (0-indexed)
    parseInt(datetimeString.substring(0, 2)), // day
    parseInt(datetimeString.substring(11, 13)), // hours
    parseInt(datetimeString.substring(14, 16)) // minutes
  );

  return toIso ? result.toISOString() : result;
}

function differenceMinute(date) {
  const currentTime = new Date();
  const inputTime = new Date(date);

  // Calculate the time difference in milliseconds
  const timeDifferenceMinute =
    (currentTime.getTime() - inputTime.getTime()) / 1000 / 60;

  // Check if the time difference is less than 5 minutes (300,000 milliseconds)
  return Math.ceil(timeDifferenceMinute);
}

function differenceMinuteFuture(date, currentTime = new Date()) {
  const inputTime = new Date(date);

  // Calculate the time difference in milliseconds
  const timeDifferenceMinute =
    (inputTime.getTime() - currentTime.getTime()) / 1000 / 60;

  // Check if the time difference is less than 5 minutes (300,000 milliseconds)
  return Math.ceil(timeDifferenceMinute);
}

export function parseDate(date) {
  const lang = useLanguage.getState().language;
  const today = new Date();
  const value = new Date(date);

  // let offset = new Date().getTimezoneOffset() / 60;
  const diffMinutes = differenceMinute(date);

  const hours = Math.floor(diffMinutes / 60);
  const remainingMinutes = diffMinutes % 60;

  if (diffMinutes < 0) {
    return parseDateFuture(date);
  }

  if (hours > 0 && hours < 12) {
    if (lang.id === "FRA") return ` Il y a ${hours}h ${remainingMinutes}mn`;
    return ` ${hours}h ${remainingMinutes}mn ago`;
  }
  if (hours < 1 && diffMinutes < 1) {
    if (lang.id === "FRA") return ` À l'instant`;
    return ` Just now`;
  }
  if (hours === 0) {
    if (lang.id === "FRA") return ` Il y a ${diffMinutes}mn`;
    return `${diffMinutes}mn ago`;
  }
  if (today.toDateString() === value.toDateString()) {
    if (lang.id === "FRA")
      return ` Aujourd'hui, ${value.getHours()}:${value.getMinutes()}`;
    return ` Today, ${value.getHours()}:${value.getMinutes()}`;
  }

  let v = value.toLocaleString(lang.id === "FRA" ? "fr-FR" : "en-EN", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });

  function getPre() {
    if (isYesterday(value)) {
      if (lang.id === "FRA") return `Hier, `;
      return `Yesterday, `;
    }
    return "";
  }

  return `${getPre()} ${v.charAt(0).toUpperCase() + v.slice(1)}`;
}

function isTomorrow(date) {
  const now = new Date();

  // Create a Date object for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(now.getDate() + 1);

  // Compare year, month, and date parts
  return (
    date.getFullYear() === tomorrow.getFullYear() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getDate() === tomorrow.getDate()
  );
}

function isYesterday(date) {
  const now = new Date();

  // Create a Date object for yesterday
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);

  // Compare year, month, and date parts
  return (
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate()
  );
}

export function parseDateFuture(date, today = new Date()) {
  const lang = useLanguage.getState().language;
  const value = new Date(date);

  const diffMinutes = differenceMinuteFuture(date, today);

  const hours = Math.floor(diffMinutes / 60);
  const remainingMinutes = diffMinutes % 60;

  if (hours === 0) {
    if (lang.id === "FRA") return ` Dans ${remainingMinutes}mn`;
    return ` in ${remainingMinutes}mn`;
  }
  if (hours > 0 && hours < 12) {
    if (lang.id === "FRA") return ` Dans ${hours}h ${remainingMinutes}mn`;
    return ` in ${hours}h ${remainingMinutes}mn`;
  }
  if (today.toDateString() === value.toDateString()) {
    if (lang.id === "FRA")
      return ` Aujourd'hui, ${value.getHours()}:${value.getMinutes()}`;
    return `Today, ${value.getHours()}:${value.getMinutes()}`;
  }

  function getPre() {
    if (isTomorrow(value)) {
      if (lang.id === "FRA") return `Demain, `;
      return `Tomorrow, `;
    }
    return "";
  }

  let v = value.toLocaleString(lang.id === "FRA" ? "fr-FR" : "en-EN", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${getPre()} ${v.charAt(0).toUpperCase() + v.slice(1)}`;
}

export function getDuration(date, today) {
  const lang = useLanguage.getState().language;
  const value = new Date(date);

  const diffMinutes = differenceMinuteFuture(date, today);

  const hours = Math.floor(diffMinutes / 60);
  const remainingMinutes = diffMinutes % 60;

  if (hours === 0) {
    if (lang.id === "FRA") return `${remainingMinutes}mn`;
    return ` ${remainingMinutes}mn`;
  }
  if (hours > 0 && hours < 12) {
    if (lang.id === "FRA") return ` ${hours}h ${remainingMinutes}mn`;
    return ` ${hours}h ${remainingMinutes}mn`;
  }
  if (lang.id === "FRA") return ` ${value.getHours()}:${value.getMinutes()}`;
  return `${value.getHours()}:${value.getMinutes()}`;
}

export function incrementHour(timeString, incrementBy) {
  // Parse the original time string
  var parts = timeString.split(":");
  var hours = parseInt(parts[0], 10);
  var minutes = parseInt(parts[1], 10);
  var seconds = parseInt(parts[2], 10) || 0;

  // Increment the hours by the specified amount
  hours += incrementBy;

  if (hours < 0) {
    hours += 24;
  }

  // Ensure that hours remain in the 24-hour format (0-23)
  hours = hours % 24;

  // Format the new time back into the string format
  var newTimeString =
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds;

  return newTimeString;
}

export function getRelativeDate(dateString) {
  const lang = useLanguage.getState().language;
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "Aujourd'hui";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Hier";
  } else {
    return date.toLocaleString(lang.id === "FRA" ? `fr-FR` : "en-EN", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  }
}

//.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
