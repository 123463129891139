import { mainAxios as axios } from "./axios";

export const getProducts = async () => {
  return axios.get("/subscriptions/products");
};

export const subscribe = async ({ items, success_url, cancel_url }) => {
  return axios.post("/subscriptions/subscribe", {
    items,
    success_url,
    cancel_url,
  });
};
export const addOptions = async ({ items }) => {
  return axios.put("/subscriptions/add/options", {
    items,
  });
};
export const removeOptions = async ({ items }) => {
  return axios.delete("/subscriptions/remove/options", {
    items,
  });
};

export const unsubscribe = async () => {
  return axios.delete("/subscriptions/unsubscribe");
};
