import { Button, Group } from "@mantine/core";
import { useState } from "react";
import FilterBlockRoot from "./FilterBlockRoot";
import { IconArrowDown, IconX } from "@tabler/icons-react";
import FilterList from "./FilterList";
import { useLanguage } from "../../stores/LanguageStore";

const FilterContent = ({
  mainFilter,
  setMainFilter,
  id,
  fields,
  getFieldOptionName,
  isClient,
  disableBottomButtons,
  maxHeight,
  pageName,
  defaultFiltersClose = false,
  buttonLoading,
  disableFilters,
  disableEdit,
}) => {
  const lang = useLanguage((s) => s.language);
  const [showFilterList, setShowFilterList] = useState(!defaultFiltersClose);
  const [loadingSaver, setLoadingSaver] = useState(true);
  const [filter, setFilter] = useState(mainFilter);

  const onSaved = (newFilter) => {
    setLoadingSaver(true);
  };

  const onChange = (newFilter) => {
    setMainFilter(newFilter);
    console.log({ newFilter });
  };

  return (
    <Group w={"100%"} p={2} align="stretch" h={"100%"}>
      <Group pos={"relative"} align="start" h={"auto"}>
        <FilterBlockRoot
          filter={filter}
          onChange={onChange}
          maxHeight={maxHeight}
          id={id}
          setFilter={setFilter}
          fields={fields}
          getFieldOptionName={getFieldOptionName}
          isClient={isClient}
          submitFilter={onChange}
          maxItems={50}
          disableButtons={disableBottomButtons}
          onSaved={onSaved}
          pageName={pageName}
          buttonLoading={buttonLoading}
          disableEdit={disableEdit}
        />

        {!disableFilters && (
          <Button
            pos={"absolute"}
            top={0}
            right={0}
            size="compact-xs"
            variant="light"
            rightSection={
              showFilterList ? <IconX size={14} /> : <IconArrowDown size={14} />
            }
            onClick={() => setShowFilterList(!showFilterList)}
            style={{
              zIndex: 4,
            }}
            disabled={!pageName}
          >
            {lang.table.toolbar.filter.my_filters}
          </Button>
        )}
      </Group>
      {showFilterList && (
        <FilterList
          loading={loadingSaver}
          setLoading={setLoadingSaver}
          handleFilter={setFilter}
          pageName={pageName}
          fields={fields}
        />
      )}
    </Group>
  );
};

export default FilterContent;
