import { useDisclosure } from "@mantine/hooks";
import { useLanguage } from "../../stores/LanguageStore";
import { Button, Modal, Text, Tooltip } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import EditModelEmail from "../../pages/protected/models/emails/EditModelEmail";
import EditModelLinkedin from "../../pages/protected/models/linkedin/EditModelLinkedin";

const ModelEditor = ({ onModelEdited, model, type, maxChars, disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Tooltip label={lang.models.edit_label}>
        <Button variant="subtle" px={"xs"} onClick={open} disabled={disabled}>
          <IconEdit size={21} />
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        size={type === "email" ? (model?.is_ai ? 800 : 1200) : 600}
        title={
          <Text>
            {lang.models.edit_title} <b>{model?.name}</b>{" "}
          </Text>
        }
        styles={{
          header: {
            paddingBottom: 8,
            minHeight: 32,
          },
        }}
        removeScrollProps={{
          enabled: false,
        }}
      >
        {type === "email" ? (
          <EditModelEmail
            onEdited={onModelEdited}
            handleClose={close}
            model={model}
            mih={model?.is_ai ? "auto" : "78vh"}
          />
        ) : (
          <EditModelLinkedin
            onEdited={onModelEdited}
            handleClose={close}
            model={model}
            type={type}
            maxChars={maxChars}
          />
        )}
      </Modal>
    </>
  );
};

export default ModelEditor;
