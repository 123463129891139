import { useEffect, useMemo, useRef } from "react";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { getFields } from "../../../../../api/ContactAPI";
import { Button, Group, Stack } from "@mantine/core";
import TextEditor from "../../../../../components/ExternalEditors/TextEditor";
import BackButton from "../../../../../components/View/BackButton";
import { IconSend } from "@tabler/icons-react";

const EmailAnswerEditor = ({ id, handleClose, handleSubmit }) => {
  const lang = useLanguage((s) => s.language);
  const editorRef = useRef();

  const datafields = useConfigStore((s) => s.datafields);
  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    df = [
      {
        label: lang.components.variable_manager.dialog.signature_variable_name,
        value: "%signature%",
        name: lang.components.variable_manager.dialog.signature_variable_name,
      },
      {
        label:
          lang.components.variable_manager.dialog.unsubscribe_variable_name,
        value: "%unsubscribe%",
        name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.webversion_variable_name,
        value: "%webversion%",
        name: lang.components.variable_manager.dialog.webversion_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.b64_variable_name,
        value: "%email_b64%",
        name: lang.components.variable_manager.dialog.b64_variable_name,
      },
      ...df,
    ];

    return df;
  }, [datafields, lang]);

  useEffect(() => {
    getFields();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let html = "";
    let text = "";

    html = editorRef?.current?.getContent();
    text = editorRef?.current?.getContent({ format: "text" });

    return handleSubmit({
      html,
      text,
      subject: data.subject,
    });
  };

  return (
    <Stack
      component={"form"}
      onSubmit={onSubmit}
      gap={0}
      id="model-form"
      w={"100%"}
      pos={"relative"}
    >
      <TextEditor
        id={id}
        editorRef={editorRef}
        placeholder={"Répondre ici..."}
        tokens={datafieldTokens}
        toolbar="undo redo | bold italic underline numlist bullist link | code"
        // noTop
        autoFocus
        autoSize
        disableDecor
      >
        <Group
          wrap="nowrap"
          style={{
            position: "absolute",
            top: 7,
            right: 7,
            zIndex: 4,
          }}
        >
          {/* <FilesModal editorRef={editorRef} /> */}

          <Group justify="end">
            <BackButton
              onClick={handleClose}
              label={lang.global.cancel}
              h={32}
            />

            <Button
              type="submit"
              rightSection={<IconSend size={18} />}
              h={32}
            >
              {lang.global.send}
            </Button>
          </Group>
        </Group>
      </TextEditor>
    </Stack>
  );
};

export default EmailAnswerEditor;
