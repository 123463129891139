import { Button, Group, Modal, Text } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import EmailPreviewer from "./EmailPreviewer";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import SignatureSelector from "../Selectors/SignatureSelector";
import { IconEdit, IconEye, IconSignature } from "@tabler/icons-react";
import { getFields } from "../../api/ContactAPI";
import BackButton from "./BackButton";
import { Link } from "react-router-dom";
import SendBAT from "../../pages/protected/models/emails/SendBAT";

const EmailModalPreview = ({
  getData,
  defaultSign,
  standalone = false,
  model,
  opener,
  isSign,
  noSign,
  disableEdit,
  disabled,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [html, setHtml] = useState("");
  const [subject, setSubject] = useState("");
  const [sign, setSign] = useState(defaultSign);

  const handleOpen = () => {
    if (standalone) {
      open();
      getFields();
    } else getData({ setHtml, setSubject, open });
  };

  const handleSignChange = (v) => {
    setSign(v?.id || null);
  };

  return (
    <>
      {opener ? (
        opener(handleOpen)
      ) : (
        <Button
          variant="light"
          onClick={handleOpen}
          leftSection={<IconEye size={18} />}
          disabled={disabled}
        >
          {lang.components.email_preview.button_label}
        </Button>
      )}
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text>
            {isSign
              ? "Prévisuel de la signature"
              : lang.components.email_preview.email_title}
          </Text>
        }
        size={model?.is_ai ? "60%" : "90%"}
        centered
      >
        <EmailPreviewer
          model_id={model?.id}
          html={html}
          subject={subject}
          iframeHeight={model?.is_ai ? 200 : 345}
          paperShadow="none"
          signatureId={sign}
          disableSubject={isSign}
        />
        {!noSign && (
          <SignatureSelector
            leftSection={<IconSignature size={18} />}
            onChange={handleSignChange}
            size={"sm"}
            value={sign}
            mt={"xs"}
          />
        )}

        <Group justify="end" mt={"md"}>
          <SendBAT
            getModel={async () => {
              return {
                html,
                subject:
                  document.getElementById("email-subject")?.value ||
                  "no_subject",
              };
            }}
            defaultSignature={sign}
            mr="auto"
            disabled={model?.is_ai}
          />
          <BackButton onClick={close} />
          {!disableEdit && (
            <Button
              component={Link}
              leftSection={<IconEdit size={18} />}
              to={`/models/email/${model?.id}/edit`}
            >
              {lang.api_keys.edit.button_label}
            </Button>
          )}
        </Group>
      </Modal>
    </>
  );
};

export default EmailModalPreview;
