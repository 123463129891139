import {
  ButtonGroup,
  Group,
  SegmentedControl,
  Skeleton,
  Stack,
  ThemeIcon,
} from "@mantine/core";
import RightSheetHeader from "./header/RightSheetHeader";
import { IconActivity, IconBell, IconSpeakerphone } from "@tabler/icons-react";
import { useLeadsSheetStore } from "../../../../../../stores/LeadsStore";
import { useCallback, useMemo } from "react";
import ProspectActivities from "./activity/ProspectActivities";
import ProspectReminders from "./reminder/ProspectReminders";
import ProspectCampaigns from "./campaigns/ProspectCampaigns";
import AddNote from "./activity/note/AddNote";
import AddReminder from "./reminder/AddReminder";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const iconProps = { size: 16 };

const RightSheet = () => {
  const lang = useLanguage((s) => s.language);
  const loading = useLeadsSheetStore((s) => s.loading);
  const current = useLeadsSheetStore((s) => s.current);
  const tab = useLeadsSheetStore((s) => s.activeTab);
  const setTab = useLeadsSheetStore((s) => s.setActiveTab);

  const tabs = useMemo(() => {
    return [
      {
        label: (
          <Group justify="center">
            <IconActivity {...iconProps} />
            <span>{lang.prm.details.activity}</span>
          </Group>
        ),
        value: "activity",
        color: "primary",
      },
      {
        label: (
          <Group justify="center" wrap="nowrap">
            <IconBell {...iconProps} />
            <span>{lang.prm.details.calls}</span>
            {current?.calls?.length > 0 && (
              <ThemeIcon variant="light" radius={"sm"} size={"xs"} fz={"xs"}>
                {current?.calls?.length}
              </ThemeIcon>
            )}
          </Group>
        ),
        value: "reminder",
        color: "tertiary.8",
      },
      {
        label: (
          <Group justify="center" wrap="nowrap">
            <IconSpeakerphone {...iconProps} />
            <span>{lang.prm.details.campaigns}</span>
            {current?.programmations?.length > 0 && (
              <ThemeIcon variant="light" radius={"sm"} size={"xs"} fz={"xs"}>
                {current?.programmations?.length}
              </ThemeIcon>
            )}
          </Group>
        ),
        value: "campaign",
        color: "tertiary.8",
      },
    ];
  }, [
    current?.calls?.length,
    current?.programmations?.length,
    lang.prm.details.activity,
    lang.prm.details.calls,
    lang.prm.details.campaigns,
    tab,
  ]);

  const handleTab = useCallback(() => {
    if (tab === "activity") {
      return <ProspectActivities />;
    }
    if (tab === "reminder") {
      return <ProspectReminders />;
    }
    if (tab === "campaign") {
      return <ProspectCampaigns />;
    }
  }, [tab]);

  return (
    <Stack
      w={"80%"}
      gap={0}
      h={"100%"}
      bg={"#f9f9f9"}
      px={"sm"}
      pt={"sm"}
      pb={"sm"}
      style={{ borderTopRightRadius: 8 }}
    >
      {loading ? (
        <Stack h={"100%"}>
          <Skeleton height={75} />

          <Skeleton height={"100%"} />
        </Stack>
      ) : (
        <>
          <RightSheetHeader />

          <Group w={"100%"} wrap="nowrap">
            <SegmentedControl
              mt={"sm"}
              value={tab}
              onChange={setTab}
              data={tabs}
              // h={44}
              // color={tabs.find((x) => x.value === tab)?.color}
              // variant="light"
              mb={"xs"}
              w={"100%"}
            />
            <Group ml={64} wrap="nowrap" gap={0}>
              <ButtonGroup>
                <AddNote />
                <AddReminder />
              </ButtonGroup>
            </Group>
          </Group>

          {handleTab()}
        </>
      )}
    </Stack>
  );
};

export default RightSheet;
