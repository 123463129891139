import { Button, Group, Popover, TextInput } from "@mantine/core";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { getProfile, updateProfile } from "../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import { filterNumberTypes } from "./filterSettings";

const FilterSaver = ({ onSaved, filter, pageName }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleFilterSave = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    let name = data?.name;

    console.log(filter);

    let getFilter = (f) => {
      return f.values.map((item) => {
        let group = item.values ? getFilter(item) : null;
        let result = {
          ...item,
          type: filterNumberTypes[item?.type] || item?.type,
          value: item?.value?.toString(),
        };
        if (group) {
          result.values = group;
        }

        return result;
      });
    };

    let newFilter = {
      mode: filter.mode,
      values: filter.values.map((item) => {
        let group = item.values ? getFilter(item) : null;
        let result = {
          ...item,
          type: filterNumberTypes[item?.type] || item?.type,
          value: item?.value?.toString(),
        };
        if (group) {
          result.values = group;
        }

        return result;
      }),
    };

    setLoading(true);
    getProfile().then((res) => {
      let profile = res.data.user_profile;
      let saved = profile?.saved_filters;

      if (
        saved &&
        profile?.saved_filters[pageName]?.find((x) => x.name === name)
      ) {
        setLoading(false);
        return notifications.show({
          message: lang.table.toolbar.filter.filter_already_exists,
          color: "red",
        });
      }

      const newData = {
        saved_filters: {
          ...(profile?.saved_filters || {}),
          [pageName]: [
            ...(saved ? profile?.saved_filters[pageName] || [] : []),
            {
              name,
              filter: newFilter,
            },
          ],
        },
      };

      updateProfile(null, newData)
        .then((response) => {
          notifications.show({
            message: lang.table.toolbar.filter.filter_save_success,
            color: "tertiary.8",
          });
          onSaved?.();
          close();
        })
        .finally(() => setLoading(false));
    });

    e.stopPropagation();
  };

  return (
    <Popover
      onOpen={open}
      onClose={close}
      opened={opened}
      trapFocus
      zIndex={1500}
      withArrow
      offset={4}
      shadow="md"
      withinPortal={false}
    >
      <Popover.Target>
        <Button
          leftSection={<IconDeviceFloppy size={16} />}
          size="compact-sm"
          ml={"auto"}
          variant="outline"
          color="tertiary.8"
          onClick={open}
          disabled={filter?.values?.length === 0 || !pageName}
        >
          {lang.table.toolbar.filter.save_button_label}
        </Button>
      </Popover.Target>

      <Popover.Dropdown p={12} pt={4}>
        <Group
          component={"form"}
          onSubmit={handleFilterSave}
          id="filter-save-form"
          w={220}
          wrap="nowrap"
          gap={4}
          align="end"
        >
          <TextInput
            label={lang.table.toolbar.filter.save_name}
            placeholder={lang.table.toolbar.filter.save_name}
            data-autofocus
            name="name"
            w={"100%"}
            size="xs"
          />

          <Button
            type="submit"
            loading={loading}
            form="filter-save-form"
            px={6}
            size="xs"
            color="tertiary.8"
          >
            <IconDeviceFloppy size={24} />
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterSaver;
