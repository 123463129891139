import { useLanguage } from "../../../stores/LanguageStore";
import { Button, Group, Indicator, Tooltip } from "@mantine/core";
import { IconFilter, IconX } from "@tabler/icons-react";
import { defaultButtonType } from "../initialState";
import FilterContent from "../../../components/Filter/FilterContent";
import { memo, useCallback, useMemo } from "react";
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  gridColumnDefinitionsSelector,
  gridFilterModelSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import PopOver from "../../../components/View/PopOver";
import FilterListQuick from "../../../components/Filter/FilterListQuick";

const TableFilter = memo(({ tableId, pageName, isClient }) => {
  const apiRef = useGridApiContext();
  const lang = useLanguage((s) => s.language);

  const currentFilter = useGridSelector(apiRef, gridFilterModelSelector);
  const mainFilter = useMemo(() => {
    if (currentFilter)
      return {
        mode: currentFilter?.logicOperator?.toLowerCase(),
        values: currentFilter?.items.map((item) => ({
          field_name: item?.field,
          // type: item?.operator.toLowerCase(),
          type: item?.operator,
          value: item?.value,
          id: item?.id,
        })),
      };
    return null;
  }, [currentFilter]);

  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector).filter(
    (x) =>
      ![
        GRID_CHECKBOX_SELECTION_FIELD,
        GRID_TREE_DATA_GROUPING_FIELD,
        "actions",
      ].includes(x.field)
  );

  const handleFilterChange = useCallback(
    (newFilter) => {
      const quickFilter =
        apiRef.current.state.filter.filterModel.quickFilterValues?.join(" ");

      apiRef.current.setFilterModel({
        items:
          newFilter?.values?.map((val) => ({
            field: val.field_name,
            value: val.value,
            operator: val.type,
            id: val?.id,
          })) || [],
        logicOperator: newFilter?.mode || "and",
        quickFilterValues: quickFilter?.split(" ") || [],
      });
    },
    [apiRef]
  );

  const fields = useMemo(() => {
    return [
      {
        label: "Colonnes",
        options: columns
          .filter((x) => x.filterable)
          .map((column) => ({
            id: column?.field,
            name: column?.headerName,
            option_name: column?.headerName,
            type: column?.type || "text",
            options: column?.getFilterOptions?.(),
            ...column,
          })),
      },
    ];
  }, [columns]);

  return (
    <Group gap={0}>
      <PopOver
        opener={(openFunction, ref) => {
          return (
            <Group gap={0}>
              <Tooltip label={lang.table.toolbar.filter.main_button_tooltip}>
                <Button
                  ref={ref}
                  onClick={openFunction}
                  radius={0}
                  variant={defaultButtonType}
                  style={{
                    borderLeft: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRight:
                      currentFilter?.items?.length > 0 ? 0 : undefined,
                  }}
                  className="toolbar-action-button"
                  id={"table-filter-" + tableId}
                >
                  <Indicator
                    color="red"
                    offset={2}
                    disabled={currentFilter?.items?.length === 0}
                    label={currentFilter?.items?.length}
                    styles={{
                      indicator: {
                        padding: "7px 4px",
                      },
                    }}
                    size={16}
                  >
                    <IconFilter size={18} />
                  </Indicator>
                  <span style={{ marginLeft: 6 }}>
                    {lang.table.toolbar.filter.main_button_label}
                  </span>
                </Button>
              </Tooltip>
              {currentFilter?.items?.length > 0 && (
                <Tooltip
                  label={lang.table.toolbar.filter.cancel_button_tooltip}
                >
                  <Button
                    variant="light"
                    color="red"
                    px={4}
                    radius={0}
                    style={{
                      borderRight: "1px solid #00000016",
                      borderBottom: "1px solid #00000016",
                    }}
                    onClick={() => handleFilterChange(null)}
                  >
                    <IconX size={18} />
                  </Button>
                </Tooltip>
              )}
            </Group>
          );
        }}
        popoverChildren={(closePopover) => (
          <FilterContent
            id={tableId}
            isClient={isClient}
            fields={fields}
            mainFilter={mainFilter}
            setMainFilter={(v) => {
              handleFilterChange(v);
              closePopover();
            }}
            getFieldOptionName={(f) => f.name}
            pageName={pageName}
          />
        )}
        elevation={3}
        paperSx={{
          ml: -12,
        }}
      />
      {pageName && (
        <FilterListQuick
          tableId={tableId}
          pageName={pageName}
          fields={fields}
          handleFilter={handleFilterChange}
        />
      )}
    </Group>
  );
});

TableFilter.displayName = "TableFilter";

export default TableFilter;
