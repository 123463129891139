import { useState } from "react";
import { createField } from "../../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import { useDisclosure } from "@mantine/hooks";
import {
  Button,
  Group,
  Menu,
  Modal,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import { IconColumnInsertRight } from "@tabler/icons-react";

const CreateFieldAny = () => {
  const lang = useLanguage((s) => s.language);
  const fields = useConfigStore((s) => s.datafields);

  const [opened, { open, close }] = useDisclosure();
  const [creatingDatafield, setCreatingDatafield] = useState(false);
  const [error, setError] = useState(false);
  const createDatafield = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    if (fields.some((x) => x.name.toLowerCase() === data?.name.toLowerCase())) {
      return setError(true);
    }
    setCreatingDatafield(true);
    createField({ name: data?.name })
      .then((res) => {
        notifications.show({
          message: lang.datafields.row_highlight.created,
          color: "tertiary.8",
        });
        close();
      })
      .finally(() => setCreatingDatafield(false));
  };

  return (
    <>
      <Menu.Item onClick={open}>
        <Group gap={4}>
          <ThemeIcon variant="subtle" p={0}>
            <IconColumnInsertRight size={16} />
          </ThemeIcon>
          {lang.contact_lists.create_column.label_simple}
        </Group>
      </Menu.Item>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={lang.contact_lists.create_column.title}
          centered
        >
          <Stack component={"form"} onSubmit={createDatafield} gap={"xs"}>
            <Text size="sm">{lang.contact_lists.create_column.text2}</Text>
            <TextInput
              name="name"
              required
              label={lang.contact_lists.create_column.label}
              placeholder={lang.contact_lists.create_column.placeholder}
              data-autofocus
              onChange={(e) => {
                setError(false);
              }}
              error={error ? lang.contact_lists.create_column.error : null}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={close} />
              <Button type="submit" loading={creatingDatafield}>
                {lang.datafields.create.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default CreateFieldAny;
