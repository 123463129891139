import { Button, Menu } from "@mantine/core";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../../stores/UserStore";
import CreateFieldAny from "./CreateFieldAny";
import CreateFieldOptions from "./CreateFieldOptions";
import CreateFieldAI from "./CreateFieldAI";

const CreateField = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  return (
    <Menu keepMounted>
      <Menu.Target>
        <Button
          leftSection={<IconPlus size={18} />}
          ml={"auto"}
          variant="light"
          rightSection={<IconChevronDown size={16} />}
        >
          {lang.datafields.create.button_tooltip}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <CreateFieldAny />
        <CreateFieldOptions />
        {profile?.level === "admin" && <CreateFieldAI />}
      </Menu.Dropdown>
    </Menu>
  );
};

export default CreateField;
