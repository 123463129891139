import dayjs from "dayjs";
import { getLists } from "../../../api/ContactAPI";
import { getEmails, getLinkedinAccounts } from "../../../api/EmailAPI";
import { getQuickCampaigns } from "../../../api/StatsAPI";
import { getFirstPageUsers } from "../../../api/UserAPI";
import { useLanguage } from "../../../stores/LanguageStore";
import { useLeadsStore } from "../../../stores/LeadsStore";
import { useProfileStore } from "../../../stores/UserStore";
import { useConfigStore } from "../../../stores/ConfigStore";
import { getWorkflows } from "../../../api/WorkflowAPI";

export const status_list = () => {
  const lang = useLanguage.getState().language;
  return [
    { label: lang.components.status_selector.statuses.opener, value: "opener" },
    {
      label: lang.components.status_selector.statuses.clicker,
      value: "clicker",
    },
    {
      label: lang.components.status_selector.statuses.answerer,
      value: "answerer",
    },
    {
      label: lang.components.status_selector.statuses.to_call,
      value: "to_call",
    },
  ];
};

export function get_positive_label() {
  const lang = useLanguage.getState().language;
  return [
    { label: lang.prm.positive, value: "true" },
    { label: lang.prm.negative, value: "false" },
    { label: lang.prm.neutral, value: "null" },
  ];
}

export function setupFilters({ datafields = [], custom_columns }) {
  const lang = useLanguage.getState().language;

  let result = [
    {
      label: "Specials",
      options: [
        {
          option_name: "ID prospect",
          type: "text",
          id: "id",
          filterable: true,
          field: "id",
        },
        {
          option_name: lang.prm.filter_labels.status,
          type: "text",
          options: status_list(),
          id: "status",
          filterable: true,
          field: "status",
        },
        {
          option_name: lang.prm.filter_labels.custom_status,
          type: "text",
          options: custom_columns,
          id: "custom_status",
          filterable: true,
          field: "custom_status",
        },
        {
          option_name: lang.prm.filter_labels.score,
          type: "number",
          id: "score",
          filterable: true,
          field: "score",
        },
        {
          option_name: lang.prm.filter_labels.campaign,
          type: "lazy",
          id: "programmation_id",
          options: [],
          filterable: true,
          field: "programmation_id",
          getter: getQuickCampaigns,
          responseField: "results",
          storeKey: "campaignOptions",
          setStoreKey: "setCampaignOptions",
          nameField: "workflow_name",
          disabledCondition: true,
        },
        {
          option_name: lang.prm.filter_labels.status_from_programmation,
          type: "campaign",
          id: "status_from_programmation",
          options: [],
          filterable: true,
          field: "status_from_programmation",
          disabledCondition: true,
        },
        {
          option_name: lang.prm.filter_labels.contact_list,
          type: "lazy",
          id: "contact_list_id",
          options: [],
          filterable: true,
          field: "contact_list_id",
          getter: getLists,
          responseField: "contact_lists",
          storeKey: "listOptions",
          setStoreKey: "setListOptions",
          nameField: "name",
          disabledCondition: true,
        },
        {
          option_name: lang.prm.filter_labels.workflow,
          type: "lazy",
          id: "workflow_id",
          options: [],
          filterable: true,
          field: "workflow_id",
          getter: getWorkflows,
          responseField: "workflows",
          storeKey: "workflowOptions",
          setStoreKey: "setWorkflowOptions",
          nameField: "name",
          disabledCondition: true,
        },
        {
          option_name: lang.prm.filter_labels.integration,
          type: "lazy",
          id: "integration_id",
          options: [],
          filterable: true,
          field: "integration_id",
          groups: [
            {
              name: lang.prm.filter_labels.linkedin_account,
              getter: getLinkedinAccounts,
              responseField: "linkedin_accounts_list",
            },
            {
              name: lang.prm.filter_labels.email_account,
              getter: getEmails,
              responseField: "email_accounts_list",
            },
          ],
          storeKey: "integrationOptions",
          setStoreKey: "setIntegrationOptions",
          nameField: "name",
          disabledCondition: true,
        },
        {
          option_name: lang.prm.filter_labels.call_created_by,
          type: "lazy",
          id: "call_created_by",
          options: [],
          filterable: true,
          field: "call_created_by",
          getter: async () =>
            getFirstPageUsers({ per_page: 100 }).then((response) => {
              let self = useProfileStore.getState().profile;
              return {
                data: {
                  users: [self, ...response.data.results].map((x) => ({
                    name: `${x.first_name} ${x.last_name}`,
                    id: x.id,
                  })),
                },
              };
            }),
          responseField: "users",
          storeKey: "usersOptions",
          setStoreKey: "setUsersOptions",
          nameField: "name",
          disabledCondition: true,
        },
        {
          option_name: lang.prm.filter_labels.person_in_charge,
          type: "lazy",
          id: "person_in_charge",
          options: [],
          filterable: true,
          field: "person_in_charge",
          getter: async () =>
            getFirstPageUsers({ per_page: 100 }).then((response) => {
              let self = useProfileStore.getState().profile;
              return {
                data: {
                  users: [self, ...response.data.results].map((x) => ({
                    name: `${x.first_name} ${x.last_name}`,
                    id: x.id,
                  })),
                },
              };
            }),
          responseField: "users",
          storeKey: "usersOptions",
          setStoreKey: "setUsersOptions",
          nameField: "name",
          disabledCondition: true,
        },
        {
          option_name:
            lang.prm.filter_labels.company_has_person_in_charge + " *",
          type: "boolean",
          id: "company_has_person_in_charge",
          options: [
            {
              label:
                lang.table.toolbar.filter.filters_list_label.server
                  .has_person_in_charge,
              value: "1",
            },
            {
              label:
                lang.table.toolbar.filter.filters_list_label.server
                  .has_no_person_in_charge,
              value: "0",
            },
          ],
          filterable: true,
          field: "company_has_person_in_charge",
          nameField: "name",
          disabledCondition: true,
        },

        {
          option_name: lang.prm.filter_labels.link_click,
          type: "boolean",
          id: "link_click",
          filterable: true,
          field: "link_click",
        },
        {
          option_name: lang.prm.filter_labels.is_positive,
          type: "boolean",
          id: "is_positive",
          options: get_positive_label(),
          filterable: true,
          field: "is_positive",
        },
      ],
    },
    {
      label: "Champs",
      options: [
        ...(datafields?.map((field) => ({
          option_name: field.name,
          type: "text",
          id: field.id,
          filterable: true,
          field: field.identifier_placeholder,
        })) || []),
      ],
    },
    {
      label: "Dates",
      options: [
        {
          option_name: lang.prm.filter_labels.last_call,
          type: "date",
          id: "last_call",
          filterable: true,
          field: "last_call",
        },
        {
          option_name: lang.prm.filter_labels.created_on,
          type: "date",
          id: "created_on",
          filterable: true,
          field: "created_on",
        },
        {
          option_name: lang.prm.filter_labels.last_reply,
          type: "date",
          id: "last_reply",
          filterable: true,
          field: "last_reply",
        },
        {
          option_name: lang.prm.filter_labels.status_changed_date,
          type: "date",
          id: "status_changed_date",
          filterable: true,
          field: "status_changed_date",
        },
        {
          option_name: lang.prm.filter_labels.last_link_click,
          type: "date",
          id: "last_link_click",
          filterable: true,
          field: "last_link_click",
        },
        {
          option_name: lang.prm.filter_labels.last_email_open,
          type: "date",
          id: "last_email_open",
          filterable: true,
          field: "last_email_open",
        },
        {
          option_name: lang.prm.filter_labels.last_email_answered,
          type: "date",
          id: "last_email_answered",
          filterable: true,
          field: "last_email_answered",
        },
        {
          option_name: lang.prm.filter_labels.last_linkedin_invitation_accepted,
          type: "date",
          id: "last_linkedin_invitation_accepted",
          filterable: true,
          field: "last_linkedin_invitation_accepted",
        },
        {
          option_name: lang.prm.filter_labels.last_linkedin_message_answered,
          type: "date",
          id: "last_linkedin_message_answered",
          filterable: true,
          field: "last_linkedin_message_answered",
        },
        {
          option_name: lang.prm.filter_labels.last_sms_got,
          type: "date",
          id: "last_sms_got",
          filterable: true,
          field: "last_sms_got",
        },
        {
          option_name: lang.prm.filter_labels.last_sms_not_got,
          type: "date",
          id: "last_sms_not_got",
          filterable: true,
          field: "last_sms_not_got",
        },
      ],
    },
  ];

  useLeadsStore.getState().setFilters(result);
}

export const callColors = {
  phone: "#dd9409",
  phone_away: "#ce0f0f",
  email: "#ffcb10",
  visio: "#2ba97b",
  meeting: "#cd6c54",
  appointment: "#000",
};

export const reminderTypes = () => {
  const lang = useLanguage.getState().language;

  return {
    phone: lang.prm.details.reminders.types.phone,
    phone_away: lang.prm.details.reminders.types.phone_away,
    email: lang.prm.details.reminders.types.email,
    visio: lang.prm.details.reminders.types.visio,
    meeting: lang.prm.details.reminders.types.meeting,
    appointment: lang.prm.details.reminders.types.appointment,
    other: lang.prm.details.reminders.types.other,
  };
};

export function getDifference(date) {
  const now = dayjs();
  const target = dayjs(date);

  const result = target.diff(now, "m");
  const days = Math.floor(result / (60 * 24));
  const hours = Math.floor((result % (60 * 24)) / 60);
  const minutes = result % 60;

  return {
    result: result,
    days,
    hours,
    minutes,
  };
}

export function formatAnswer(answer) {
  return `<div style="font-family: sans-serif;" > ${answer} </div>`;
}

export function parseProspectDatafields(prospect) {
  let newProspect = { ...prospect };
  const datafields = useConfigStore.getState().datafields;

  let defaultResults = datafields
    // .filter((x) => x.created_by === null)
    .map((element) => {
      let value = newProspect.properties.find(
        (x) =>
          x?.data_field_id === element.id ||
          x?.identifier_placeholder === element.identifier_placeholder
      )?.value;

      return {
        identifier_placeholder: element.identifier_placeholder,
        id: element.id,
        name: element.name,
        value: value,
      };
    });

  newProspect.properties = defaultResults;

  return newProspect;
}
