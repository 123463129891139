import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  getFirstPageUnsub,
  importUnsub,
} from "../../../../../api/UnsubscribersAPI";
import { useProfileStore } from "../../../../../stores/UserStore";
import { notifications } from "@mantine/notifications";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconUserOff } from "@tabler/icons-react";
import BackButton from "../../../../../components/View/BackButton";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
} from "../../../../../stores/LeadsStore";
import { parseProspectDatafields } from "../../utils";

const UnsubscribeProspect = ({
  current,
  onFinish,
  closeMenu,
  inSheet,
  inPrm,
  inList,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const profile = useProfileStore((s) => s.profile);
  const [unsubed, setUnsubed] = useState(false);
  const [checking, setChecking] = useState(true);

  const currentSheet = useLeadsSheetStore((s) => s.current);
  const setCurrentSheet = useLeadsSheetStore((s) => s.setCurrent);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );

  const handlePrmFinish = () => {
    if (inSheet) {
      setCurrentSheet({
        ...currentSheet,
        unsubscribed: true,
      });
    } else {
      updateContainerItem(
        current?.custom_status || current?.status,
        current.id,
        {
          unsubscribed: true,
        }
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    const unsubData = [];

    let parsedCurrent = parseProspectDatafields(current);

    if (
      parsedCurrent.properties.find(
        (x) => x.identifier_placeholder === "%linkedin_url%"
      )?.value
    ) {
      unsubData.push({
        value: parsedCurrent.properties.find(
          (x) => x.identifier_placeholder === "%linkedin_url%"
        )?.value,
      });
    } else if (
      parsedCurrent.properties.find(
        (x) => x.identifier_placeholder === "%email%"
      )?.value
    ) {
      unsubData.push({
        value: parsedCurrent.properties.find(
          (x) => x.identifier_placeholder === "%email%"
        )?.value,
      });
    } else {
      return notifications.show({
        message: lang.prm.unsub.no_data,
        color: "red",
      });
    }

    setLoading(true);
    importUnsub(
      data?.organization ? parseInt(data?.organization) : null,
      unsubData
    )
      .then((res) => {
        notifications.show({
          message: lang.prm.unsub.saved,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
        setUnsubed(true);

        if (inPrm) {
          handlePrmFinish();
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  const unsubscribed = useMemo(() => {
    return (
      unsubed ||
      current?.unsubscribed ||
      !!current?.programmations?.find((x) => x.unsubscribed)
    );
  }, [current?.programmations, current?.unsubscribed, unsubed]);

  useEffect(() => {
    let filterValues = [];

    let parsedCurrent = parseProspectDatafields(current);

    let _email = parsedCurrent.properties.find(
      (x) => x.identifier_placeholder === "%email%"
    )?.value;

    if (_email) {
      filterValues.push({
        field_name: "value",
        type: "equals",
        value: _email,
      });
    }

    let _linkedin_url = parsedCurrent.properties.find(
      (x) => x.identifier_placeholder === "%linkedin_url%"
    )?.value;
    if (_linkedin_url) {
      filterValues.push({
        field_name: "value",
        type: "equals",
        value: _linkedin_url,
      });
    }

    if (filterValues.length > 0) {
      setChecking(true);
      getFirstPageUnsub({
        per_page: 20,
        filter: { mode: "or", values: filterValues },
      })
        .then((response) => {
          if (response.data.number_of_results > 0) {
            setUnsubed(true);
          } else {
            setUnsubed(false);
          }
        })
        .finally(() => setChecking(false));
    }
  }, [current.id]);

  return (
    <>
      <Tooltip
        label={
          unsubscribed ? lang.prm.unsub.unsubed : lang.prm.unsub.button_label
        }
        disabled={inSheet ? false : !unsubscribed}
      >
        {inList ? (
          <ActionIcon
            variant="subtle"
            onClick={open}
            color="red"
            disabled={unsubscribed}
            loading={checking}
          >
            <IconUserOff size={16} />
          </ActionIcon>
        ) : (
          <Button
            onClick={open}
            leftSection={inSheet ? null : <IconUserOff size={18} />}
            fw={600}
            color="red"
            variant={inSheet ? "light" : "subtle"}
            justify="start"
            disabled={unsubed || current?.unsubscribed}
            px={inSheet ? "sm" : undefined}
            mr={"auto"}
            loading={checking}
            w={closeMenu ? "100%" : undefined}
          >
            {inSheet ? <IconUserOff size={18} /> : lang.prm.unsub.button_label}
          </Button>
        )}
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.prm.unsub.dialog_title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>{lang.prm.unsub.text}</Text>

            <Select
              label={lang.prm.unsub.org}
              placeholder={lang.prm.unsub.org}
              data={profile?.organizations.map((x) => ({
                label: x.name,
                value: x.id.toString(),
              }))}
              name="organization"
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconUserOff size={18} />}
                loading={loading}
                color="red"
                type="submit"
              >
                {lang.prm.unsub.save}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default UnsubscribeProspect;
