import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useLeadsStore } from "../../../stores/LeadsStore";
import { getQuickCampaigns } from "../../../api/StatsAPI";
import { Group, Select } from "@mantine/core";
import { status_list } from "../../../pages/protected/leadsTracking/utils";
import Selector from "../../Selectors/Selector";

const FilterLeadsCampaign = ({ setValue, value }) => {
  const lang = useLanguage((s) => s.language);
  const options = useLeadsStore((s) => s.campaignOptions);
  const setOptions = useLeadsStore((s) => s.setCampaignOptions);

  const [status, setStatus] = useState(
    value ? status_list().find((x) => x.value === value.split("_")[0]) : null
  );
  const [programmation, setProgrammation] = useState(
    value ? options.find((x) => x.id === parseInt(value.split("_")[1])) : null
  );

  const [loading, setLoading] = useState(false);

  const getOptions = () => {
    setLoading(true);
    getQuickCampaigns()
      .then((response) => {
        setOptions([
          {
            label: lang.table.toolbar.filter.values.campaign,
            options:
              response.data["results"]?.map((x) => ({
                ...x,
                option_name: x["workflow_name"],
              })) || [],
          },
        ]);
        setOptions(response.data.results);
      })
      .finally(() => setLoading(false));
  };

  const handleStatusChange = (v) => {
    setStatus(v);
  };

  const handleProgrammationChange = (v) => {
    setProgrammation(v);
  };

  const progOptions = useMemo(() => {
    return [
      {
        label: "Programmations",
        options: options.map((x) => ({
          ...x,
          option_name: `${x.workflow_name} #${x.id}`,
        })),
      },
    ];
  }, [options]);

  useEffect(() => {
    if (status && programmation) {
      let result = status + "_" + programmation?.id;
      setValue(result);
    }
  }, [status, programmation, setValue]);

  return (
    <Group gap={"xs"}>
      <Select
        data={status_list()}
        value={status}
        placeholder={lang.table.toolbar.filter.values.campaign_status}
        required
        onChange={handleStatusChange}
      />

      <Selector
        options={progOptions}
        loading={loading}
        value={programmation?.id || null}
        onChange={handleProgrammationChange}
        placeholder={lang.table.toolbar.filter.values.campaign}
        required
        getOptionName={(o) => o["workflow_name"] + " #" + o.id}
        onDropdownOpen={getOptions}
        rightOptionSection={(item) => {
          return (
            item?.id && (
              <span style={{ fontSize: 10, opacity: 0.35 }}>#{item?.id}</span>
            )
          );
        }}
        unclearable
        size={"sm"}
      />
    </Group>
  );
};

export default FilterLeadsCampaign;
